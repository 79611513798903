export const scatterSymbolCoords = [
	[
		{ x: -1, y: 30 },
		{ x: -1, y: 253 },
		{ x: -1, y: 477 },
		{ x: -1, y: 701 },
	],
	[
		{ x: 222, y: 30 },
		{ x: 222, y: 253 },
		{ x: 222, y: 477 },
		{ x: 222, y: 701 },
	],
	[
		{ x: 445, y: 30 },
		{ x: 445, y: 253 },
		{ x: 445, y: 477 },
		{ x: 445, y: 701 },
	],
	[
		{ x: 668, y: 30 },
		{ x: 668, y: 253 },
		{ x: 668, y: 477 },
		{ x: 668, y: 701 },
	],
	[
		{ x: 890, y: 30 },
		{ x: 890, y: 253 },
		{ x: 890, y: 477 },
		{ x: 890, y: 701 },
	],
];

export const wildSymbolCoords = [
	[
		{ x: -42, y: -13 },
		{ x: -42, y: 209 },
		{ x: -42, y: 433 },
		{ x: -42, y: 656 },
	],
	[
		{ x: 180, y: -13 },
		{ x: 180, y: 209 },
		{ x: 180, y: 433 },
		{ x: 180, y: 656 },
	],
	[
		{ x: 404, y: -13 },
		{ x: 404, y: 209 },
		{ x: 404, y: 433 },
		{ x: 404, y: 656 },
	],
	[
		{ x: 626, y: -13 },
		{ x: 626, y: 209 },
		{ x: 626, y: 433 },
		{ x: 626, y: 656 },
	],
	[
		{ x: 850, y: -13 },
		{ x: 850, y: 209 },
		{ x: 850, y: 433 },
		{ x: 850, y: 656 },
	],
];
