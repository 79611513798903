import { Loader } from 'pixi.js';

import zero from '../assets/base_amount/0.png';
import one from '../assets/base_amount/1.png';
import two from '../assets/base_amount/2.png';
import three from '../assets/base_amount/3.png';
import four from '../assets/base_amount/4.png';
import five from '../assets/base_amount/5.png';
import six from '../assets/base_amount/6.png';
import seven from '../assets/base_amount/7.png';
import eight from '../assets/base_amount/8.png';
import nine from '../assets/base_amount/9.png';
import comma from '../assets/base_amount/comma.png';
import dot from '../assets/base_amount/dot.png';
import x from '../assets/base_amount/X.png';
import background from '../assets/base_amount/base_amount_bg.png';

export enum BASE_AMOUNT_ASSETS {
	ONE = 'BASE_AMOUNT_ONE',
	TWO = 'BASE_AMOUNT_TWO',
	THREE = 'BASE_AMOUNT_THREE',
	FOUR = 'BASE_AMOUNT_FOUR',
	FIVE = 'BASE_AMOUNT_FIVE',
	SIX = 'BASE_AMOUNT_SIX',
	SEVEN = 'BASE_AMOUNT_SEVEN',
	EIGHT = 'BASE_AMOUNT_EIGHT',
	NINE = 'BASE_AMOUNT_NINE',
	ZERO = 'BASE_AMOUNT_ZERO',
	COMMA = 'BASE_AMOUNT_COMMA',
	DOT = 'BASE_AMOUNT_DOT',
	X = 'BASE_AMOUNT_X',
	BACKGROUND = 'BASE_AMOUNT_BACKGROUND',
}

export const loadBaseAmountAssets = (loader: Loader) => {
	loader.add(BASE_AMOUNT_ASSETS.ZERO, zero);
	loader.add(BASE_AMOUNT_ASSETS.ONE, one);
	loader.add(BASE_AMOUNT_ASSETS.TWO, two);
	loader.add(BASE_AMOUNT_ASSETS.THREE, three);
	loader.add(BASE_AMOUNT_ASSETS.FOUR, four);
	loader.add(BASE_AMOUNT_ASSETS.FIVE, five);
	loader.add(BASE_AMOUNT_ASSETS.SIX, six);
	loader.add(BASE_AMOUNT_ASSETS.SEVEN, seven);
	loader.add(BASE_AMOUNT_ASSETS.EIGHT, eight);
	loader.add(BASE_AMOUNT_ASSETS.NINE, nine);
	loader.add(BASE_AMOUNT_ASSETS.COMMA, comma);
	loader.add(BASE_AMOUNT_ASSETS.DOT, dot);
	loader.add(BASE_AMOUNT_ASSETS.X, x);
	loader.add(BASE_AMOUNT_ASSETS.BACKGROUND, background);
};
