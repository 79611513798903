import React from "react";
import styled from "styled-components";


const DecisionButton = (props: React.PropsWithChildren) => {
    return (
        <ButtonBg>
            <ButtonHoverBg className="btn-hover">
                {props.children}
            </ButtonHoverBg>
        </ButtonBg>
    );
}

export default DecisionButton;

const ButtonBg = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(49% 81% at 45% 47%, #FFE20345 0%, #073AFF00 100%),
    conic-gradient(from 180deg at 50% 50%, #af70c1 1%, #45d1bd 33%, #f9ad29 66%, #af70c1 100%),
    radial-gradient(142% 91% at 83% 7%, #FFDB00FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at -6% 74%, #FF0049FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at 111% 84%, #FF7000FF 0%, #FF0000FF 100%);
    font-weight: 700;
    margin: 8px;
    font-size: 22px;
    width: 128px;
    height: 60px;
    border-radius: 60px;
`

const ButtonHoverBg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #151515;
    position: absolute;
    color: #e4f2f4;
    width: 124px;
    height: 56px;
    border-radius: 50px;
    border: 2px solid #151515;

    &.btn-hover:hover {
        color: #151515;
        background: #e4f2f4;
    }    
    `


