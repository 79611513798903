import { Game } from "../../Game";
import {Container, Sprite, AnimatedSprite, Texture} from "pixi.js";
import { UI_ASSETS } from "../../assetsLoader/UiAssets";
import { Spine } from 'pixi-spine';
import { MINIGAME_ASSETS } from '../../assetsLoader/miniGameLoader';
import { MODAL_ASSETS } from '../../assetsLoader/modalLoader';
import RespinAnimation from '../RespinAnimation/RespinAnimation';
import RespinFeatureAnimation from '../RespinFeatureAnimation/RespinFeatureAnimation';
import BaseAmount from '../BaseAmount/BaseAmount';
import {REELS_FLAME_DESKTOP_ASSETS, REELS_FLAME_PORTRAIT_ASSETS} from '../../assetsLoader/reelsFlameLoader'

export class GameField {
    game: Game
    container: Container
    background?: Sprite
    border?: Sprite
    backgroundPortrait?: Sprite
    borderPortrait?: Sprite
    width: number
    height: number
    mask?: Sprite
    minigameReelsDesktop!: AnimatedSprite
    minigameReelsPortrait!: AnimatedSprite
    feature?: RespinAnimation;
    respinFeature?: RespinFeatureAnimation;
    baseAmount?: BaseAmount;
    tempSymbols: Container[];
    tempWildSymbols: Container[];
    blur?: Sprite;
    isFlameActive: boolean
    orientation: 'desktop' | 'portrait'

    constructor(game: Game, baseAmount: BaseAmount) {
        this.game = game
        this.width = 1152
        this.height = 926
        this.container = new Container();
        this.baseAmount = baseAmount;
        this.tempSymbols = []
        this.tempWildSymbols = []
        this.isFlameActive = false
        this.orientation = 'desktop';
    }
    
    init = async () => {
        this.background = new Sprite(Texture.from(UI_ASSETS.GAME_FIELD_BG))
        this.background.width = 1125
        this.background.height = 921
        this.background.x = 0
        this.background.y = 20
        this.container.sortableChildren = true;

        this.setupFeature();
        this.setupDesktopFlame();
        this.setupPortraitFlame();

        this.border = new Sprite(Texture.from(UI_ASSETS.GAME_FIELD))
        this.border.width = 1377
        this.border.height = 1021
        this.border.x = -125
        this.border.y = -45

        this.backgroundPortrait = new Sprite(Texture.from(UI_ASSETS.GAME_FIELD_BG_PORTRAIT))
        this.backgroundPortrait.width = 1180
        this.backgroundPortrait.height = 977
        this.backgroundPortrait.x = -20
        this.backgroundPortrait.y = 0

        this.borderPortrait = new Sprite(Texture.from(UI_ASSETS.GAME_FIELD_PORTRAIT))
        this.borderPortrait.width = 1212
        this.borderPortrait.height = 986
        this.borderPortrait.x = -41
        this.borderPortrait.y = -10

        this.border.zIndex = 1;
        this.borderPortrait.zIndex = 1;

        this.container.width = this.width
        this.container.height = this.height
        this.container.addChild(this.baseAmount!.container)
        this.container.addChild(this.background)
        this.container.addChild(this.border)
        this.container.addChild(this.backgroundPortrait)
        this.container.addChild(this.borderPortrait)
        this.container.x = 248
        this.container.y = 10
        this.container.name = 'GAME_FIELD'
        this.container.zIndex = 10
        this.game.assetsManager.gameContainer.addChild(this.container)
        this.game.resizeCallbacks.push(this.resize)
        
        this.baseAmount?.container.position.set(560, 475);
        this.setupBlur();
    }

    setupMask = () => {
        if(!this.game.slotMachine) return
        this.mask = new Sprite(Texture.from(UI_ASSETS.SHEET_MASK))
        this.mask.width = 1114
        this.mask.height = 900
        this.mask.x = 20
        this.mask.y = 16
        this.mask.zIndex = 1000
        this.game.slotMachine.reelsContainer.mask = this.mask
        this.game.slotMachine.reelsContainer.addChild(this.mask)
    }

    setupFeature = () => {
        this.feature = new RespinAnimation(this.game);
        this.container.addChild(this.feature.container);
        this.respinFeature = new RespinFeatureAnimation(this.game);
        this.container.addChild(this.respinFeature.container);
    }

    setupBlur = () => {
        this.blur = new Sprite(Texture.from(UI_ASSETS.GAME_FIELD_BLUR))
        this.blur.name = "REEL_CONTAINER_BLUR";
        this.blur.position.set(14, 35);
        this.blur.alpha = 0;
        this.blur.zIndex = 100;
        this.container.addChild(this.blur);
    }

    setBlur = (state: boolean) => {
        if (!this.blur) return;
        this.blur.alpha = state ? 1 : 0;
    }

    setupDesktopFlame = () => {
        const textureArray = [];
        const textures = Object.values(REELS_FLAME_DESKTOP_ASSETS);
        for (let texture of textures) {
            const textureData = Texture.from(texture)
            textureArray.push((textureData))
        }
        this.minigameReelsDesktop = new AnimatedSprite(textureArray);
        this.minigameReelsDesktop.animationSpeed = 0.5;
        this.minigameReelsDesktop.anchor.set(0.5)
        this.minigameReelsDesktop.visible = false;
        this.minigameReelsDesktop.name = 'MINIGAME_REELS_FRAME'
        this.minigameReelsDesktop.zIndex = -1;
        this.minigameReelsDesktop.position.set(565, 468)
        this.container.addChild(this.minigameReelsDesktop)
    }

    setupPortraitFlame = () => {
        const textureArray = [];
        const textures = Object.values(REELS_FLAME_PORTRAIT_ASSETS);
        for (let texture of textures) {
            const textureData = Texture.from(texture)
            textureArray.push((textureData))
        }
        this.minigameReelsPortrait = new AnimatedSprite(textureArray);
        this.minigameReelsPortrait.animationSpeed = 0.5;
        this.minigameReelsPortrait.anchor.set(0.5)
        this.minigameReelsPortrait.visible = false;
        this.minigameReelsPortrait.name = 'MINIGAME_REELS_FRAME'
        this.minigameReelsPortrait.zIndex = -1;
        this.minigameReelsPortrait.position.set(563, 483)
        this.minigameReelsPortrait.scale.set(-1.135, 1.13)
        this.container.addChild(this.minigameReelsPortrait)
    }

    removeTempScatters = (name: 'TEMP_SCATTER') => {
        if(this.container.children.filter((event) => event.name === name)) {
            const tempSymbols = this.container.children.filter((event) => event.name === name)
            for (let i = 0; i < tempSymbols.length; i++) {
                this.container.removeChild(tempSymbols[i])
            }
        }
    }

    removeTempWilds = (isSticky: boolean) => {
        const currentArray = isSticky ? this.tempSymbols : this.tempWildSymbols;
        if (currentArray.length === 0) return;

        currentArray.forEach(tempSymbol => {
            this.container.removeChild(tempSymbol);
        });

        this[isSticky ? 'tempSymbols' : 'tempWildSymbols'] = [];
    }

    /**
     * function for adaptive description
     */

    resize = () => {
        if (!this.container || !this.background) return
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.container.scale.set(1.56)
            this.container.x = 80
            this.container.y = 235;
            this.background.alpha = 0
            this.border!.alpha = 0
            this.backgroundPortrait!.alpha = 1
            this.borderPortrait!.alpha = 1
            this.orientation = 'portrait';
            if (this.isFlameActive) {
                this.minigameReelsDesktop.stop();
                this.minigameReelsDesktop.visible = false;
                this.minigameReelsPortrait.play();
                this.minigameReelsPortrait.visible = true;
            }
        } else {
            this.background.alpha = 1
            this.border!.alpha = 1
            this.backgroundPortrait!.alpha = 0
            this.borderPortrait!.alpha = 0
            this.orientation = "desktop";
            if (window.innerWidth <= 1080) {
                this.container.scale.set(0.91);
                this.container.y = 167;
                this.container.x = 450;
                if (this.isFlameActive) {
                    this.minigameReelsDesktop.play();
                    this.minigameReelsDesktop.visible = true;
                    this.minigameReelsPortrait.stop();
                    this.minigameReelsPortrait.visible = false;
                }
            } else {
                this.container.scale.set(1);
                this.container.x = 402
                this.container.y = 20

                if (this.isFlameActive) {
                    this.minigameReelsDesktop.play();
                    this.minigameReelsDesktop.visible = true;
                    this.minigameReelsPortrait.stop();
                    this.minigameReelsPortrait.visible = false;
                }
            }
        }
    }
}
