import {Loader} from "pixi.js";

import one from "../assets/free_spins_summary/numbers/1.png"
import two from "../assets/free_spins_summary/numbers/2.png"
import three from "../assets/free_spins_summary/numbers/3.png"
import four from "../assets/free_spins_summary/numbers/4.png"
import five from "../assets/free_spins_summary/numbers/5.png"
import six from "../assets/free_spins_summary/numbers/6.png"
import seven from "../assets/free_spins_summary/numbers/7.png"
import eight from "../assets/free_spins_summary/numbers/8.png"
import nine from "../assets/free_spins_summary/numbers/9.png"
import zero from "../assets/free_spins_summary/numbers/0.png"
import dot from '../assets/free_spins_summary/numbers/dot.png'
import l1 from '../assets/symbols/L1.png'
import l2 from '../assets/symbols/L2.png'
import l3 from '../assets/symbols/L3.png'
import l4 from '../assets/symbols/L4.png'
import h1 from '../assets/symbols/H1.png'
import h2 from '../assets/symbols/H2.png'
import h3 from '../assets/symbols/H3.png'
import h4 from '../assets/symbols/H4.png'
import wild from '../assets/symbols/WILD.png'
import scatter from '../assets/symbols/WILD.png'
import {CDN_BASE_URL} from "../../index";
export enum SYMBOLS_ASSETS {
    ONE_LOW = 'ONE_LOW',
    TWO_LOW = 'TWO_LOW',
    THREE_LOW = 'THREE_LOW',
    FOUR_LOW = 'FOUR_LOW',
    ONE_HIGH = 'ONE_HIGH',
    TWO_HIGH = 'TWO_HIGH',
    THREE_HIGH = 'THREE_HIGH',
    FOUR_HIGH = 'FOUR_HIGH',
    ONE_LOW_FAKE = 'ONE_LOW_FAKE',
    TWO_LOW_FAKE = 'TWO_LOW_FAKE',
    THREE_LOW_FAKE = 'THREE_LOW_FAKE',
    FOUR_LOW_FAKE = 'FOUR_LOW_FAKE',
    ONE_HIGH_FAKE = 'ONE_HIGH_FAKE',
    TWO_HIGH_FAKE = 'TWO_HIGH_FAKE',
    THREE_HIGH_FAKE = 'THREE_HIGH_FAKE',
    FOUR_HIGH_FAKE = 'FOUR_HIGH_FAKE',
    WILD_FAKE = "WILD_FAKE",
    CHEST_FAKE = "CHEST_FAKE",
    SCATTER_FAKE = "SCATTER_FAKE",
    WILD = "WILD",
    CHEST = "CHEST",
    SCATTER = "SCATTER",
    ONE = "ONE",
    TWO = "TWO",
    THREE = "THREE",
    FOUR = "FOUR",
    FIVE = "FIVE",
    SIX = "SIX",
    SEVEN = "SEVEN",
    EIGHT = "EIGHT",
    NINE = "NINE",
    ZERO = "ZERO",
    DOT = "DOT",
    SWAP_TO_WILD = "SWAP_TO_WILD",
}

export const loadSymbols = (loader: Loader) => {
    loader.add(SYMBOLS_ASSETS.ONE_LOW, `${CDN_BASE_URL}/symbols/L1/L1.json`)
    loader.add(SYMBOLS_ASSETS.TWO_LOW, `${CDN_BASE_URL}/symbols/L2/L2.json`)
    loader.add(SYMBOLS_ASSETS.THREE_LOW, `${CDN_BASE_URL}/symbols/L3/L3.json`)
    loader.add(SYMBOLS_ASSETS.FOUR_LOW, `${CDN_BASE_URL}/symbols/L4/L4.json`)
    loader.add(SYMBOLS_ASSETS.ONE_HIGH, `${CDN_BASE_URL}/symbols/H1/H1.json`)
    loader.add(SYMBOLS_ASSETS.TWO_HIGH, `${CDN_BASE_URL}/symbols/H2/H2.json`)
    loader.add(SYMBOLS_ASSETS.THREE_HIGH, `${CDN_BASE_URL}/symbols/H3/H3.json`)
    loader.add(SYMBOLS_ASSETS.FOUR_HIGH, `${CDN_BASE_URL}/symbols/H4/H4.json`)
    loader.add(SYMBOLS_ASSETS.SCATTER, `${CDN_BASE_URL}/symbols/Scatter/Scatter.json`)
    loader.add(SYMBOLS_ASSETS.WILD, `${CDN_BASE_URL}/symbols/WILD/Wild.json`)
    loader.add(SYMBOLS_ASSETS.CHEST, `${CDN_BASE_URL}/symbols/Chest/chest_anim.json`)
    loader.add(SYMBOLS_ASSETS.ONE_LOW_FAKE, l1)
    loader.add(SYMBOLS_ASSETS.TWO_LOW_FAKE, l2)
    loader.add(SYMBOLS_ASSETS.THREE_LOW_FAKE, l3)
    loader.add(SYMBOLS_ASSETS.FOUR_LOW_FAKE, l4)
    loader.add(SYMBOLS_ASSETS.ONE_HIGH_FAKE, h1)
    loader.add(SYMBOLS_ASSETS.TWO_HIGH_FAKE, h2)
    loader.add(SYMBOLS_ASSETS.THREE_HIGH_FAKE, h3)
    loader.add(SYMBOLS_ASSETS.FOUR_HIGH_FAKE, h4)
    loader.add(SYMBOLS_ASSETS.SCATTER_FAKE, scatter)
    loader.add(SYMBOLS_ASSETS.WILD_FAKE, wild)
    loader.add(SYMBOLS_ASSETS.ONE, one)
    loader.add(SYMBOLS_ASSETS.TWO, two)
    loader.add(SYMBOLS_ASSETS.THREE, three)
    loader.add(SYMBOLS_ASSETS.FOUR, four)
    loader.add(SYMBOLS_ASSETS.FIVE, five)
    loader.add(SYMBOLS_ASSETS.SIX, six)
    loader.add(SYMBOLS_ASSETS.SEVEN, seven)
    loader.add(SYMBOLS_ASSETS.EIGHT, eight)
    loader.add(SYMBOLS_ASSETS.NINE, nine)
    loader.add(SYMBOLS_ASSETS.ZERO, zero)
    loader.add(SYMBOLS_ASSETS.DOT, dot)
    loader.add(SYMBOLS_ASSETS.SWAP_TO_WILD, `${CDN_BASE_URL}/spine/symbol_swap/symbol_swap.json`)
}
