import styled from "styled-components";
import { Game } from "../../../../game/Game";

interface IGame {
    game: Game
}

const MinMaxBet = ({game}: IGame) => {

    let betsArray: number[];
    if (!game.slotMachine) {
        betsArray = []
    } else {
        betsArray = game.slotMachine?.betList;
    }
    
    const minBet = betsArray[0];
    const maxBet = betsArray[betsArray.length - 1];

    return (
        <MinMaxBetTextContainer>
            <MinMaxBetDesciption>
                Min Bet: {minBet.toFixed(2)} USD
            </MinMaxBetDesciption>
            <MinMaxBetDesciption>
                Max Bet: {maxBet.toFixed(2)} USD
            </MinMaxBetDesciption>
        </MinMaxBetTextContainer>

    );
}

export default MinMaxBet;

const MinMaxBetTextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #2A2C2B;
    width: 100%;
    height: 30px;
    border-radius: 7px;
    padding: 0 6px;
    margin: 8px 0;
`

const MinMaxBetDesciption = styled.p`
    font-family: Rubik;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center; 
`