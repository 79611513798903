import { EventType, getEvent } from "../../GameEventBus";
import { Game } from "../Game";

export default function clickSpin(this: Game) {
    if (this.slotMachine?.isFsRunning) return;
    if (this.slotMachine?.isSpinning) {
        getEvent(EventType.ANIMATION_SPEED).send(0.2);

        if (this.assetsManager.autoPlayModal?.isAutoPlayRunning) {
            this.assetsManager.autoPlayBtn!.setButtonState(false);
            this.assetsManager.autoPlayModal.setCounter(0);
        }

        return;
    }

    if (this.isDebug) {
        const data = localStorage.getItem("debugReels");
        const debugReels = JSON.parse(data!);
        const command = debugReels.command ? debugReels.command : "debug";
        this.slotMachine?.changeCommand(command);
        this.slotMachine?.onSpin();
    } else {
        this.slotMachine?.onSpin();
    }
}