import {Loader} from "pixi.js";
import arrowDownIcon from '../assets/modal/auto-play-modal-arrow-small.svg'
import autoPLayStatus from '../assets/modal/auto-play-status.png'
import bg from "../assets/free_spins_summary/free_spins_summary_bg.png";
import youWon from "../assets/free_spins_summary/you_won.png"
import winStartModalText from "../assets/modal/win-start-modal-text.png";
import respin from "../assets/modal/respin.png"
import maxWinCap from "../assets/modal/max_win_cap.png"
import {CDN_BASE_URL} from "../../index";

export enum MODAL_ASSETS {
    ARROW_DOWN_ICON = 'ARROW_DOWN_ICON',
    STATUS_ICON = 'STATUS_ICON',
    WIN_START_MODAL_TEXT = "WIN_START_MODAL_TEXT",
    MODAL_BACKGROUND = "MODAL_BACKGROUND",
    YOU_WON = "YOU_WON",
    BIG_WIN = "BIG_WIN",
    MEGA_WIN = "MEGA_WIN",
    SUPER_WIN = "SUPER_WIN",
    BW_SHEET = "BW_SHEET",
    RESPIN = "RESPIN",
    COIN_SHOWER = "COIN_SHOWER",
    MAX_WIN_CAP_TEXT = "MAX_WIN_CAP_TEXT",
}

export const loadModalAssets = (loader: Loader) => {
    loader.add(MODAL_ASSETS.ARROW_DOWN_ICON, arrowDownIcon)
    loader.add(MODAL_ASSETS.STATUS_ICON, autoPLayStatus)
    loader.add(MODAL_ASSETS.MODAL_BACKGROUND, bg)
    loader.add(MODAL_ASSETS.YOU_WON, youWon)
    loader.add(MODAL_ASSETS.WIN_START_MODAL_TEXT, winStartModalText)
    loader.add(MODAL_ASSETS.BIG_WIN, `${CDN_BASE_URL}/spine/big_win/Big_Win_anim.json`)
    loader.add(MODAL_ASSETS.MEGA_WIN, `${CDN_BASE_URL}/spine/mega_win/Mega_Win_anim.json`)
    loader.add(MODAL_ASSETS.SUPER_WIN, `${CDN_BASE_URL}/spine/super_win/Super_Win_anim.json`)
    loader.add(MODAL_ASSETS.RESPIN, respin)
    loader.add(MODAL_ASSETS.BW_SHEET, `${CDN_BASE_URL}/bw/bw_numbers.json`)
    loader.add(MODAL_ASSETS.COIN_SHOWER, `${CDN_BASE_URL}/spine/coin_showers/Viking_coin_shower.json`)
    loader.add(MODAL_ASSETS.MAX_WIN_CAP_TEXT, maxWinCap)
}
