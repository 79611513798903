import styled from "styled-components";
import { useState, useCallback } from "react";
import { EventType, useEventReceiver } from "../../GameEventBus";
import { useNavigate } from "react-router-dom";

const TokenAuthError = () => {
    const navigate = useNavigate()
    const [isActive, setActive] = useState(false)

    const redirectHandle = useCallback(() => {
        setActive(false)
        navigate('auth')
    }, [setActive])

    useEventReceiver<boolean>(EventType.AUTH_IS_SUCCESS, value => {
        if (value) {
            setActive(false)
        } else {
            setActive(true)
        }
    })
    return (
        <Wrapper style={{ display: isActive ? 'flex' : 'none' }}>
            <Container>
                <Header>
                    Token authentication error
                </Header>
                <ButtonBg>
                    <Button className="hover-btn" onClick={() => redirectHandle()}>
                        try again
                    </Button>
                </ButtonBg>
            </Container>
        </Wrapper>
    );
}

export default TokenAuthError;


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: rgba(21, 21, 21, 0.8);
    left: 0;
    top: 0;
`;

const Container = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: fixed;
    width: 42vw;
    height: 21vw;
    background: #151515;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    justify-content: start;

    @media (max-width: 666px) {
        width: 90vw;
        height: 45vw;
    }

`
const Header = styled.h1`
    margin: auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 2.29vw;
    line-height: 2.75vw;
    text-align: center;
    color: #E2F4F2;

    @media (max-width: 666px) {
        font-size: calc(2.29vw * 2);
        line-height: calc(2.75vw * 2);
    }
`

const ButtonBg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10.67vw;
    height: 4.14vw;
    margin: auto;
    margin-top: 0;
    position: relative;
    border-radius: 3.125vw;
    background: conic-gradient(
        from 180deg at 50% 50%,
        #af70c1 0deg,
        #45d1bd 125.63deg,
        #f9ad29 266.25deg,
        #af70c1 360deg
    );

    @media (max-width: 666px) {
        width: calc(10.67vw * 2);
        height: calc(4.14vw * 2);
    }
`


const Button = styled.div`
    margin: auto;
    background: #151515;    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #e4f2f4 ;
    width: 10.46vw;
    height: 3.96vw;
    border: 0.14vw solid #151515;
    border-radius: 3.125vw;
    font-family: 'Rubik';
    font-size: 1.5vw;

    &.hover-btn: hover {
        color: #151515 ;
        background: #e4f2f4;
    }

    @media (max-width: 666px) {
        width: calc(10.46vw * 2);
        height: calc(3.96vw * 2);
        font-size: calc(1.5vw * 2);
    }
`