import * as PIXI from "pixi.js";
import {Application, Container, Texture} from "pixi.js";
import {SYMBOL_SIZE, SYMBOLS} from "../constants/Symbols";
import {Cords, SymbolName, SymbolPriority} from "../../api/types";
import {Spine} from "pixi-spine";
import {SYMBOLS_ASSETS} from "../assetsLoader/SymbolsLoader";
import {Timer} from "../../utils/Timer";
import {EventType, getEvent} from "../../GameEventBus";
import {SoundNames} from '../constants/sounds';
import {Sounds} from './SoundController';
import {Game} from '../Game';
import {scatterSymbolCoords, wildSymbolCoords} from '../constants/coords';

export class SymbolItem {
    name: SymbolName;
    asset: string;
    symbolId: string;
    sprite?: PIXI.Sprite;
    spine?: Spine;
    scatter?: Spine;
    wild?: Spine;
    container: Container;
    game: Game;

    constructor(id: string, name: SymbolName, asset: string, game: Game) {
        this.symbolId = id
        this.name = name
        this.asset = asset
        this.container = new Container()
        this.container.name = `${name}`;
        this.container.sortableChildren = true;
        this.game = game

        if (![SYMBOLS_ASSETS.ONE_LOW, SYMBOLS_ASSETS.TWO_LOW, SYMBOLS_ASSETS.THREE_LOW, SYMBOLS_ASSETS.FOUR_LOW, SYMBOLS_ASSETS.CHEST, SYMBOLS_ASSETS.WILD, SYMBOLS_ASSETS.SCATTER].includes(asset as SYMBOLS_ASSETS)) {
            this.sprite = new PIXI.Sprite(Texture.from(asset + "_FAKE"));
            this.sprite.width = SYMBOL_SIZE;
            this.sprite.height = SYMBOL_SIZE;
            this.sprite.x -= 3.5
            this.sprite.y -= 3.5

            if (asset === SYMBOLS_ASSETS.THREE_HIGH) {
                this.sprite.x = -3.9
                this.sprite.y = -4.55
            }
            if (asset === SYMBOLS_ASSETS.FOUR_HIGH) {
                this.sprite.x = -3
                this.sprite.y = -4
            }
            this.container.addChild(this.sprite)
        }

        this.container.interactiveChildren = true;
        this.container.width = SYMBOL_SIZE
        this.container.height = SYMBOL_SIZE

        const spineData = this.game.app.loader.resources[this.asset].spineData!
        if (!spineData) return

        this.spine = new Spine(spineData)

        if (this.spine.state.hasAnimation('placement')) this.spine.state.setAnimation(0, 'placement', false)

        this.spine.width = SYMBOL_SIZE
        this.spine.height = SYMBOL_SIZE


        this.spine.position.set(this.spine.width / 2, this.spine.height / 2)
        this.spine.scale.set(1, 1)
        this.container.addChild(this.spine)
        if (this.sprite) {
            this.sprite.width = this.spine.width;
            this.sprite.height = this.spine.height;
            this.sprite.scale.set(1, 1);
        }
        if (this.spine.state.hasAnimation('idle')) this.spine.state.setAnimation(0, 'idle', false)

        if (["CHEST"].includes(name)) this.spine.state.setAnimation(0, "win_anim", false);
        if (["SCATTER"].includes(name)) {
            this.scatter = new Spine(spineData)
            this.scatter.width = SYMBOL_SIZE
            this.scatter.height = SYMBOL_SIZE
            this.scatter.position.set(this.spine.width / 2, this.spine.height / 2)
            this.scatter.scale.set(1, 1)
            this.scatter.visible = false
        }
        if (["WILD"].includes(name)) {
            this.wild = new Spine(spineData)
            this.wild.width = SYMBOL_SIZE
            this.wild.height = SYMBOL_SIZE
            this.wild.position.set(this.spine.width / 2, this.spine.height / 2)
            this.wild.scale.set(1, 1)
            this.wild.visible = false
        }

        if (this.sprite) {
            this.spine.renderable = false
            this.sprite.renderable = true
        }

    }


    win = (game: Game, cord: Cords) => {
        if (
            [
                "L1",
                "L2",
                "L3",
                "L4",
                "H1",
                "H2",
                "H3",
                "H4",
                "WILD",
                "SCATTER",
            ].includes(this.name) &&
            this.spine
        ) {
            if (this.sprite) {
                this.sprite.renderable = false;
                this.spine.renderable = true;
            }

            this.checkSpecialSymbols(this.spine, game, cord)

            if (cord.y) this.container.zIndex = 90 / SymbolPriority[this.name];
        }

        setTimeout(() => {
            if (this.spine && this.sprite) {
                this.sprite.renderable = true;
                this.spine.renderable = false;
            }
        }, 2000);
    };

    checkSpecialSymbols = (spine: Spine, game: Game, cord: Cords) => {
        const isStickyWild = this.game.slotMachine?.slotEvents?.isStickyWild!;
        const symbolName = isStickyWild ? `TEMP_STICKY_WILD_${cord.x}_${cord.y}` : `TEMP_WILD_${cord.x}_${cord.y}`;
        const checkSymbol:any = this.game.assetsManager.gameField?.container.children.filter((event) => event.name === symbolName);

        if (this.name === "SCATTER") {
            spine.state.setAnimation(0, "win", false);
            this.addScatterSymbol(this.container, this.scatter, game, cord);
        } else if (this.name === "WILD") {
            if (checkSymbol!.length === 0) {
                this.addStickyWildSymbol(this.container, this.wild, game, cord, isStickyWild);
            } else if (checkSymbol[0].children && !isStickyWild) {
                checkSymbol[0].children.forEach((child: any) => {
                    if (child instanceof Spine) {
                        child.state.setAnimation(0, "win", false);
                    }
                });
            }
        } else {
            spine.state.setAnimation(0, "win", false);
        }
    }

    addScatterSymbol = (container: Container, spine: Spine | undefined, game: Game, cord: Cords) => {
        const containerCopy = new Container();
        containerCopy.width = container.width;
        containerCopy.height = container.height;
        containerCopy.addChild(spine!);
        spine!.visible = true;
        spine!.state.setAnimation(0, "win", false);
        game.assetsManager.gameField?.container.addChild(containerCopy);
        containerCopy.name = "TEMP_SCATTER";
        containerCopy.x = scatterSymbolCoords[cord.x][cord.y].x;
        containerCopy.y = scatterSymbolCoords[cord.x][cord.y].y;
    }

    addStickyWildSymbol = (container: Container, spine: Spine | undefined, game: Game, cord: Cords, isSticky: boolean) => {
        const containerCopy = new Container();
        containerCopy.width = container.width;
        containerCopy.height = container.height;
        containerCopy.addChild(spine!);
        spine!.visible = true;
        this.container.visible = false
        Sounds.play(SoundNames.WILD_LOCKED);
        if (isSticky) {
            spine!.state.setAnimation(0, "sticky", false);
            containerCopy.name = `TEMP_STICKY_WILD_${cord.x}_${cord.y}`;
            this.game.assetsManager.gameField?.tempSymbols.push(containerCopy);
        } else {
            containerCopy.name = `TEMP_WILD_${cord.x}_${cord.y}`;
            spine!.name = 'WILD';
            spine!.state.setAnimation(0, "win", false);
            this.game.assetsManager.gameField?.tempWildSymbols.push(containerCopy);
        }
        game.assetsManager.gameField?.container.addChild(containerCopy);
        containerCopy.x = wildSymbolCoords[cord.x][cord.y].x;
        containerCopy.y = wildSymbolCoords[cord.x][cord.y].y;
        this.container.visible = true
    }

    static getSymbol = (
        name: string,
        game: Game,
        fake: boolean = false
    ) => {
        const symbol = SYMBOLS.find((symbol) => symbol.name === name);
        if (!symbol) {
            return;
        }

        return new SymbolItem(symbol.id, symbol.name, symbol.asset, game);
    };
    // METHODS EXAMPLE: WIN_ANIM, LANDING_ANIM, DESTRUCTION_ANIM
}
