import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Game } from '../../Game';
import { Texture } from 'pixi.js';
import { BUTTONS_ASSETS } from '../../assetsLoader/ButtonsLoader';
import { EventType, getEvent } from '../../../GameEventBus';
import { SoundNames } from '../../constants/sounds';
import { Sounds } from '../../classes/SoundController';

class BetBtn {
    game: Game;
    btn: PIXI.Container;
    btnDefault: PIXI.Sprite;
    btnHover: PIXI.Sprite;
    btnInactive: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;

    constructor(game: Game) {
        this.game = game;
        this.btn = new PIXI.Container();
        this.btnDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BET_DEFAULT_BTN));
        this.btnHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BET_ACTIVE_BTN));
        this.btnInactive = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BET_INACTIVE_BTN));
        this.pointerIsDown = false;
        this.pointerIsOver = false;

    }

    init = () => {
        this.setupBtnContainer();
        this.setupBtnDefault();
        this.setupBtnHover();
        this.setupBtnInactive();
        // this.game.assetsManager.gameContainer.addChild(this.btn);
        // this.game.resizeCallbacks.push(this.resize)
    }

    setupBtnContainer = () => {

        this.btn.x = 1640;
        this.btn.y = 3000;
        this.btn.interactive = true;
        this.btn.cursor = 'pointer';
        this.btn.name = "BET_BTN";
        this.btn
            .on('pointerup', this.DoPointerUp)
            .on('pointerupoutside', this.DoPointerUpOutside)
            .on('touchstart', this.onClick)
            .on('click', this.onClick)
            .on('pointerover', this.DoPointerOver)
            .on('pointerout', this.DoPointerOut)
        this.btn.visible = false;
        this.game.inactiveElementsWhileSpin.push(this.btn)
    }

    setupBtnDefault = () => {
        this.btn.addChild(this.btnDefault);
    }

    setupBtnHover = () => {
        this.btnHover.alpha = 0;
        this.btn.addChild(this.btnHover);
    }

    setupBtnInactive = () => {
        this.btnInactive.visible = false;
        this.btn.addChild(this.btnInactive);
    }

    onClick = () => {
        this.DoPointerDown();
        getEvent(EventType.OPEN_TOTAL_BET_MOBILE).send(true);
    }

    DoPointerUp = () => {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnHover,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
            wiggleTween.fromTo(
                this.btnDefault,
                0,
                { alpha: 1 },
                { alpha: 0, repeat: 0 }
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnDefault,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
        }
        this.pointerIsDown = false;
    }

    DoPointerUpOutside = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    DoPointerDown = () => {
        Sounds.play(SoundNames.BTN_UI);
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );

        this.pointerIsDown = true;
    }

    DoPointerOver = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0.3,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsOver = true;
    }

    DoPointerOut = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0.3,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            if (this.game.leftHandedMode) {
                this.btn.scale.set(2.38);
                this.btn.visible = true;
                this.btn.y = 2360;
                this.btn.x = 1292;
            } else {
                this.btn.scale.set(2.38);
                this.btn.visible = true;
                this.btn.y = 2360;
                this.btn.x = 360;
            }

        } else {
            if (window.innerWidth <= 1080) {
                if (this.game.leftHandedMode) {
                    this.btn.x = 90;
                    this.btn.y = 770;
                    this.btn.scale.set(1.7);
                    this.btn.visible = true;
                } else {
                    this.btn.x = 1635;
                    this.btn.y = 770;
                    this.btn.scale.set(1.7);
                    this.btn.visible = true;
                }

            } else {
                this.btn.x = 1635;
                this.btn.y = 770;
                this.btn.visible = false;
                getEvent(EventType.OPEN_TOTAL_BET_MOBILE).send(false);
            }
        }

    }

}

export default BetBtn;
