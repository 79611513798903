import { Spine } from "pixi-spine";
import { UI_ASSETS } from "../../assetsLoader/UiAssets";
import { Game } from "../../Game";
import { SymbolName } from "../../../api/types";
import { SoundNames } from '../../constants/sounds';
import { Sounds } from '../../classes/SoundController';
import { Timer } from '../../../utils/Timer';
import {Container, Texture, AnimatedSprite} from "pixi.js";
import {ANTISIPATION_ASSETS} from '../../assetsLoader/antisipationLoader'

export default class Antisipation {
    game: Game;
    container: Container;
    antisipationIndex: number[];

    constructor(game: Game) {
        this.game = game;
        this.container = new Container();
        this.antisipationIndex = [];
    }

    init = async () => {
        this.setupContainer();
        this.setupAntisipation();
        this.game.assetsManager.gameContainer.addChild(this.container);
        this.game.resizeCallbacks.push(this.resize);
    }

    setupContainer = () => {
        this.container.name = "ANTISIPATION";
        this.container.zIndex = 11;
    }

    setupAntisipation = () => {
        for (let i = 0; i < 5; i++) {
            const textureArray = [];

            const textures = Object.values(ANTISIPATION_ASSETS);
            for (let texture of textures) {
                const textureData = Texture.from(texture)
                textureArray.push((textureData))
            }

            const antisipation = new AnimatedSprite(textureArray);
            antisipation.animationSpeed = 0.6;
            antisipation.anchor.set(0.5)
            antisipation.x = 229 * i;
            antisipation.visible = false;
            this.container.addChild(antisipation);
        }
    }

    animate = (id: number) => {
        const antsipation = this.container.children[id] as AnimatedSprite;
        antsipation.visible = true;
        antsipation.play()
    }

    stop = (id: number) => {
        const antsipation = this.container.children[id] as AnimatedSprite;
        antsipation.visible = false;
        antsipation.stop();
    }

    reset = () => {
        this.antisipationIndex.splice(0, this.antisipationIndex.length);
    }

    checkAntisipation = (board: SymbolName[][]) => {
        let flag = false;
        let counter = 0;
        const scatterSounds = [
            SoundNames.SCATTER_LAND_0,
            SoundNames.SCATTER_LAND_1,
            SoundNames.SCATTER_LAND_2,
        ]
        board.forEach((symbol, index) => {
            if (flag) this.antisipationIndex.push(index);
            if (symbol.includes("SCATTER")) {
                const timer = new Timer(() => {
                    if(counter < 3) Sounds.play(scatterSounds[counter]);
                }, (250 + index * 250) * this.game.slotMachine?.currentSpeed!);
                timer.initialize();
                counter++
            };
            if (counter >= 2) flag = true;
        })
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.container.scale.set(1.56);
            this.container.x = 220;
            this.container.y = 1000;
        } else {
            if (window.innerWidth <= 1080) {
                this.container.scale.set(0.91);
                this.container.x = 534;
                this.container.y = 615;
            } else {
                this.container.scale.set(1);
                this.container.x = 491;
                this.container.y = 514;
            }
        }

    }
}