import {Loader} from "pixi.js";
import slide_desktop_000 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00000.webp"
import slide_desktop_002 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00002.webp"
import slide_desktop_004 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00004.webp"
import slide_desktop_006 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00006.webp"
import slide_desktop_008 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00008.webp"
import slide_desktop_010 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00010.webp"
import slide_desktop_012 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00012.webp"
import slide_desktop_014 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00014.webp"
import slide_desktop_016 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00016.webp"
import slide_desktop_018 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00018.webp"
import slide_desktop_020 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00020.webp"
import slide_desktop_022 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00022.webp"
import slide_desktop_024 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00024.webp"
import slide_desktop_026 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00026.webp"
import slide_desktop_028 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00028.webp"
import slide_desktop_030 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00030.webp"
import slide_desktop_032 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00032.webp"
import slide_desktop_034 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00034.webp"
import slide_desktop_036 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00036.webp"
import slide_desktop_038 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00038.webp"
import slide_desktop_040 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00040.webp"
import slide_desktop_042 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00042.webp"
import slide_desktop_044 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00044.webp"
import slide_desktop_046 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00046.webp"
import slide_desktop_048 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00048.webp"
import slide_desktop_050 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00050.webp"
import slide_desktop_052 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00052.webp"
import slide_desktop_054 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00054.webp"
import slide_desktop_056 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00056.webp"
import slide_desktop_058 from "../assets/reels_flame_sequence/Reel_desktop_sequence/Desktop_Reel_00058.webp"

import slide_portrait_000 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00000.webp"
import slide_portrait_002 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00002.webp"
import slide_portrait_004 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00004.webp"
import slide_portrait_006 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00006.webp"
import slide_portrait_008 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00008.webp"
import slide_portrait_010 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00010.webp"
import slide_portrait_012 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00012.webp"
import slide_portrait_014 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00014.webp"
import slide_portrait_016 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00016.webp"
import slide_portrait_018 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00018.webp"
import slide_portrait_020 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00020.webp"
import slide_portrait_022 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00022.webp"
import slide_portrait_024 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00024.webp"
import slide_portrait_026 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00026.webp"
import slide_portrait_028 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00028.webp"
import slide_portrait_030 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00030.webp"
import slide_portrait_032 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00032.webp"
import slide_portrait_034 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00034.webp"
import slide_portrait_036 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00036.webp"
import slide_portrait_038 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00038.webp"
import slide_portrait_040 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00040.webp"
import slide_portrait_042 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00042.webp"
import slide_portrait_044 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00044.webp"
import slide_portrait_046 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00046.webp"
import slide_portrait_048 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00048.webp"
import slide_portrait_050 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00050.webp"
import slide_portrait_052 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00052.webp"
import slide_portrait_054 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00054.webp"
import slide_portrait_056 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00056.webp"
import slide_portrait_058 from "../assets/reels_flame_sequence/Reel_portrait_sequence/Portrait_Reel_00058.webp"

export enum REELS_FLAME_DESKTOP_ASSETS {
    REELS_FLAME_DESKTOP_SEQUENCE_000 = 'REELS_FLAME_DESKTOP_SEQUENCE_000',
    REELS_FLAME_DESKTOP_SEQUENCE_002 = 'REELS_FLAME_DESKTOP_SEQUENCE_002',
    REELS_FLAME_DESKTOP_SEQUENCE_004 = 'REELS_FLAME_DESKTOP_SEQUENCE_004',
    REELS_FLAME_DESKTOP_SEQUENCE_006 = 'REELS_FLAME_DESKTOP_SEQUENCE_006',
    REELS_FLAME_DESKTOP_SEQUENCE_008 = 'REELS_FLAME_DESKTOP_SEQUENCE_008',
    REELS_FLAME_DESKTOP_SEQUENCE_010 = 'REELS_FLAME_DESKTOP_SEQUENCE_010',
    REELS_FLAME_DESKTOP_SEQUENCE_012 = 'REELS_FLAME_DESKTOP_SEQUENCE_012',
    REELS_FLAME_DESKTOP_SEQUENCE_014 = 'REELS_FLAME_DESKTOP_SEQUENCE_014',
    REELS_FLAME_DESKTOP_SEQUENCE_016 = 'REELS_FLAME_DESKTOP_SEQUENCE_016',
    REELS_FLAME_DESKTOP_SEQUENCE_018 = 'REELS_FLAME_DESKTOP_SEQUENCE_018',
    REELS_FLAME_DESKTOP_SEQUENCE_020 = 'REELS_FLAME_DESKTOP_SEQUENCE_020',
    REELS_FLAME_DESKTOP_SEQUENCE_022 = 'REELS_FLAME_DESKTOP_SEQUENCE_022',
    REELS_FLAME_DESKTOP_SEQUENCE_024 = 'REELS_FLAME_DESKTOP_SEQUENCE_024',
    REELS_FLAME_DESKTOP_SEQUENCE_026 = 'REELS_FLAME_DESKTOP_SEQUENCE_026',
    REELS_FLAME_DESKTOP_SEQUENCE_028 = 'REELS_FLAME_DESKTOP_SEQUENCE_028',
    REELS_FLAME_DESKTOP_SEQUENCE_030 = 'REELS_FLAME_DESKTOP_SEQUENCE_030',
    REELS_FLAME_DESKTOP_SEQUENCE_032 = 'REELS_FLAME_DESKTOP_SEQUENCE_032',
    REELS_FLAME_DESKTOP_SEQUENCE_034 = 'REELS_FLAME_DESKTOP_SEQUENCE_034',
    REELS_FLAME_DESKTOP_SEQUENCE_036 = 'REELS_FLAME_DESKTOP_SEQUENCE_036',
    REELS_FLAME_DESKTOP_SEQUENCE_038 = 'REELS_FLAME_DESKTOP_SEQUENCE_038',
    REELS_FLAME_DESKTOP_SEQUENCE_040 = 'REELS_FLAME_DESKTOP_SEQUENCE_040',
    REELS_FLAME_DESKTOP_SEQUENCE_042 = 'REELS_FLAME_DESKTOP_SEQUENCE_042',
    REELS_FLAME_DESKTOP_SEQUENCE_044 = 'REELS_FLAME_DESKTOP_SEQUENCE_044',
    REELS_FLAME_DESKTOP_SEQUENCE_046 = 'REELS_FLAME_DESKTOP_SEQUENCE_046',
    REELS_FLAME_DESKTOP_SEQUENCE_048 = 'REELS_FLAME_DESKTOP_SEQUENCE_048',
    REELS_FLAME_DESKTOP_SEQUENCE_050 = 'REELS_FLAME_DESKTOP_SEQUENCE_050',
    REELS_FLAME_DESKTOP_SEQUENCE_052 = 'REELS_FLAME_DESKTOP_SEQUENCE_052',
    REELS_FLAME_DESKTOP_SEQUENCE_054 = 'REELS_FLAME_DESKTOP_SEQUENCE_054',
    REELS_FLAME_DESKTOP_SEQUENCE_056 = 'REELS_FLAME_DESKTOP_SEQUENCE_056',
    REELS_FLAME_DESKTOP_SEQUENCE_058 = 'REELS_FLAME_DESKTOP_SEQUENCE_058',

}
export enum REELS_FLAME_PORTRAIT_ASSETS {
    REELS_FLAME_PORTRAIT_SEQUENCE_000 = 'REELS_FLAME_PORTRAIT_SEQUENCE_000',
    REELS_FLAME_PORTRAIT_SEQUENCE_002 = 'REELS_FLAME_PORTRAIT_SEQUENCE_002',
    REELS_FLAME_PORTRAIT_SEQUENCE_004 = 'REELS_FLAME_PORTRAIT_SEQUENCE_004',
    REELS_FLAME_PORTRAIT_SEQUENCE_006 = 'REELS_FLAME_PORTRAIT_SEQUENCE_006',
    REELS_FLAME_PORTRAIT_SEQUENCE_008 = 'REELS_FLAME_PORTRAIT_SEQUENCE_008',
    REELS_FLAME_PORTRAIT_SEQUENCE_010 = 'REELS_FLAME_PORTRAIT_SEQUENCE_010',
    REELS_FLAME_PORTRAIT_SEQUENCE_012 = 'REELS_FLAME_PORTRAIT_SEQUENCE_012',
    REELS_FLAME_PORTRAIT_SEQUENCE_014 = 'REELS_FLAME_PORTRAIT_SEQUENCE_014',
    REELS_FLAME_PORTRAIT_SEQUENCE_016 = 'REELS_FLAME_PORTRAIT_SEQUENCE_016',
    REELS_FLAME_PORTRAIT_SEQUENCE_018 = 'REELS_FLAME_PORTRAIT_SEQUENCE_018',
    REELS_FLAME_PORTRAIT_SEQUENCE_020 = 'REELS_FLAME_PORTRAIT_SEQUENCE_020',
    REELS_FLAME_PORTRAIT_SEQUENCE_022 = 'REELS_FLAME_PORTRAIT_SEQUENCE_022',
    REELS_FLAME_PORTRAIT_SEQUENCE_024 = 'REELS_FLAME_PORTRAIT_SEQUENCE_024',
    REELS_FLAME_PORTRAIT_SEQUENCE_026 = 'REELS_FLAME_PORTRAIT_SEQUENCE_026',
    REELS_FLAME_PORTRAIT_SEQUENCE_028 = 'REELS_FLAME_PORTRAIT_SEQUENCE_028',
    REELS_FLAME_PORTRAIT_SEQUENCE_030 = 'REELS_FLAME_PORTRAIT_SEQUENCE_030',
    REELS_FLAME_PORTRAIT_SEQUENCE_032 = 'REELS_FLAME_PORTRAIT_SEQUENCE_032',
    REELS_FLAME_PORTRAIT_SEQUENCE_034 = 'REELS_FLAME_PORTRAIT_SEQUENCE_034',
    REELS_FLAME_PORTRAIT_SEQUENCE_036 = 'REELS_FLAME_PORTRAIT_SEQUENCE_036',
    REELS_FLAME_PORTRAIT_SEQUENCE_038 = 'REELS_FLAME_PORTRAIT_SEQUENCE_038',
    REELS_FLAME_PORTRAIT_SEQUENCE_040 = 'REELS_FLAME_PORTRAIT_SEQUENCE_040',
    REELS_FLAME_PORTRAIT_SEQUENCE_042 = 'REELS_FLAME_PORTRAIT_SEQUENCE_042',
    REELS_FLAME_PORTRAIT_SEQUENCE_044 = 'REELS_FLAME_PORTRAIT_SEQUENCE_044',
    REELS_FLAME_PORTRAIT_SEQUENCE_046 = 'REELS_FLAME_PORTRAIT_SEQUENCE_046',
    REELS_FLAME_PORTRAIT_SEQUENCE_048 = 'REELS_FLAME_PORTRAIT_SEQUENCE_048',
    REELS_FLAME_PORTRAIT_SEQUENCE_050 = 'REELS_FLAME_PORTRAIT_SEQUENCE_050',
    REELS_FLAME_PORTRAIT_SEQUENCE_052 = 'REELS_FLAME_PORTRAIT_SEQUENCE_052',
    REELS_FLAME_PORTRAIT_SEQUENCE_054 = 'REELS_FLAME_PORTRAIT_SEQUENCE_054',
    REELS_FLAME_PORTRAIT_SEQUENCE_056 = 'REELS_FLAME_PORTRAIT_SEQUENCE_056',
    REELS_FLAME_PORTRAIT_SEQUENCE_058 = 'REELS_FLAME_PORTRAIT_SEQUENCE_058',
}

export const reelsFlameLoader = (loader: Loader) => {
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_000, slide_desktop_000)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_002, slide_desktop_002)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_004, slide_desktop_004)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_006, slide_desktop_006)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_008, slide_desktop_008)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_010, slide_desktop_010)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_012, slide_desktop_012)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_014, slide_desktop_014)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_016, slide_desktop_016)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_018, slide_desktop_018)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_020, slide_desktop_020)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_022, slide_desktop_022)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_024, slide_desktop_024)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_026, slide_desktop_026)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_028, slide_desktop_028)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_030, slide_desktop_030)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_032, slide_desktop_032)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_034, slide_desktop_034)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_036, slide_desktop_036)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_038, slide_desktop_038)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_040, slide_desktop_040)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_042, slide_desktop_042)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_044, slide_desktop_044)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_046, slide_desktop_046)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_048, slide_desktop_048)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_050, slide_desktop_050)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_052, slide_desktop_052)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_054, slide_desktop_054)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_056, slide_desktop_056)
    loader.add(REELS_FLAME_DESKTOP_ASSETS.REELS_FLAME_DESKTOP_SEQUENCE_058, slide_desktop_058)

    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_000, slide_portrait_000)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_002, slide_portrait_002)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_004, slide_portrait_004)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_006, slide_portrait_006)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_008, slide_portrait_008)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_010, slide_portrait_010)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_012, slide_portrait_012)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_014, slide_portrait_014)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_016, slide_portrait_016)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_018, slide_portrait_018)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_020, slide_portrait_020)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_022, slide_portrait_022)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_024, slide_portrait_024)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_026, slide_portrait_026)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_028, slide_portrait_028)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_030, slide_portrait_030)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_032, slide_portrait_032)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_034, slide_portrait_034)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_036, slide_portrait_036)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_038, slide_portrait_038)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_040, slide_portrait_040)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_042, slide_portrait_042)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_044, slide_portrait_044)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_046, slide_portrait_046)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_048, slide_portrait_048)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_050, slide_portrait_050)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_052, slide_portrait_052)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_054, slide_portrait_054)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_056, slide_portrait_056)
    loader.add(REELS_FLAME_PORTRAIT_ASSETS.REELS_FLAME_PORTRAIT_SEQUENCE_058, slide_portrait_058)
}
