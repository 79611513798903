import {Loader} from "pixi.js";
import chestClosed from "../assets/minigame/minigame_chest_closed.png"
import chestOpened from "../assets/minigame/minigame_chest_opened.png"
import fbFragment from "../assets/minigame/feature_bar/fb_fragment.png"
import fbMask from "../assets/minigame/feature_bar/fb_mask.png"
import minigameBG from "../assets/minigame/minigame_reels_bg.png"
import minigameBGPortrait from "../assets/minigame/minigame_reels_bg_portrait.png"
import bepBtn from "../assets/minigame/modal/buy_extra_pick_button.png"
import shine  from "../assets/minigame/win_feature_modal_elements/shine.png"
import popup_window  from "../assets/minigame/win_feature_modal_elements/popup_window.png"
import ExtraFreespins1  from "../assets/minigame/features/ExtraFreespins1.png"
import ExtraFreespins2  from "../assets/minigame/features/ExtraFreespins2.png"
import ExtraFreespins3  from "../assets/minigame/features/ExtraFreespins3.png"
import SymbolWildH1  from "../assets/minigame/features/SymbolWildH1.png"
import SymbolWildH2  from "../assets/minigame/features/SymbolWildH2.png"
import SymbolWildH3  from "../assets/minigame/features/SymbolWildH3.png"
import SymbolWildH4  from "../assets/minigame/features/SymbolWildH4.png"
import SymbolWildL1  from "../assets/minigame/features/SymbolWildL1.png"
import SymbolWildL2  from "../assets/minigame/features/SymbolWildL2.png"
import SymbolWildL3  from "../assets/minigame/features/SymbolWildL3.png"
import SymbolWildL4  from "../assets/minigame/features/SymbolWildL4.png"
import SymbolRemoveL1  from "../assets/minigame/features/SymbolRemoveL1.png"
import SymbolRemoveL2  from "../assets/minigame/features/SymbolRemoveL2.png"
import SymbolRemoveL3  from "../assets/minigame/features/SymbolRemoveL3.png"
import SymbolRemoveL4  from "../assets/minigame/features/SymbolRemoveL4.png"
import MultiplierIncrease2  from "../assets/minigame/features/MultiplierIncrease2.png"
import MultiplierIncrease3  from "../assets/minigame/features/MultiplierIncrease3.png"
import MultiplierIncrease5  from "../assets/minigame/features/MultiplierIncrease5.png"
import IncreaseMultipliertrue  from "../assets/minigame/features/IncreaseMultipliertrue.png"
import StickyWildtrue  from "../assets/minigame/features/StickyWildtrue.png"
import {CDN_BASE_URL} from "../../index";

export enum MINIGAME_ASSETS {
    CHEST_CLOSED = 'CHEST_CLOSED',
    CHEST_OPENED = "CHEST_OPENED",
    FB_FRAGMENT = "FB_FRAGMENT",
    FB_MASK = "FB_MASK",
    MINIGAME_BG = "MINIGAME_BG",
    MINIGAME_BG_PORTRAIT = "MINIGAME_BG_PORTRAIT",
    BEP_BTN = "BEP_BTN",
    FEATURE_SHINE = "FEATURE_SHINE",
    FEATURE_POPUP = "FEATURE_POPUP",
    SHINE_ICONS = "SHINE_ICONS",
    POPUP_ICONS = "POPUP_ICONS",
    FEATURES_SHEET = "FEATURES_SHEET",
    SHINE_ANIMATION = "SHINE_ANIMATION",
    EXTRA_FREESPINS_1 = 'EXTRA_FREESPINS_1',
	EXTRA_FREESPINS_2 = 'EXTRA_FREESPINS_2',
	EXTRA_FREESPINS_3 = 'EXTRA_FREESPINS_3',
	SYMBOL_WILD_H1 = 'SYMBOL_WILD_H1',
	SYMBOL_WILD_H2 = 'SYMBOL_WILD_H2',
	SYMBOL_WILD_H3 = 'SYMBOL_WILD_H3',
	SYMBOL_WILD_H4 = 'SYMBOL_WILD_H4',
	SYMBOL_WILD_L1 = 'SYMBOL_WILD_L1',
	SYMBOL_WILD_L2 = 'SYMBOL_WILD_L2',
	SYMBOL_WILD_L3 = 'SYMBOL_WILD_L3',
	SYMBOL_WILD_L4 = 'SYMBOL_WILD_L4',
	SYMBOL_REMOVE_L1 = 'SYMBOL_REMOVE_L1',
	SYMBOL_REMOVE_L2 = 'SYMBOL_REMOVE_L2',
	SYMBOL_REMOVE_L3 = 'SYMBOL_REMOVE_L3',
	SYMBOL_REMOVE_L4 = 'SYMBOL_REMOVE_L4',
	MULTIPLIER_INCREASE_2 = 'MULTIPLIER_INCREASE_2',
	MULTIPLIER_INCREASE_3 = 'MULTIPLIER_INCREASE_3',
	MULTIPLIER_INCREASE_5 = 'MULTIPLIER_INCREASE_5',
	INCREASE_MULTIPLIER_TRUE = 'INCREASE_MULTIPLIER_TRUE',
	STICKY_WILD_TRUE = 'STICKY_WILD_TRUE',
    CHEST_ANIM = "CHEST_ANIM",
    FEATURE_BAR_ANIM = "FEATURE_BAR_ANIM",
}

export const miniGameLoader = (loader: Loader) => {
    loader.add(MINIGAME_ASSETS.CHEST_CLOSED, chestClosed)
    loader.add(MINIGAME_ASSETS.CHEST_OPENED, chestOpened)
    loader.add(MINIGAME_ASSETS.FB_FRAGMENT, fbFragment)
    loader.add(MINIGAME_ASSETS.FB_MASK, fbMask)
    loader.add(MINIGAME_ASSETS.MINIGAME_BG, minigameBG)
    loader.add(MINIGAME_ASSETS.MINIGAME_BG_PORTRAIT, minigameBGPortrait)
    loader.add(MINIGAME_ASSETS.BEP_BTN, bepBtn)
    loader.add(MINIGAME_ASSETS.FEATURE_SHINE, shine)
    loader.add(MINIGAME_ASSETS.FEATURE_POPUP, popup_window)
    loader.add(MINIGAME_ASSETS.POPUP_ICONS, `${CDN_BASE_URL}/minigame/popup.json`)
    loader.add(MINIGAME_ASSETS.SHINE_ICONS, `${CDN_BASE_URL}/minigame/shine_icons.json`)
    loader.add(MINIGAME_ASSETS.FEATURES_SHEET, `${CDN_BASE_URL}/feature_bar/fb_icons.json`)
    loader.add(MINIGAME_ASSETS.SHINE_ANIMATION, `${CDN_BASE_URL}/spine/pop_up_for_features/Pop_Up_for_Features_FIN.json`)
    loader.add(MINIGAME_ASSETS.EXTRA_FREESPINS_1, ExtraFreespins1)
    loader.add(MINIGAME_ASSETS.EXTRA_FREESPINS_2, ExtraFreespins2)
    loader.add(MINIGAME_ASSETS.EXTRA_FREESPINS_3, ExtraFreespins3)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_H1, SymbolWildH1)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_H2, SymbolWildH2)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_H3, SymbolWildH3)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_H4, SymbolWildH4)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_L1, SymbolWildL1)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_L2, SymbolWildL2)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_L3, SymbolWildL3)
    loader.add(MINIGAME_ASSETS.SYMBOL_WILD_L4, SymbolWildL4)
    loader.add(MINIGAME_ASSETS.SYMBOL_REMOVE_L1, SymbolRemoveL1)
    loader.add(MINIGAME_ASSETS.SYMBOL_REMOVE_L2, SymbolRemoveL2)
    loader.add(MINIGAME_ASSETS.SYMBOL_REMOVE_L3, SymbolRemoveL3)
    loader.add(MINIGAME_ASSETS.SYMBOL_REMOVE_L4, SymbolRemoveL4)
    loader.add(MINIGAME_ASSETS.MULTIPLIER_INCREASE_2, MultiplierIncrease2)
    loader.add(MINIGAME_ASSETS.MULTIPLIER_INCREASE_3, MultiplierIncrease3)
    loader.add(MINIGAME_ASSETS.MULTIPLIER_INCREASE_5, MultiplierIncrease5)
    loader.add(MINIGAME_ASSETS.INCREASE_MULTIPLIER_TRUE, IncreaseMultipliertrue)
    loader.add(MINIGAME_ASSETS.STICKY_WILD_TRUE, StickyWildtrue)
    loader.add(MINIGAME_ASSETS.CHEST_ANIM, `${CDN_BASE_URL}/spine/chests_anim/chest_anim.json`)
    loader.add(MINIGAME_ASSETS.FEATURE_BAR_ANIM, `${CDN_BASE_URL}/spine/features_bar/Features_bar_fin.json`)
}

