import * as PIXI from 'pixi.js'
import { Game } from "../../Game";
import { Texture } from 'pixi.js';

export class Multiplier {
    game: Game;
    multiplierBG!: PIXI.Sprite;
    multiplier: PIXI.Container;
    multiplierX!: PIXI.Sprite;
    multiplierSheet: any;
    multiplierTextures: Map<string, Texture>
    values: PIXI.Container

    constructor(game: Game) {
        this.game = game;
        this.multiplier = new PIXI.Container();
        this.multiplierSheet = this.game.app.loader.resources.MULTIPLIER.spritesheet?.textures;
        this.values = new PIXI.Container();
        this.multiplierTextures = new Map()
    }

    init = () => {
        this.setupMultiplierTextures();
        this.setupMultiplier();
        this.setupMultiplierStatic()
        this.game.assetsManager.gameContainer.addChild(this.multiplier);
        this.game.resizeCallbacks.push(this.resize)
    }

    setupMultiplierTextures = () => {
        this.multiplierTextures.set("1", this.multiplierSheet["mult1.png"])
        this.multiplierTextures.set("2", this.multiplierSheet["mult2.png"])
        this.multiplierTextures.set("3", this.multiplierSheet["mult3.png"])
        this.multiplierTextures.set("4", this.multiplierSheet["mult4.png"])
        this.multiplierTextures.set("5", this.multiplierSheet["mult5.png"])
        this.multiplierTextures.set("6", this.multiplierSheet["mult6.png"])
        this.multiplierTextures.set("7", this.multiplierSheet["mult7.png"])
        this.multiplierTextures.set("8", this.multiplierSheet["mult8.png"])
        this.multiplierTextures.set("9", this.multiplierSheet["mult9.png"])
        this.multiplierTextures.set("0", this.multiplierSheet["mult0.png"])
        this.multiplierTextures.set("x", this.multiplierSheet["mult_x.png"])
        this.multiplierTextures.set("frame", this.multiplierSheet["mult_frame.png"])
    }

    setupMultiplierValue = (value: string) => {
        this.values.removeChildren()
        let letterSpacing = -20;
        let xPos = new PIXI.Container();
        xPos.x = 0;
        xPos.y = 0;
        for (let i = 0; i < value.length; i++) {
            let number = new PIXI.Sprite(this.multiplierTextures.get(value[i].toLowerCase()))
            number.position.copyFrom(xPos);
            number.x += xPos.width + letterSpacing;
            this.values.addChild(number)
            xPos = number;
        }
        if (value.length > 1) {
            this.values.scale.set(0.5);
            this.values.x = 100;
            this.values.y = 70;
        } else {
            this.values.scale.set(1);
            this.values.x = 100;
            this.values.y = 33;
        }
       
        this.multiplier.addChild(this.values)
        xPos = new PIXI.Container();
        xPos.destroy();
    }

    setupMultiplier = () => {
        this.multiplier.x = 1680;
        this.multiplier.y = 100;
        this.multiplier.width = 200;
        this.multiplier.height = 200;
        this.multiplier.zIndex = 110;
        this.multiplier.name = 'MULTIPLIER'
        this.multiplier.renderable = false;
    }

    setupMultiplierStatic = () => {
        this.multiplierBG = new PIXI.Sprite(this.multiplierTextures.get("frame"))
        this.multiplierX = new PIXI.Sprite(this.multiplierTextures.get('x'))
        this.multiplierBG.width = 200;
        this.multiplierBG.height = 200;
        this.multiplier.addChild(this.multiplierBG);
        this.multiplier.addChild(this.multiplierX)
    }

    isMultpilerVisible = (value: boolean = false) => {
        return value;
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.multiplier.scale.set(1);
            this.multiplier.y = 0;
            this.multiplier.x = 118;
        } else {
            if (window.innerWidth <= 1080) {
                this.multiplier.scale.set(0.82);
                this.multiplier.y = 1;
                this.multiplier.x = 485;
            } else {
                this.multiplier.visible = true;
                this.multiplier.scale.set(1);
                this.multiplier.x = 1680;
                this.multiplier.y = 100;
            }
        }

    }

}

export default Multiplier;
