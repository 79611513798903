import Feature from "../FeaturesBar/FeaturesBar";
import { Game } from "../../Game";
import { Sprite, Texture, Graphics, Container, Text } from "pixi.js";
import { Timer } from "../../../utils/Timer";
import { MINIGAME_ASSETS } from "../../assetsLoader/miniGameLoader";
import { Spine } from 'pixi-spine';

class WinFeatureModal {
    game: Game;
    overlay: Graphics;
    container: Container;
    modalContainer: Container;
    feature?: Feature;
    featureText: Text;
    shine: Spine;
    popupModal: Sprite;
    popupFeatures: Map<string, Texture>;
    shineFeatures: Map<string, Texture>;
    shineIcons: any;
    popupIcons: any;

    constructor(game: Game) {
        this.game = game;
        this.overlay = new Graphics();
        this.container = new Graphics();
        this.modalContainer = new Container();
        this.featureText = new Text('', {
            fontFamily: 'Norse',
            fontSize: 70,
            fill: 0xFFD700,
            align: 'center'
        })
        const featureData = this.game.app.loader.resources[MINIGAME_ASSETS.SHINE_ANIMATION].spineData!
        this.shine = new Spine(featureData);
        this.popupModal = new Sprite(Texture.from(MINIGAME_ASSETS.FEATURE_POPUP));
        this.popupFeatures = new Map();
        this.shineFeatures = new Map();
        this.shineIcons = this.game.app.loader.resources.SHINE_ICONS.spritesheet?.textures;
        this.popupIcons = this.game.app.loader.resources.POPUP_ICONS.spritesheet?.textures;

    }

    init = async () => {
        this.setupOverlay();
        this.setupContainer();
        this.setupPopupFeatures();
        this.setupShineFeatures();
        this.setupModalContainer();

        this.modalContainer.addChild(this.popupModal);

        this.game.app.stage.addChild(this.overlay);
        this.game.app.stage.addChild(this.container);
        this.container.name = "WIN_FEATURE_MODAL";
        this.game.resizeCallbacks.push(this.resize)
    }

    showFeature = async (name: string, value: number | boolean, index: number, type: 'respin' | 'minigame') => {
        return new Promise(resolve => {
        this.overlay.visible = true;
        this.container.visible = true;
        const popup = new Sprite(this.popupFeatures.get(`${name}${value}`))
        const shine = new Sprite(this.shineFeatures.get(`${name}${value}`))
        this.modalContainer.addChild(this.popupModal)
        this.modalContainer.addChild(popup);
        this.shine.position.set(330, 525);
        this.shine.state.setAnimation(0, "Shine_idle", true);
        this.modalContainer.addChild(shine);

        if(type === 'respin') {
            this.setupFeatureBar(name, value, index);
        } else {
            this.setupChestFeatureBar(name, value);
        }

        const timer = new Timer(() => {
            this.overlay.visible = false;
            this.container.visible = false;
            this.modalContainer.removeChildren();
            this.modalContainer.addChild(this.shine)
            return resolve(true);
        }, 1000)
        timer.initialize()
        })
    }

    setupFeatureBar = (name: string, value: number | boolean, index: number) => {
        this.game.assetsManager.miniGame?.featureBar.setupFeatureBarForRespin(name, value, index);
        this.game.assetsManager.miniGame?.portraitFeatureBar.setupFeatureBarForRespin(name, value, index);
    }

    setupChestFeatureBar = (name: string, value: number | boolean) => {
        this.game.assetsManager.miniGame?.featureBar.insertObtaindedFeature([name, value]);
        this.game.assetsManager.miniGame?.portraitFeatureBar.insertObtaindedFeature([name, value]);
    }

    setupOverlay = () => {
        this.overlay.beginFill(0x151515);
        this.overlay.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.overlay.endFill();
        this.overlay.alpha = 0.8;
        this.overlay.visible = false;
    }

    setupContainer = () => {
        this.container.width = 1920;
        this.container.height = 1080;
        this.container.pivot.set(950, 540)
        this.container.visible = false;
    }

    setupPopupFeatures = () => {
        this.popupFeatures.set("ExtraFreespins1", this.popupIcons['extra_fs.png']);
        this.popupFeatures.set("ExtraFreespins2", this.popupIcons['extra_fs2.png']);
        this.popupFeatures.set("ExtraFreespins3", this.popupIcons['extra_fs3.png']);
        this.popupFeatures.set("SymbolWildH1", this.popupIcons['h1_to_wild.png']);
        this.popupFeatures.set("SymbolWildH2", this.popupIcons['h2_to_wild.png']);
        this.popupFeatures.set("SymbolWildH3", this.popupIcons['h3_to_wild.png']);
        this.popupFeatures.set("SymbolWildH4", this.popupIcons['h4_to_wild.png']);
        this.popupFeatures.set("SymbolWildL1", this.popupIcons['l1_to_wild.png']);
        this.popupFeatures.set("SymbolWildL2", this.popupIcons['l2_to_wild.png']);
        this.popupFeatures.set("SymbolWildL3", this.popupIcons['l3_to_wild.png']);
        this.popupFeatures.set("SymbolWildL4", this.popupIcons['l4_to_wild.png']);
        this.popupFeatures.set("SymbolRemoveL1", this.popupIcons['l1_removed.png']);
        this.popupFeatures.set("SymbolRemoveL2", this.popupIcons['l2_removed.png']);
        this.popupFeatures.set("SymbolRemoveL3", this.popupIcons['l3_removed.png']);
        this.popupFeatures.set("SymbolRemoveL4", this.popupIcons['l4_removed.png']);
        this.popupFeatures.set("MultiplierIncrease2", this.popupIcons['mult_x2.png']);
        this.popupFeatures.set("MultiplierIncrease3", this.popupIcons['mult_x3.png']);
        this.popupFeatures.set("MultiplierIncrease5", this.popupIcons['mult_x5.png']);
        this.popupFeatures.set("IncreaseMultipliertrue", this.popupIcons['progressive_mult.png']);
        this.popupFeatures.set("StickyWildtrue", this.popupIcons['sticky_wild.png']);
    }

    setupShineFeatures = () => {
        this.shineFeatures.set("ExtraFreespins1", this.shineIcons['extra_fs_s.png']);
        this.shineFeatures.set("ExtraFreespins2", this.shineIcons['extra_fs2_s.png']);
        this.shineFeatures.set("ExtraFreespins3", this.shineIcons['extra_fs3_s.png']);
        this.shineFeatures.set("SymbolWildH1", this.shineIcons['h1_to_wild_s.png']);
        this.shineFeatures.set("SymbolWildH2", this.shineIcons['h2_to_wild_s.png']);
        this.shineFeatures.set("SymbolWildH3", this.shineIcons['h3_to_wild_s.png']);
        this.shineFeatures.set("SymbolWildH4", this.shineIcons['h4_to_wild_s.png']);
        this.shineFeatures.set("SymbolWildL1", this.shineIcons['l1_to_wild_s.png']);
        this.shineFeatures.set("SymbolWildL2", this.shineIcons['l2_to_wild_s.png']);
        this.shineFeatures.set("SymbolWildL3", this.shineIcons['l3_to_wild_s.png']);
        this.shineFeatures.set("SymbolWildL4", this.shineIcons['l4_to_wild_s.png']);
        this.shineFeatures.set("SymbolRemoveL1", this.shineIcons['l1_removed_s.png']);
        this.shineFeatures.set("SymbolRemoveL2", this.shineIcons['l2_removed_s.png']);
        this.shineFeatures.set("SymbolRemoveL3", this.shineIcons['l3_removed_s.png']);
        this.shineFeatures.set("SymbolRemoveL4", this.shineIcons['l4_removed_s.png']);
        this.shineFeatures.set("MultiplierIncrease2", this.shineIcons['mult_x2_s.png']);
        this.shineFeatures.set("MultiplierIncrease3", this.shineIcons['mult_x3_s.png']);
        this.shineFeatures.set("MultiplierIncrease5", this.shineIcons['mult_x5_s.png']);
        this.shineFeatures.set("IncreaseMultipliertrue", this.shineIcons['progressive_mult_s.png']);
        this.shineFeatures.set("StickyWildtrue", this.shineIcons['sticky_wild_s.png']);
    }

    setupModalContainer = () => {
        this.modalContainer.width = 687;
        this.modalContainer.height = 752;
        this.container.addChild(this.modalContainer)

        this.modalContainer.addChild(this.shine);
        this.shine.position.set(335, 525);
    }

    resize = () => {
        this.container.width = window.innerWidth;
        this.container.height = window.innerHeight;
        this.overlay.width = window.innerWidth;
        this.overlay.height = window.innerHeight;
        this.container.scale.x = window.innerWidth / 1920;
        this.container.scale.y = window.innerWidth / 1920;
        this.container.x = window.innerWidth / 2;
        this.container.y = window.innerHeight / 1920;


        if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
            this.container.scale.x = window.innerHeight / 1080 - 0.039;
            this.container.scale.y = window.innerHeight / 1080 - 0.039;
        }


        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.modalContainer.scale.set(1.6);
            this.modalContainer.y = 790;
            this.modalContainer.x = 400;
        } else {
            if (window.innerWidth <= 1080) {
                this.modalContainer.scale.set(0.9)
                this.modalContainer.y = 700;
                this.modalContainer.x = 650;
            } else {
                this.modalContainer.scale.set(1)
                this.modalContainer.y = 544;
                this.modalContainer.x = 616.5;
            }

        }

    }
}

export default WinFeatureModal;


