export enum EventType {
  GAME_ASSETS_LOADED = "GAME_ASSETS_LOADED",
  GAME_AUTO_SPIN_COUNT = "GAME_AUTO_SPIN_COUNT",
  OPEN_BUY_EXTRA_FEATURE_MODAL = "OPEN_BUY_EXTRA_FEATURE_MODAL",
  BUY_FEATURE = "BUY_FEATURE",
  OPEN_PAYTABLE_MODAL = "OPEN_PAYTABLE_MODAL",
  AUTH_IS_SUCCESS = "AUTH_IS_SUCCESS",
  ERROR = "ERROR",
  OPEN_TOTAL_BET_MOBILE = "OPEN_TOTAL_BET_MOBILE",
  SET_BET_VALUE = "SET_BET_VALUE",
  ASSETS_PROGRESS = "ASSETS_PROGRESS",
  SPIN_IN_PROGRESS = "SPIN_IN_PROGRESS",
  AUTOSPIN_IN_PROGRESS = "AUTOSPIN_IN_PROGRESS",
  MINI_GAME_IN_PROGRESS = "MINIGAME_IN_PROGRESS",
  CHEST_INTERACTION = "CHEST_INTERACTION",
  OPEN_CHEST = "OPEN_CHEST",
  LEFT_HANDED_MODE_TOGGLE = "LEFT_HANDED_MODE_TOGGLE",
  CHOSEN_BET = "CHOSEN_BET",
  CHOSEN_BET_MOBILE = "CHOSEN_BET_MOBILE",
  SET_BET_LIST = "SET_BET_LIST",
  MINIGAME_ENDED = "MINIGAME_ENDED",
  SET_LOSS_LIMIT = "SET_LOSS_LIMIT",
  SET_SINGLE_WIN = "SET_SINGLE_WIN",
  SET_STOP_ON_ANY_WIN = "SET_STOP_ON_ANY_WIN",
  SET_STOP_ON_FREE_SPIN = "SET_STOP_ON_FREE_SPIN", 
  CHANGE_BALANCE = "CHANGE_BALANCE",
  CHANGE_WIN = "CHANGE_WIN",
  IS_DEBUG = "IS_DEBUG",
  RESPIN_MINIGAME_ENDED = "RESPIN_MINIGAME_ENDED",
  ANIMATION_SPEED = "ANIMATION_SPEED",
  SHOW_AUTO_PLAY_MODAL = "SHOW_AUTO_PLAY_MODAL",
  AUDIO_TOGGLE = "AUDIO_TOGGLE",
  SHOW_LOADING_SCREEN = "SHOW_LOADING_SCREEN",
  SET_CHEST_ANIMATION = "SET_CHEST_ANIMATION",
  PAUSE_ON_WIN_MODAL = "PAUSE_ON_WIN_MODAL",
  PRELOADER_ASSETS_PROGRESS = "PRELOADER_ASSETS_PROGRESS",
  ENABLE_TURBO_MODE = "ENABLE_TURBO_MODE",
  DISABLE_TURBO_BTN = "DISABLE_TURBO_BTN",
  SLOW_WIN_ANIMATION_OVER = "SLOW_WIN_ANIMATION_OVER",
  INIT_DATA = "INIT_DATA", 
}
