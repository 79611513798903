import {Loader} from "pixi.js";
import GameSheetImg from '../assets/sheet.png'
import GameSheetBGImg from '../assets/sheetBG.png'
import GameSheetPortrait from '../assets/portraitSheet.png'
import GameSheetBGPortrait from '../assets/portraitSheetBG.png'
import SheetMask from '../assets/sheetMask.png'
import InfoBg from '../assets/info-bar/infoBG.svg'
import logoPortrait from '../assets/logoPortrait.png'
import logoDesktop from '../assets/logo_horisontal.png'
import ReelContainerBlur from '../assets/reel_container_blur_desktop.png'
import {CDN_BASE_URL} from "../../index";

export enum UI_ASSETS {
    GAME_FIELD = 'GAME_FIELD',
    GAME_FIELD_BG = 'GAME_FIELD_BG',
    GAME_FIELD_BG_PORTRAIT = 'GAME_FIELD_BG_PORTRAIT',
    GAME_FIELD_PORTRAIT = 'GAME_FIELD_PORTRAIT',
    SHEET_MASK = "SHEET_MASK",
    INFO_BACKGROUND = "INFO_BACKGROUND",
    GAME_BACKGROUND = "GAME_BACKGROUND",
    LOGO_PORTRAIT = "LOGO_PORTRAIT",
    LOGO_DESKTOP = "LOGO_DESKTOP",
    FS_BACKGROUND = "FS_BACKGROUND",
    GAME_FIELD_BLUR = 'GAME_FIELD_BLUR',
}

export const loadUiAssets = (loader: Loader) => {
    loader.add(UI_ASSETS.GAME_FIELD,  GameSheetImg)
    loader.add(UI_ASSETS.GAME_FIELD_BG,  GameSheetBGImg)
    loader.add(UI_ASSETS.GAME_FIELD_PORTRAIT, GameSheetPortrait)
    loader.add(UI_ASSETS.GAME_FIELD_BG_PORTRAIT, GameSheetBGPortrait)
    loader.add(UI_ASSETS.SHEET_MASK, SheetMask)
    loader.add(UI_ASSETS.INFO_BACKGROUND, InfoBg)
    loader.add(UI_ASSETS.GAME_BACKGROUND, `${CDN_BASE_URL}/spine/bg/Base_Game_BG_1920x1920.json`)
    loader.add(UI_ASSETS.LOGO_PORTRAIT, logoPortrait)
    loader.add(UI_ASSETS.LOGO_DESKTOP, logoDesktop)
    loader.add(UI_ASSETS.FS_BACKGROUND, `${CDN_BASE_URL}/spine/fs_bg/Base_Game_BG_1920x1920.json`)
    loader.add(UI_ASSETS.GAME_FIELD_BLUR,  ReelContainerBlur)
}
