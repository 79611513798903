import { Game } from "../Game";
import { Reel } from "./Reel";
import { TEST_SYMBOLS } from "../constants/Symbols";
import * as PIXI from 'pixi.js'
import { ApiResponse, PlayResponse, SymbolName, WhoAmI } from "../../api/types";
import { api } from "../../App";
import { LocalStorage } from "../../utils/localStorage";
import { EventType, getEvent } from "../../GameEventBus";
import { SlotEvents } from "./slotEvents";
import { Sounds } from './SoundController';
import { SoundNames } from '../constants/sounds';
import { Timer } from '../../utils/Timer';
import { SlowWin } from "./slowWin";
import { UI } from "../../ui";

interface SpinParams {
    params?: {
        anyWin: boolean
        freeSpins: boolean
        singleWin: null | number
        lossLimit: null | number
        refreshLoss: boolean
        startSpinCount?: number | string
    }
}

export class slotMachine {
    game: Game
    reels: Reel[]
    isSpinning: boolean
    reelsContainer: PIXI.Container
    balance: number
    bet: number
    symbols: SymbolName[][]
    spinData?: ApiResponse<PlayResponse>
    currentSpeed: number
    nextCommand: string
    slotEvents?: SlotEvents
    isFsRunning: boolean
    freeSpinCount?: number
    betList: number[]
    chest4Cost!: number[]
    chest5Cost!: number[]
    betIndex: number;
    requestParams?: SpinParams['params']
    winDescription: string | undefined
    isRespinRunning: boolean
    isLoading: boolean
    autoSpinCount?: number
    currentCommand: string
    onStart: boolean
    isAutoPlayStopped?: boolean;
    maxWinCap!: number;
    currentRTP!: number;
    fourthFeatureRTP!: number;
    fifthFeatureRTP!: number;
    isBuyBonus: boolean;

    constructor(game: Game) {
        this.game = game
        this.reels = []
        this.isSpinning = false
        this.reelsContainer = new PIXI.Container()
        this.reelsContainer.sortableChildren = true
        this.balance = 0;
        this.betIndex = LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet') : 0;
        this.symbols = []
        this.currentSpeed = 1
        this.nextCommand = ""
        this.betList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        this.chest4Cost = [];
        this.chest5Cost = [];
        this.bet = this.betList[this.betIndex]
        this.slotEvents = new SlotEvents(this)
        this.isFsRunning = false
        this.winDescription = undefined;
        this.isRespinRunning = false;
        this.isLoading = true;
        this.currentCommand = ""
        this.onStart = false
        this.maxWinCap = 20000;
        this.currentRTP = 96;
        this.fourthFeatureRTP = 96;
        this.fifthFeatureRTP = 96;
        this.isBuyBonus = false;
    }

    init = async () => {
        if (!this.game.assetsManager.gameField) return
        this.reelsContainer.width = this.game.assetsManager.gameField.width
        this.reelsContainer.height = this.game.assetsManager.gameField.height
        this.reelsContainer.name = 'REELS_CONTAINER'
        this.game.assetsManager.gameField.container?.addChild(this.reelsContainer)
        const slowWin = this.game.assetsManager.slowWin?.reelsContainer as PIXI.Container;
        this.reelsContainer.addChild(slowWin)
        await this.game.assetsManager.gameField.setupMask()

        for (let i = 0; i < TEST_SYMBOLS.length; i++) {
            const symbols = TEST_SYMBOLS[i]
            const reel = new Reel(this.game, i, this.reelsContainer)
            await reel.init()
            await reel.generateReels(symbols)
            await reel.placeSymbols(i)
            this.reels?.push(reel)
        }

        getEvent<boolean>(EventType.SHOW_LOADING_SCREEN).subscribe(value => {
            this.isLoading = value;
        })

        document.addEventListener('keydown', (e) => {
            if (this.isFsRunning) return;
            if (this.isRespinRunning) return;
            if (this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) return;
            if (this.isLoading) return;
            if (e.code === 'Space') {
                if(this.game.assetsManager.burgerMenuBtn?.isMenuOpen) return;
                if(this.game.isDebug) {
                    const data = localStorage.getItem('debugReels');
                    const debugReels = JSON.parse(data!);
                    const command = debugReels.command ? debugReels.command : 'debug';
                    this.nextCommand = command;
                    this.onSpin()
                } else {
                    this.onSpin()
                }
            }
        })

        this.setBetList(this.betList, this.betIndex);

        getEvent<any>(EventType.GAME_AUTO_SPIN_COUNT).subscribe(value => {
            this.autoSpinCount = value;
        })
    }


    onSpin = async (command = "") => {
        if(this.bet > this.balance && !this.game.assetsManager.buyBonusModal?.isBuyBonus) {
            let errorArray = ["Not enough funds", "Please refill your balance", '']
            getEvent(EventType.ERROR).send(errorArray);
            return
        }
        if (this.isSpinning) return
        if (this.isRespinRunning) return
        if (this.game.assetsManager.autoPlayModal!.isModalOpen) return
        if (this.game.assetsManager.fss?.isModalActive) return
        if (this.game.assetsManager.autoPlayBtn?.buttonLabel.text === "OFF") {
            this.game.assetsManager.autoPlayBtn!.setButtonState(false)
            UI.events.emit('auto:stop')
        }
        getEvent<boolean>(EventType.SLOW_WIN_ANIMATION_OVER).send(true);
        const slowWin = this.game.assetsManager.slowWin as SlowWin;
        slowWin.reset();
        this.onStart = false;
        this.isSpinning = true
        this.game.assetsManager.gameField?.removeTempWilds(false);
        UI.events.emit('spin:start')
        this.currentCommand = this.nextCommand
        this.slotEvents?.changeWin(0)
        Sounds.play(SoundNames.BTN_SPIN);
        getEvent(EventType.SPIN_IN_PROGRESS).send(this.isSpinning);

        if (!['minigame', 'buybonusspin', 'buyextrafeature', 'respinminigame', 'respin', 'freegame'].includes(this.nextCommand)) {
            this.changeBalance(-this.bet)
            this.onStart = true;
            this.reels.forEach(async (reel) => {
                await reel.onStartAnimate(true)
            })
        }

        try {
            if (this.game.isDebug) {
                const data = localStorage.getItem('debugReels');
                const debugReels = JSON.parse(data!);
                this.nextCommand = this.nextCommand === '' ? 'debug' : this.nextCommand
                this.nextCommand = (this.nextCommand === 'freegame' && debugReels.maxWinCap) ? 'freegamecap' : this.nextCommand
                const request = {
					bet: this.bet,
					command: this.nextCommand,
					limitReq: this.requestParams,
					testReels: {
						screen: JSON.parse(debugReels.positions),
					},
					feature: JSON.parse(debugReels.feature),
				};
				this.spinData = await api.auth.playDebug(request);
            } else {
                this.spinData = await api.auth.play(this.bet, this.nextCommand, this.requestParams)
            }
        } catch (e: any) {
            this.nextCommand = e.nextCommands[0]
            this.isSpinning = false
            UI.events.emit('spin:stop')
            await this.onSpin()
            return
        }

        if (!this.spinData?.results) {
            return
        }
        if (this.spinData.results[0].clientData.buyExtraFeatureCost) this.game.assetsManager.miniGame?.setFeaturePrice(this.spinData.results[0].clientData.buyExtraFeatureCost)
        this.isAutoPlayStopped = this.spinData.results[0].isAutoPlayStopped;
        if (['respinminigame'].includes(this.nextCommand)) {
            if (!this.spinData.results[0].clientData.featuresDetails) return;
            if (this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) this.game.assetsManager.autoPlayModal!.isPaused = true;

            this.isRespinRunning = true;
            this.nextCommand = this.spinData.nextCommands[0];

            await this.slotEvents?.startRespinMinigame(this.spinData.results[0].clientData.featuresDetails);
            this.isFsRunning = false;
            this.isSpinning = false;
            UI.events.emit('spin:stop')
            return;
        }

        if (['minigame'].includes(this.nextCommand)) {
            await this.minigamePreparing(this.spinData);
            return
        }

        this.nextCommand = this.spinData.nextCommands[0]
        // this.nextCommand = ""
        this.symbols = this.spinData?.results[0]?.clientData.reels.board
        const resultsLength = this.spinData.results.length
        if (!this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) {
            getEvent(EventType.SPIN_IN_PROGRESS).send(this.isSpinning)
        }

        this.getCurrentSpeed()
        let freeSpinWin = 0
        for (let i = 0; i < resultsLength; i++) {
            if (this.freeSpinCount) {
                this.freeSpinCount--
                this.game.assetsManager?.playBtn?.setSpinCounter(this.freeSpinCount);
                UI.events.emit('free-spin:start', {amount: this.freeSpinCount})
                this.winDescription = this.spinData.results[i].winDescription
                this.game.assetsManager.fss?.setFreeSpinsCount(this.freeSpinCount);
            }

            const results = this.spinData.results[i]
            this.game.assetsManager.multiplier?.setupMultiplierValue(String(results.clientData.multiplier))
            this.symbols = results.clientData.reels.board;

            const antisipation = this.game.assetsManager.antisipation!;
            antisipation.reset();
            if (!this.isFsRunning && !this.isBuyBonus) {
                antisipation.checkAntisipation(results.clientData.reels.board);
                if (!this.onStart) await this.beforeAntisipation();
            }

            await this.spinAnimation(results);

            await this.slotEvents?.buildWinLine(results, results.clientData.reels.eventsLeft, this.spinData.results[i].winDescription)
            await this.slotEvents?.buildSpecialEvents(results.clientData.reels.eventsLeft)
            if (this.spinData.results[i].cashWin !== 0 && !this.winDescription) {
                await slowWin.prepareWinAmounts(this.spinData, i);
                if (!['minigame'].includes(this.nextCommand)) await slowWin.play(true);
            }
            freeSpinWin = results.sumOfFreeWin ? results.sumOfFreeWin : 0;
            if (!this.isFsRunning) this.changeBalance(results.cashWin)

            if (this.winDescription) {
                await this.slotEvents?.openWinModal(this.winDescription, results.cashWin);
                this.winDescription = undefined;
            }

            const sumOfFreeWin = freeSpinWin < this.maxWinCap ? results.sumOfFreeWin : this.maxWinCap;
            if (this.isFsRunning) this.slotEvents?.changeWin(sumOfFreeWin)
            this.isAutoPlayStopped = results.isAutoPlayStopped;

            if(freeSpinWin > this.maxWinCap) {
                this.game.assetsManager.maxWinModal?.openModal();
                this.freeSpinCount = 0;
                return;
            }
        }

        if (this.spinData.results[0].winDescription && !this.isFsRunning) {
            await this.slotEvents?.openWinModal(this.spinData.results[0].winDescription, this.spinData.results[0].cashWin);
        }

        if (this.isFsRunning) {
            this.game.assetsManager?.fss?.showModal(true, Number(freeSpinWin.toFixed(2)))
        }

        this.stopFs()

        if (command === 'buybonusspin') {
            this.isSpinning = false
            UI.events.emit('spin:stop')
            this.nextCommand = 'minigame'
            await this.onSpin()
        }

        this.isSpinning = false
        UI.events.emit('spin:stop')
        if (['minigame', 'respin', 'respinminigame'].includes(this.nextCommand)) {
            await this.onSpin()
        }

        getEvent(EventType.SPIN_IN_PROGRESS).send(this.isSpinning)
        if(this.game.assetsManager.buyBonusModal) this.game.assetsManager.buyBonusModal.isBuyBonus = false;
        if (this.currentCommand !== 'freegame' && !this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) await slowWin.play(false);
        await this.checkIsBuyBonus(false);
        return this.isAutoPlayStopped;
    }

    beforeAntisipation = async () => {
        const iDs = this.game.assetsManager.antisipation!.antisipationIndex;
        this.reels.forEach(async (reel) => {
            if (iDs.includes(reel.id)) {
                await reel.onStartAnimate(true)
            }
        });
    }

    spinAnimation = async (results: PlayResponse) => {
        if (!this.reels?.length) throw 'REELS IS EMPTY'
        const start = performance.now()
        const antisipation = this.game.assetsManager.antisipation!;
        const isBonusCmd = ['minigame', 'buybonusspin', 'buyextrafeature', 'respinminigame', 'respin', 'freegame'].includes(this.currentCommand)
        const reelRemap = async (reel: Reel, idx: number) => {
            if (idx === 0) this.game.assetsManager.playBtn?.changeButtonMode('stop');
            const newSymbols = this.symbols[reel.id]
            const isScatter = newSymbols.includes("SCATTER");
            await reel.replaceSymbols(newSymbols)
            if (!antisipation.antisipationIndex.includes(reel.id)) {
                await reel.onStartAnimate(false, this.isFsRunning || isBonusCmd)
                await reel.replaceSymbols(newSymbols)
                await reel.replaceSecondSymbols(newSymbols);
                await reel.onEndAnimate();
            } else {
                const start = antisipation.antisipationIndex[0] === reel.id;
                await reel.onStartAnimate(false, true, true, start, isScatter);
                await reel.replaceSymbols(newSymbols)
                await reel.replaceSecondSymbols(newSymbols);
                await reel.onEndAnimate();
                if (isScatter && reel.id === 4) {
                    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
                    await sleep(600);
                }
            }
        }
        const reelRemapForWild = async (reel: Reel) => {
            if (results.clientData.reels.symbolEvents.length === 0) return
            await reel.symbolToWild(this.symbols[reel.id], results.clientData.reels.symbolEvents, true)
        }
        if (!!results.clientData.reels.eventsLeft) {
            const scatterWin = results.clientData.reels.eventsLeft.filter((event) => event.type === 'ScatterWin')
            if (scatterWin.length > 0) {
                this.playScatterSounds();
            }
        }
        await Promise.all(this.reels.map(reelRemap))
        await Promise.all(this.reels.map(reelRemapForWild))
        return true
    }

    changeBalance = (changeValue: number = 0, value = this.balance) => {
        this.balance = value + changeValue;
        UI.events.emit('balance:update', {
            value: this.balance,
        })
        this.game.assetsManager.infoBar?.balanceBar.changeValue(this.balance);
        // this.game.assetsManager.footerForMobileHorizontal?.changeBalance(this.balance);
        // this.game.assetsManager.footerForMobileVertical?.changeBalance(this.balance);
        getEvent(EventType.CHANGE_BALANCE).send(this.balance);
    }

    setBetList = (list: number[], index: number) => {
        // this.game.assetsManager.footerForMobileHorizontal?.setBetList(list);
        // this.game.assetsManager.footerForMobileVertical?.setBetList(list);
        this.game.assetsManager.infoBar?.betBar.setBetList(list);
        getEvent(EventType.SET_BET_LIST).send(list);
        getEvent(EventType.CHOSEN_BET).send(index);
    }

    getCurrentSpeed = (speedUp: boolean = false) => {
        const isTurboMode = LocalStorage.getItem('WrapperTurboSettings')
        if (isTurboMode && !this.isFsRunning) {
            this.currentSpeed = 0.5;
        } else {
            this.currentSpeed = 1;
        }

        if (speedUp && !this.isFsRunning) {
            this.currentSpeed = 0.5
            getEvent(EventType.ANIMATION_SPEED).send(this.currentSpeed)
        }
    }

    minigamePreparing = async (spinData: any) => {
        this.game.assetsManager.miniGame?.setupMiniGameHeader();
        this.game.assetsManager.gameField?.removeTempScatters('TEMP_SCATTER');
        if (this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) this.game.assetsManager.autoPlayModal!.isPaused = true;
        getEvent(EventType.RESPIN_MINIGAME_ENDED).send(false);
        this.freeSpinCount = spinData.results[0].clientData.freeSpin.added
        let extraFreeSpins = 0
        spinData.results[0].clientData.featuresDetails.forEach((feature: any) => {
            if (feature.name === 'ExtraFreespins') extraFreeSpins += feature.value
        })
        this.isFsRunning = true
        this.isSpinning = false
        getEvent(EventType.DISABLE_TURBO_BTN).send(this.isFsRunning)
        getEvent(EventType.SPIN_IN_PROGRESS).send(true)
        this.nextCommand = spinData.nextCommands[0]
        if (this.game.assetsManager.gameField?.orientation === 'desktop') {
            this.game.assetsManager.gameField!.minigameReelsDesktop.play()
            this.game.assetsManager.gameField!.minigameReelsDesktop.visible = true;
        } else {
            this.game.assetsManager.gameField!.minigameReelsPortrait.play()
            this.game.assetsManager.gameField!.minigameReelsPortrait.visible = true;
        }
        this.game.assetsManager.gameField!.isFlameActive = true;
        this.game.assetsManager.multiplier!.multiplier.renderable = true;
        this.game.assetsManager.gameBG!.freeGameBG!.renderable = true;
        this.game.assetsManager.miniGame?.replaceFeatures(spinData.results[0].clientData.featuresDetails)
        this.game.assetsManager.autoPlayBtn!.button.renderable = false;
        this.slotEvents?.startMiniGame(spinData.results[0].clientData.featuresDetails, extraFreeSpins)
    }

    stopFs = () => {
        this.game.assetsManager.gameField?.removeTempWilds(true);
        this.slotEvents!.isStickyWild = false;
        this.game.assetsManager.autoPlayBtn!.button.renderable = true;
        this.game.assetsManager.autoPlayBtn!.changeFSMode(false)
        this.game.assetsManager.playBtn!.changeButtonMode('default')
        this.isFsRunning = false
        getEvent(EventType.DISABLE_TURBO_BTN).send(this.isFsRunning)
        this.game.assetsManager.buyBonusBtn!.btn.visible = true;
        this.game.assetsManager.miniGame?.featureBar.clearFeatures();
        this.game.assetsManager.miniGame?.portraitFeatureBar.clearFeatures();
        this.game.assetsManager.multiplier!.multiplier.renderable = false;
        Sounds.changeToFsMode(false);
        this.game.assetsManager.gameBG!.freeGameBG!.renderable = false;
        if (this.game.assetsManager.autoPlayModal?.isAutoPlayRunning &&
            this.game.assetsManager.autoPlayModal!.isPaused &&
            !this.game.assetsManager.autoPlayModal!.isModalOpen) {
            this.game.assetsManager.autoPlayModal.isPaused = false;
            if (!this.game.assetsManager.fss?.isModalActive) {
                this.game.assetsManager.autoPlayModal
                    .setCounter(this.game.assetsManager.autoPlayModal.pausedCounter)
            }
        }
        if (this.isAutoPlayStopped) {
            this.game.assetsManager.autoPlayModal?.setCounter(0)
            UI.events.emit('auto:stop')
        }

        this.game.assetsManager.gameField!.minigameReelsDesktop.visible = false;
        this.game.assetsManager.gameField!.minigameReelsPortrait.visible = false;
        this.game.assetsManager.gameField!.isFlameActive = false;
        this.game.assetsManager.gameField!.minigameReelsDesktop.stop();
        this.game.assetsManager.gameField!.minigameReelsPortrait.stop();
        // const isTurboMode = LocalStorage.getItem('isTurboEnabled')
        // if (!isTurboMode) this.currentSpeed = 1;
    }

    // setAutoPlayParams = (params: any) => {
    //     this.requestParams = params;
    // }

    changeCommand = (command: string) => {
        this.nextCommand = command;
    }

    setInitData = (init: WhoAmI) => {
        this.betList = init.betsArray;
        this.chest4Cost = init.chest4Cost;
        this.chest5Cost = init.chest5Cost;
        this.currentRTP = init.RTP;
        this.fourthFeatureRTP = init.chest4RTP;
        this.fifthFeatureRTP = init.chest5RTP;
        this.maxWinCap = init.maxWinCap;
        UI.config.setBetSteps(init.betsArray, init.betsArray[0]);
    }

    playScatterSounds = () => {
        const soundTimeout = 500 * this.currentSpeed;
        const scatterSounds = [
            SoundNames.SCATTER_LAND_0,
            SoundNames.SCATTER_LAND_1,
        ]
        for (let index = 0; index < 2; index++) {
            const timer = new Timer(() => {
                Sounds.play(scatterSounds[index])
            }, soundTimeout + 250 * index)
            timer.initialize();
        }
    }

    checkIsBuyBonus = async (state: boolean) => {
        this.isBuyBonus = state;
    }

    showReels = (state: boolean) => {
        this.reelsContainer.children.forEach(item => {
            if (item.name === `REEL`) {
                item.visible = state;
            }
        });
    }
}