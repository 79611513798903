export const respinStartCoords = [
	[
		{ x: -80, y: -210 },
		{ x: -80, y: 0 },
		{ x: -80, y: 210 },
		{ x: -80, y: 420 },
	],
	[
		{ x: 145, y: -210 },
		{ x: 145, y: 0 },
		{ x: 145, y: 210 },
		{ x: 145, y: 420 },
	],
	[
		{ x: 370, y: -210 },
		{ x: 370, y: 0 },
		{ x: 370, y: 210 },
		{ x: 370, y: 420 },
	],
	[
		{ x: 595, y: -210 },
		{ x: 595, y: 0 },
		{ x: 595, y: 210 },
		{ x: 595, y: 420 },
	],
	[
		{ x: 820, y: -210 },
		{ x: 820, y: 0 },
		{ x: 820, y: 210 },
		{ x: 820, y: 420 },
	],
];
