import * as PIXI from 'pixi.js';
import { Game } from '../Game';
import FeaturesBar from '../components/FeaturesBar/FeaturesBar';
import { Chest } from './Chest';
import PlayBtn from '../components/PlayBtn/PlayBtn';
import BuyAnExtraFeatureBtn from '../components/BuyAnExtraFeatureBtn/BuyAnExtraFeatureBtn';
import { GameField } from '../components/gameField/GameField';
import AutoPlayButton from '../components/AutoPlayButton/AutoPlayButton';
import { InfoBar } from './InfoBar';
import BuyBonusButton from '../components/BuyBonusButton/BuyBonusButton';
import Multiplier from '../components/multiplier/Multiplier';
import BetBtn from '../components/BetButton/BetButton';
import BurgerMenuBtn from '../components/BurgerMenuBtn/BurgerMenuBtn';
import { EventType, getEvent } from '../../GameEventBus';
import PortraitFeaturesBar from '../components/PortraitFeatureBar/PortraitFeatureBar';
import { FeatureDetailed } from "../../api/types";
import { Texture } from 'pixi.js';
import { MINIGAME_ASSETS } from '../assetsLoader/miniGameLoader';
import LettersHelper from '../components/LettersHelper/LettersHelper';
import { Timer } from '../../utils/Timer';
import ChestFeatureAnimation from '../components/ChestFeatureAnimation/ChestFeatureAnimation';
import {Sounds} from "./SoundController";


const chestPosArray = [
    { x: 170, y: 320 }, { x: 400, y: 245 }, { x: 720, y: 220 }, { x: 1020, y: 220 },
    { x: 560, y: 370 }, { x: 240, y: 550 }, { x: 500, y: 580 },
    { x: 760, y: 500 }, { x: 1000, y: 430 }, { x: 920, y: 670 }
]

class MiniGame {
    game: Game;
    gameField: GameField;
    playBtn: PlayBtn;
    autoPlayBtn: AutoPlayButton;
    infoBar: InfoBar;
    miniGameContainer: PIXI.Container;
    chests: Chest[];
    featureBar: FeaturesBar;
    baefBtn: BuyAnExtraFeatureBtn;
    buyBonusBtn: BuyBonusButton;
    multiplier: Multiplier;
    betBtn: BetBtn;
    burgerMenuBtn: BurgerMenuBtn;
    portraitFeatureBar: PortraitFeaturesBar;
    features: FeatureDetailed[];
    receivedFeatures: FeatureDetailed[];
    minigameBG: PIXI.Sprite;
    minigameBGPortrait: PIXI.Sprite;
    chestContainer: PIXI.Container;
    lettersHelper: LettersHelper;
    featureAnimContainer: ChestFeatureAnimation;
    featurePrice!: number;

    constructor(
        game: Game,
        gameField: GameField,
        playBtn: PlayBtn,
        autoPlayBtn: AutoPlayButton,
        infoBar: InfoBar,
        multiplier: Multiplier,
        burgerMenuBtn: BurgerMenuBtn,
        betBtn: BetBtn,
        buyBonusBtn: BuyBonusButton,
    ) {
        this.game = game;
        this.gameField = gameField;
        this.playBtn = playBtn;
        this.autoPlayBtn = autoPlayBtn;
        this.infoBar = infoBar;
        this.miniGameContainer = new PIXI.Container();
        this.chests = [];
        this.featureBar = new FeaturesBar(this.game);
        this.portraitFeatureBar = new PortraitFeaturesBar(this.game);
        this.baefBtn = new BuyAnExtraFeatureBtn(this.game, this.featureBar, this.portraitFeatureBar);
        this.buyBonusBtn = buyBonusBtn;
        this.multiplier = multiplier;
        this.betBtn = betBtn;
        this.burgerMenuBtn = burgerMenuBtn;
        this.features = [];
        this.receivedFeatures = [];
        this.minigameBG = new PIXI.Sprite(Texture.from(MINIGAME_ASSETS.MINIGAME_BG));
        this.minigameBGPortrait = new PIXI.Sprite(Texture.from(MINIGAME_ASSETS.MINIGAME_BG_PORTRAIT));
        this.chestContainer = new PIXI.Container();
        this.lettersHelper = new LettersHelper(this.game.app.loader.resources.LETTERS_SHEET_DATA.spritesheet?.textures, "MINIGAME_HEADER");
        this.featureAnimContainer = new ChestFeatureAnimation(this.game);
    }

    init = async () => {
        this.setupMiniGameContainer();
        this.setupMiniGameBG();
        this.setupChestContainer();
        this.chestListener();
        this.setupBuyAnExtraFeatureBtn();
        this.setupFeatureBar();
        this.checkFeatureBar();


        this.game.assetsManager.gameContainer.addChild(this.miniGameContainer);
        this.game.resizeCallbacks.push(this.resize)

        getEvent<boolean>(EventType.CHEST_INTERACTION).subscribe(value => {
            this.chestListener();
            const timer = new Timer(() => {
                this.modalChestListener();
            }, 2000)
            timer.initialize()
        })

        getEvent<boolean>(EventType.OPEN_CHEST).subscribe(value => {
            this.lettersHelper.visible = false;
            this.baefBtn.setBtnVisible(false);
            this.miniGameEndTrigger();
        })

        getEvent<boolean>(EventType.MINIGAME_ENDED).subscribe(value => {
            const timer = new Timer(() => {
                if (value) {
                    this.endMiniGame();
                }
            }, 1500);
            timer.initialize();
        });
    }

    setFeaturePrice = async (price: number) => {
        this.featurePrice = price;
    }

    setupMiniGameContainer = () => {
        this.miniGameContainer.width = 1377;
        this.miniGameContainer.height = 1021;
        this.miniGameContainer.visible = false;
        this.miniGameContainer.zIndex = 10;
        this.miniGameContainer.name = 'MINI_GAME';
        this.miniGameContainer.sortableChildren = true;
    }

    setupMiniGameBG = () => {
        this.minigameBG.visible = false;
        this.minigameBG.name = "MINIGAME_BG"
        this.minigameBGPortrait.visible = false;
        this.minigameBGPortrait.scale.set(1.25)
        this.minigameBGPortrait.name = "MINIGAME_BG_PORTRAIT"
        this.miniGameContainer.addChild(this.minigameBG);
        this.miniGameContainer.addChild(this.minigameBGPortrait)
        this.featureAnimContainer.container.zIndex = 10000;
        this.miniGameContainer.addChild(this.featureAnimContainer.container)
    }

    setupMiniGameHeader = () => {
        this.clearText();
        let currency = "usd"
        this.lettersHelper.makeSentense(`buy an extra pick for ${this.featurePrice} ${currency} or open chest`, -7);
        this.lettersHelper.y = 115;
        this.lettersHelper.x = this.minigameBG.width / 2 - this.lettersHelper.width / 2;
        this.lettersHelper.name = "BUY_EXTRA_FEATURE_TEXT";
        this.miniGameContainer.addChild(this.lettersHelper);
    }

    setupChestContainer = () => {
        this.chestContainer.width = 1152;
        this.chestContainer.height = 925;
        this.chestContainer.name = "CHEST_CONTAINER";
    }

    setupChests = () => {
        for (let i = 0; i < 10; i++) {
            const chest = new Chest(this, this.game, i);
            chest.init();
            chest.featureName = `Feature ${i}`
            chest.container.x = chestPosArray[i].x;
            chest.container.y = chestPosArray[i].y;
            this.chests.push(chest);
            this.chestContainer.addChild(chest.container);
        }
        this.miniGameContainer.addChild(this.chestContainer);
    }

    restoreChests = () => {
        this.chests.splice(0, this.chests.length);
        this.chestContainer.removeChildren();
    }

    setChestsInteractive = (state: boolean) => {
        this.chestContainer.interactiveChildren = state;
    }

    setupFeatureBar = () => {
        this.featureBar.init();
        this.portraitFeatureBar.init();
    }

    checkFeatureBar = () => {
        if (this.featureBar.hollowFeaturesContainer.children.length === 5) {
            this.lettersHelper.visible = false;
            this.baefBtn.setBtnVisible(false);
        }
    }

    setupBuyAnExtraFeatureBtn = () => {
        this.baefBtn.init();
        this.baefBtn.setBtnVisible(true);
        this.miniGameContainer.addChild(this.baefBtn.btn);
    }

    toggleMiniGame = async (status: boolean) => {
        if (status) {
            this.setupMiniGameIsAvaliable()
        }
        else await this.setupMiniGameIsNotAvaliable()
        getEvent(EventType.MINI_GAME_IN_PROGRESS).send(status)
    }

    setupMiniGameIsAvaliable = () => {
        Sounds.changeToFsMode(true);
        this.gameField.container.visible = false;
        this.miniGameContainer.visible = true;

        this.lettersHelper.visible = true;
        this.setupChests();

        this.playBtn.button.renderable = false;


        this.autoPlayBtn.button.interactive = false;
        this.autoPlayBtn.button.alpha = 0;

        this.infoBar.betBar.minusButton.interactive = false;
        this.infoBar.betBar.minusButton.alpha = 0.5;

        this.infoBar.betBar.plusButton.interactive = false;
        this.infoBar.betBar.plusButton.alpha = 0.5;

        this.baefBtn.setBtnVisible(true);

        this.buyBonusBtn.btn.visible = false;

        this.multiplier.multiplier.renderable = false;

        this.betBtn.btn.interactive = false;
        this.betBtn.btn.alpha = 0;

        if (this.receivedFeatures.length === 5) {
            this.baefBtn.setBtnVisible(false);
            this.lettersHelper.visible = false;
        }

        this.resize();
        this.featureBar.addFeatures(this.receivedFeatures.length);

        this.portraitFeatureBar.addFeatures(this.receivedFeatures.length);

        // this.game.assetsManager.footerForMobileHorizontal!.footer.alpha = 0;
        // this.game.assetsManager.footerForMobileVertical!.footer.alpha = 0;

        this.animateRandomChest();
    }

    setupMiniGameIsNotAvaliable = async () => {
        this.gameField.container.visible = true;
        this.baefBtn.setBtnVisible(false);

        this.playBtn.button.interactive = true;
        this.playBtn.button.alpha = 1;

        this.autoPlayBtn.button.interactive = true;
        this.autoPlayBtn.button.alpha = 1;

        this.infoBar.betBar.minusButton.interactive = true;
        this.infoBar.betBar.minusButton.alpha = 1;

        this.infoBar.betBar.plusButton.interactive = true;
        this.infoBar.betBar.plusButton.alpha = 1;
        this.playBtn.button.renderable = true;


        this.multiplier.multiplier.renderable = true;

        this.betBtn.btn.interactive = true;
        this.betBtn.btn.alpha = 1;

        this.restoreChests();

        // this.game.assetsManager.footerForMobileHorizontal!.footer.alpha = 1;
        // this.game.assetsManager.footerForMobileVertical!.footer.alpha = 1;

        this.miniGameContainer.visible = false;
    }

    setupRespinMinigame = () => {

        return new Promise(async (resolve) => {
            let index = 0;

            this.buyBonusBtn.btn.visible = false;
            this.featureBar.addFeatures(this.receivedFeatures.length);
            this.portraitFeatureBar.addFeatures(this.receivedFeatures.length);

            this.featureBar.hollowFeaturesContainer.alpha = 0;
            this.portraitFeatureBar.hollowFeaturesContainer.alpha = 0;

            this.game.assetsManager.gameContainer.interactiveChildren = false;

            let playBtnTimer = new Timer(() => {
                this.game.assetsManager.playBtn!.button.alpha = 0.5
            }, 10)
            playBtnTimer.initialize();

            const featureMap = (feature: FeatureDetailed) => {
                return new Promise((resolve) => {
                    if (feature.name === "MultiplierIncrease") this.game.assetsManager.multiplier?.setupMultiplierValue(feature.value);
                    let index2 = index;
                    let timer = new Timer(() => {
                        this.gameField.respinFeature?.animate(feature.name, feature.value, index2);
                        return resolve(true)
                    }, (index > 0) ? 3000 * index + 1000 : 1000);
                    timer.initialize();
                    index++;
                })

            }

            await Promise.all(this.receivedFeatures.map(featureMap))

            let timer = new Timer(async () => {
                this.game.assetsManager.multiplier!.multiplier.renderable = true;
                this.game.slotMachine!.isRespinRunning = false
                this.game.assetsManager.gameContainer.interactiveChildren = true;
                await this.game.slotMachine?.onSpin();
                this.toggleMiniGame(false)
                this.featureBar.hollowFeaturesContainer.alpha = 1;
                this.portraitFeatureBar.hollowFeaturesContainer.alpha = 1;
                this.game.assetsManager.multiplier!.multiplier.renderable = false
                return resolve(true)
            }, 2000);

            timer.initialize();
        })



    }

    animateRandomChest = async () => {
        const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
        await sleep(3000);
        let prevIndex = 0;
        while (true) {
            let index = Math.floor(Math.random() * 10);
            if (index === prevIndex) {
                index > 0 ? index -= 1 : index += 1;
            }
            const chest = this.chests[index] as Chest;
            if (!chest.status) chest.chest.state.setAnimation(0, 'win_anim', false);
            prevIndex = index;
            const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
            await sleep(750);
            if (!this.miniGameContainer.visible) break;
        }
    }



    chestListener = () => {
        let openCounter = this.chests.filter(chest => chest.status).length;
        if (this.featureBar.hollowFeaturesContainer.children.length === openCounter + 1) {
            this.chestContainer.children.forEach(e => {
                e.interactive = false;
            })
        } else {
            this.chestContainer.children.forEach(e => {
                e.interactive = true;
            })
        }

    }


    modalChestListener = () => {
        let openCounter = this.chests.filter(chest => chest.status).length;
        if (this.featureBar.hollowFeaturesContainer.children.length === openCounter) {
            this.chestContainer.children.forEach(e => {
                e.interactive = false;
            })
        } else {
            this.chestContainer.children.forEach(e => {
                e.interactive = true;
            })
        }

    }

    miniGameEndTrigger = () => {
        let counter = 0;
        this.chestContainer.children.forEach(chest => {
            if (chest.interactive) counter++;
        })
        if (!counter) getEvent(EventType.MINIGAME_ENDED).send(true);
    }

    replaceFeatures = (features: FeatureDetailed[]) => {
        this.receivedFeatures = features
    }

    endMiniGame = async () => {
        const timer = new Timer(() => {
            this.toggleMiniGame(false)
            this.game.assetsManager.autoPlayBtn!.changeFSMode(true)
            this.game.assetsManager.playBtn!.changeButtonMode('fs')
            this.game.assetsManager?.playBtn?.setSpinCounter(this.game.slotMachine?.freeSpinCount!);
            this.game.slotMachine?.onSpin()
        }, 1300)
        getEvent(EventType.SPIN_IN_PROGRESS).send(true)
        timer.initialize()
    }

    clearText = () => {
        if(this.miniGameContainer.children.filter((event) => event.name === "BUY_EXTRA_FEATURE_TEXT")) {
            const letters = this.miniGameContainer.children.filter((event) => event.name === "BUY_EXTRA_FEATURE_TEXT")
            for (let i = 0; i < letters.length; i++) {
                this.miniGameContainer.removeChild(letters[i])
            }
        }
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.miniGameContainer.scale.set(1.4);
            this.miniGameContainer.x = 15;
            this.miniGameContainer.y = 215;

            this.chestContainer.scale.set(1.1);
            this.chestContainer.x = 20;
            this.chestContainer.y = 30;

            this.lettersHelper.setScale(1.25);
            this.lettersHelper.x = 140;
            this.lettersHelper.y = 83;

            this.minigameBG.visible = false;
            this.minigameBG.width = 1080;
            this.minigameBG.height = 885;

            this.minigameBGPortrait.visible = true;

            this.baefBtn.btn.x = 495;
            this.baefBtn.btn.y = 870;
        } else {
            if (window.innerWidth <= 1080) {
                this.miniGameContainer.scale.set(0.93);
                this.miniGameContainer.y = 120;
                this.miniGameContainer.x = 330;

                this.chestContainer.scale.set(1);
                this.chestContainer.x = 90;
                this.chestContainer.y = 50;

                this.lettersHelper.setScale(1);
                this.lettersHelper.x = this.minigameBG.width / 2 - this.lettersHelper.width / 2;
                this.lettersHelper.y = 115;

                this.minigameBG.visible = true;
                this.minigameBG.width = 1377;
                this.minigameBG.height = 1021;

                this.minigameBGPortrait.visible = false;

                this.baefBtn.btn.x = 506;
                this.baefBtn.btn.y = 799;
            } else {
                this.miniGameContainer.scale.set(1);
                this.miniGameContainer.x = 280;
                this.miniGameContainer.y = -25;

                this.chestContainer.scale.set(1);
                this.chestContainer.x = 90;
                this.chestContainer.y = 50;

                this.lettersHelper.setScale(1);
                this.lettersHelper.x = this.minigameBG.width / 2 - this.lettersHelper.width / 2;
                this.lettersHelper.y = 115;

                this.minigameBG.visible = true;
                this.minigameBG.width = 1377;
                this.minigameBG.height = 1021;

                this.minigameBGPortrait.visible = false;

                this.baefBtn.btn.x = 506;
                this.baefBtn.btn.y = 799;
            }
        }
    }
}

export default MiniGame;

