export function calculateArcPoints(startPoint: any, endPoint: any) {
	var maxPoints = 50;
	var clockwise = endPoint.x >= startPoint.x;

	var radius =
		Math.sqrt(
			Math.pow(endPoint.x - startPoint.x, 2) +
				Math.pow(endPoint.y - startPoint.y, 2)
		) / 2;

	var centerX = (startPoint.x + endPoint.x) / 2;
	var centerY = (startPoint.y + endPoint.y) / 2;

	var startAngle = Math.atan2(startPoint.y - centerY, startPoint.x - centerX);
	var endAngle = Math.atan2(endPoint.y - centerY, endPoint.x - centerX);

	if (clockwise && startAngle > endAngle) {
		endAngle += 2 * Math.PI;
	} else if (!clockwise && startAngle < endAngle) {
		startAngle += 2 * Math.PI;
	}

	var angleRange = Math.abs(endAngle - startAngle);
	var step = angleRange / Math.min(maxPoints, Math.ceil(angleRange * radius));

	var coordinates = [];

	for (var t = 0; t <= angleRange; t += step) {
		var angle = clockwise ? startAngle + t : startAngle - t;
		var x = centerX + radius * Math.cos(angle);
		var y = centerY + radius * Math.sin(angle);
		coordinates.push({ x: x, y: y });
	}

	return coordinates;
}
