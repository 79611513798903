import {Loader} from "pixi.js";
import {CDN_BASE_URL} from "../../index";

export enum MULTIPLIER_ASSETS {
  MULTIPLIER = "MULTIPLIER",
}

export const multiplierLoader = (loader: Loader) => {
    loader.add(MULTIPLIER_ASSETS.MULTIPLIER, `${CDN_BASE_URL}/multiplier/multiplier.json`)
}

