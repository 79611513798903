import { WrapperOptions, UserLocale } from "@playmer/ui-wrapper";

export class ConfigResolver {
    readonly options = {
        title: "Example Title",
        gameQuery: "#game-canvas",
        balance: 0,
        bet: {
            steps: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            default: 20,
        },
        locale: "en",
        platform: 'desktop',
        currency: "USD",
        layout: {
            top: [
                {
                    type: "header",
                    left: ["logo", "title"],
                    center: ["bet", "win", "balance"],
                    right: ["settings", "sound", "help", "exit"],
                },
            ],
            bottom: [
                {
                    type: "transparent",
                    center: ["info", "bet", "win", "balance"],
                },
            ],
            right: [{ type: "transparent", center: ["spin", "auto"] }],
            left: [{type: "transparent", center: ["bonus", "burger"]}]
        },
        win: 0,
    } as WrapperOptions;

    /**
     * Устанавливает название игры
     * @param title Название игры
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setTitle("Candy Slot");
     * ```
     */
    setTitle(title: string) {
        this.options.title = title;
    }

    /**
     * Устанавливает селектор канваса
     * @param selector Селектор канваса
     * @default "#game-canvas"
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setCanvasQuerySelector("#game-canvas");
     * ```
     * @see https://developer.mozilla.org/ru/docs/Web/API/Document/querySelector
     */
    setCanvasQuerySelector(selector: string) {
        this.options.gameQuery = selector;
    }

    /**
     * Устанавливает баланс игрока
     * @param balance Баланс игрока
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setBalance(1000);
     * ```
     */
    setBalance(balance: number) {
        this.options.balance = balance;
    }

    /**
     * Устанавливает значения ставок игры
     * @param steps Список значений, которые можно выбрать с помощью кнопок + (больше), - (меньше) в качестве ставки для следующей игры.
     * @param default Значение ставки по умолчанию (должно быть одним из значений шагов).
     *
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setBetSteps([1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500], 1);
     * ```
     */
    setBetSteps(steps: number[], defaultStep: number) {
        this.options.bet.steps = steps;
        this.options.bet.default = defaultStep;
    }

    /**
     * Устанавливает локализацию UI
     * @param locale Локализация
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setLocale("en");
     * ```
     */
    setLocale(locale: UserLocale) {
        this.options.locale = locale;
    }

    /**
     * Устанавливает валюту отображаемую в UI
     * @param currency Валюта
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setCurrency("USD");
     * ```
     * @see http://161.35.76.219/v0.x/config.html#currency
     */
    setCurrency(currency: string) {
        this.options.currency = currency;
    }

    /**
     * Устанавливает лейаут UI
     * @param layout Лейаут
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setLayout({
     *    top: [
     *       {
     *         type: "header",
     *         left: ["logo", "title"],
     *         center: ["bet", "win", "balance"],
     *         right: ["settings", "sound", "help", "exit"],
     *       }
     *    ],
     * });
     * ```
     * @see http://161.35.76.219/v0.x/config.html#layout
     */
    setLayout(layout: WrapperOptions["layout"]) {
        this.options.layout = {
            ...this.options.layout,
            ...layout,
        };
    }

    /**
     * Устанавливает значение выигрыша
     * @param win Значение выигрыша
     * @example
     * ```typescript
     * import { UI } from "@/ui";
     *
     * UI.setWin(100);
     * ```
     */
    setWin(win: number) {
        this.options.win = win;
    }
}