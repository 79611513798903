export class LocalStorage {
    public static setItem = (key: string, value: any) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value))
        } catch (e: any) {
            new Error('FAILED TO SET ITEM', e)
        }

    }

    public static getItem = (key: string) => {
        try {
            const value = window.localStorage.getItem(key)
            if(!value) {
                console.error('Can\'t find any data in localStorage')
                return null
            }

            return JSON.parse(value)
        } catch (e: any) {
            console.error(`FAILED TO FIND DATA`, e)
            return null
        }
    }

    public static  checker = () => {
        const isTurbo = LocalStorage.getItem('WrapperTurboSettings')

        console.log(`IS TURBO ENABLED`, isTurbo)
        if(!isTurbo) {
            window.localStorage.setItem('WrapperTurboSettings', JSON.stringify(false))
        }
    }
}
