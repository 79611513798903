import * as PIXI from 'pixi.js'
import { Game } from "../../Game";
import { Feature } from '../../classes/Feature';
import { MINIGAME_ASSETS } from '../../assetsLoader/miniGameLoader';
import { Texture } from 'pixi.js';
import { Timer } from '../../../utils/Timer'
import {Spine} from "pixi-spine";

export class FeaturesBar {
    game: Game;
    featuresContainer: PIXI.Container;
    hollowFeaturesContainer: PIXI.Container;
    features: Feature[];
    fullFragments: number;
    featuresVisualize: Map<string, Texture>;
    icons: any

    constructor(game: Game) {
        this.game = game;
        this.featuresContainer = new PIXI.Container();
        this.hollowFeaturesContainer = new PIXI.Container();
        this.features = [];
        this.fullFragments = 0;
        this.featuresVisualize = new Map();
        this.icons = this.game.app.loader.resources.FEATURES_SHEET.spritesheet?.textures
    }

    init = () => {
        this.setupFeatureContainer();
        this.setupFeaturesVisualize();
        this.game.assetsManager.gameContainer.addChild(this.hollowFeaturesContainer);
        this.game.assetsManager.gameContainer.addChild(this.featuresContainer);
        this.game.resizeCallbacks.push(this.resize)
    }


    setupFeatureContainer = () => {
        this.featuresContainer.width = 196;
        this.featuresContainer.height = 730;
        this.featuresContainer.x = -87;
        this.featuresContainer.y = 40;

        this.hollowFeaturesContainer.width = 196;
        this.hollowFeaturesContainer.height = 730;
        this.hollowFeaturesContainer.x = 8;
        this.hollowFeaturesContainer.y = 113;

        this.featuresContainer.name = 'FEATURE_BAR'
        this.hollowFeaturesContainer.name = 'HOLLOW_FEATURE_BAR'
    }

    clearFeatures = () => {
        this.features.splice(0, this.features.length);
        this.featuresContainer.removeChildren(0, this.featuresContainer.children.length);
        this.hollowFeaturesContainer.removeChildren(0, this.hollowFeaturesContainer.children.length);
        this.fullFragments = 0;
    }

    setupFeaturesVisualize = () => {
        this.featuresVisualize.set("ExtraFreespins1", this.icons['extra_fs_fb.png']);
        this.featuresVisualize.set("ExtraFreespins2", this.icons['extra_fs2_fb.png']);
        this.featuresVisualize.set("ExtraFreespins3", this.icons['extra_fs3_fb.png']);
        this.featuresVisualize.set("SymbolWildH1", this.icons['h1_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildH2", this.icons['h2_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildH3", this.icons['h3_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildH4", this.icons['h4_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL1", this.icons['l1_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL2", this.icons['l2_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL3", this.icons['l3_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL4", this.icons['l4_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL1", this.icons['l1_removed_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL2", this.icons['l2_removed_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL3", this.icons['l3_removed_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL4", this.icons['l4_removed_fb.png']);
        this.featuresVisualize.set("MultiplierIncrease2", this.icons['m_x2_fb.png']);
        this.featuresVisualize.set("MultiplierIncrease3", this.icons['m_x3_fb.png']);
        this.featuresVisualize.set("MultiplierIncrease5", this.icons['m_x5_fb.png']);
        this.featuresVisualize.set("IncreaseMultipliertrue", this.icons['p_mult_fb.png']);
        this.featuresVisualize.set("StickyWildtrue", this.icons['s_wild_fb.png']);
    }

    addFeatures = async (count = 3) => {
        for (let i = 5; i > 5 - count; i--) {
            const frag = new Spine(this.game.app.loader.resources[MINIGAME_ASSETS.FEATURE_BAR_ANIM].spineData!);
            const fragContainer = new PIXI.Container();

            fragContainer.width = frag.width;
            fragContainer.height = frag.height;

            fragContainer.x = 191;
            fragContainer.y = 146 * i;

            fragContainer.addChild(frag);
            this.hollowFeaturesContainer.addChild(fragContainer);
        }
    }

    addFeature = async () => {
        const index = 5 - this.hollowFeaturesContainer.children.length;
        const frag = new Spine(this.game.app.loader.resources[MINIGAME_ASSETS.FEATURE_BAR_ANIM].spineData!);
        const fragContainer = new PIXI.Container();
        frag.state.setAnimation(0, "animation", false);
        fragContainer.width = frag.width;
        fragContainer.height = frag.height;

        fragContainer.x = 191;
        fragContainer.y = 146 * index;
        fragContainer.addChild(frag);
        this.hollowFeaturesContainer.addChild(fragContainer);
    }

    insertObtaindedFeature = (feature: [string, number | boolean]) => {
        const index = this.fullFragments;
        const fragment = this.hollowFeaturesContainer.getChildAt(index);
        const obtainedFeature = new Feature(index);


        obtainedFeature.init()
        obtainedFeature.featureContainer.position.copyFrom(fragment);
        let featureIcon = new PIXI.Sprite(this.featuresVisualize.get(`${feature[0]}${feature[1]}`));


        const timer = new Timer(() => {
            obtainedFeature.featureContainer.addChild(featureIcon);
            this.featuresContainer.addChild(obtainedFeature.featureContainer);
            this.features.push(obtainedFeature);
            this.fullFragments += 1;
            this.game.assetsManager.miniGame?.setChestsInteractive(true);
        }, 1500)
        timer.initialize();
    }

    setupFeatureBarForRespin = (name: string, value: number | boolean, index: number) => {
        return new Promise(async (resolve) => {
            const respinFeature = new Feature(index);
			respinFeature.init();
			let featureIcon = new PIXI.Sprite(
				this.featuresVisualize.get(`${name}${value}`)
			);
			respinFeature.featureContainer.position.copyFrom(
                this.hollowFeaturesContainer.getChildAt(index)
            );
            this.featuresContainer.addChild(respinFeature.featureContainer);
            
            const timer = new Timer(() => {
                this.features.push(respinFeature);
                respinFeature.featureContainer.addChild(featureIcon);
				return resolve(true);
			}, 1500);
			timer.initialize();
		})
    }



    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.featuresContainer.visible = false;
            this.hollowFeaturesContainer.visible = false;
        } else {
            if (window.innerWidth <= 1080) {
                if (this.game.leftHandedMode) {
                    this.featuresContainer.visible = true;
                    this.featuresContainer.scale.set(0.72)
                    this.featuresContainer.x = 1525;
                    this.featuresContainer.y = 115;

                    this.hollowFeaturesContainer.visible = true;
                    this.hollowFeaturesContainer.scale.set(0.72)
                    this.hollowFeaturesContainer.x = 1525;
                    this.hollowFeaturesContainer.y = 115;
                } else {
                    this.featuresContainer.visible = true;
                    this.featuresContainer.scale.set(0.72)
                    this.featuresContainer.x = -13;
                    this.featuresContainer.y = 115;

                    this.hollowFeaturesContainer.visible = true;
                    this.hollowFeaturesContainer.scale.set(0.72)
                    this.hollowFeaturesContainer.x = 56;
                    this.hollowFeaturesContainer.y = 166;
                }

            } else {
                this.featuresContainer.visible = true;
                this.featuresContainer.scale.set(1)
                this.featuresContainer.x = -87;
                this.featuresContainer.y = 40;

                this.hollowFeaturesContainer.visible = true;
                this.hollowFeaturesContainer.scale.set(1)
                this.hollowFeaturesContainer.x = 8;
                this.hollowFeaturesContainer.y = 113;
            }
        }

    }
}

export default FeaturesBar;
