export const respinFeatureStartCoords = [
	{ x: 260, y: 350 },
	{ x: 500, y: 300 },
	{ x: 830, y: 280 },
	{ x: 1120, y: 280 },
	{ x: 650, y: 400 },
	{ x: 330, y: 570 },
	{ x: 590, y: 620 },
	{ x: 850, y: 550 },
	{ x: 1100, y: 500 },
	{ x: 1000, y: 700 },
];

export const moveToFeatureBarDesktop = [
	{ x: -80, y: 1020 },
	{ x: -80, y: 875 },
	{ x: -80, y: 730 },
	{ x: -80, y: 580 },
	{ x: -80, y: 430 },
];

export const moveToFeatureBarLandscape = [
	{ x: -147, y: 770 },
	{ x: -147, y: 655 },
	{ x: -147, y: 540 },
	{ x: -147, y: 430 },
	{ x: -147, y: 320 },
];

export const moveToFeatureBarPortrait = [
	{ x: 250, y: 1330 },
	{ x: 470, y: 1330 },
	{ x: 680, y: 1330 },
	{ x: 900, y: 1330 },
	{ x: 1120, y: 1330 },
];
