import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application, Texture } from 'pixi.js';
import { Game } from "../../Game";
import { BUTTONS_ASSETS } from "../../assetsLoader/ButtonsLoader";
import { EventType, getEvent } from '../../../GameEventBus';
import { SoundNames } from '../../constants/sounds';
import { Sounds } from '../../classes/SoundController';

class PlayBtn {
    app: Application;
    game: Game;
    button: PIXI.Container;
    buttonBackground: PIXI.Sprite;
    buttonBorder: PIXI.Sprite;
    buttonBorderFS: PIXI.Sprite;
    buttonPlay: PIXI.Sprite;
    buttonPlayHover: PIXI.Sprite;
    buttonStop: PIXI.Sprite;
    buttonStopHover: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;
    buttonLabel: PIXI.Text;

    constructor(game: Game) {
        this.game = game;
        this.app = game.app
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.button = new PIXI.Container();
        this.buttonBackground = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_BG));
        this.buttonBorder = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_BORDER));
        this.buttonBorderFS = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_BORDER_FS));
        this.buttonPlay = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_ICON));
        this.buttonPlayHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLAY_BTN_ICON_HOVER));
        this.buttonStop = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.STOP_BTN_ICON));
        this.buttonStopHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.STOP_BTN_ICON_HOVER));
        this.buttonLabel = new PIXI.Text('', {
            fontFamily: 'Rubik',
            fontSize: 55.5,
            fontWeight: '900',
            fill: '#F9AD29',
            stroke: '#000000',
            strokeThickness: 1,
        })
    }

    init = async () => {
        await this.setupBackgrounds()
        await this.setupBorder()
        await this.setupIcons()
        await this.setupInteractive()

        // this.button.x = 1670;
        this.button.y = 485;
        this.button.name = 'PLAY_BUTTON';
        this.button.zIndex = 100
        this.buttonLabel.anchor.set(0.5)
        // this.game.assetsManager.gameContainer.addChild(this.button)
        this.button.addChild(this.buttonLabel!)

        // this.game.resizeCallbacks.push(this.resize)
        // this.game.inactiveElementsWhileSpin.push(this.button)
        //
        // getEvent<boolean>(EventType.SPIN_IN_PROGRESS).subscribe(value => {
        //     if (this.game.slotMachine?.isFsRunning) {
        //         this.button.alpha = 0.5;
        //         this.button.interactive = false;
        //         return;
        //     }
        //
        //
        //     if (value && !this.game.slotMachine?.isFsRunning) {
        //         this.buttonPlay.visible = false;
        //         this.buttonPlayHover.visible = false;
        //         this.buttonStop.visible = true;
        //         this.buttonStopHover.visible = true;
        //     } else if (!this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) {
        //         this.buttonPlay.visible = true;
        //         this.buttonPlayHover.visible = true;
        //         this.buttonStop.visible = false;
        //         this.buttonStopHover.visible = false;
        //         this.button.alpha = 1;
        //         this.button.interactive = true;
        //     } else if (this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) {
        //         this.buttonPlay.visible = false;
        //         this.buttonPlayHover.visible = false;
        //         this.buttonStop.visible = true;
        //         this.buttonStopHover.visible = true;
        //         this.button.alpha = 1;
        //         this.button.interactive = true;
        //     }
        // })
    };

    //<--
    // DESIGN SETUP
    setupBorder = () => {
        this.buttonBorder.x = 0;
        this.buttonBorder.y = 0;
        this.buttonBorder.width = 200;
        this.buttonBorder.height = 200;
        this.buttonBorder.anchor.set(0.5);
        this.button.addChild(this.buttonBorder);

        this.buttonBorderFS.x = 0;
        this.buttonBorderFS.y = 0;
        this.buttonBorderFS.width = 200;
        this.buttonBorderFS.height = 200;
        this.buttonBorderFS.anchor.set(0.5);
        this.buttonBorderFS.alpha = 0;
        this.button.addChild(this.buttonBorderFS);
    }

    setupIcons = () => {
        this.buttonPlay.x = 0;
        this.buttonPlay.y = 0;
        this.buttonPlayHover.alpha = 0;
        this.buttonPlay.anchor.set(0.5);
        this.buttonPlayHover.anchor.set(0.5);
        this.buttonBorder.anchor.set(0.5);

        this.buttonStop.x = 0;
        this.buttonStop.y = 0;
        this.buttonStop.anchor.set(0.5);
        this.buttonStopHover.anchor.set(0.5);
        this.buttonStopHover.alpha = 0;
        this.buttonStop.visible = false;
        this.buttonStopHover.visible = false;

        this.button.addChild(this.buttonPlay);
        this.button.addChild(this.buttonPlayHover);

        this.button.addChild(this.buttonStop);
        this.button.addChild(this.buttonStopHover);
    }

    setupBackgrounds = () => {
        this.buttonBackground.y = 0;
        this.buttonBackground.x = 0;
        this.buttonBackground.anchor.set(0.5);
        this.button.addChild(this.buttonBackground);
    }

    // DESIGN
    // -->

    setupInteractive = () => {
        this.button.hitArea = new PIXI.Circle(0, 0, 110)
        this.button.interactive = true
        this.button.buttonMode = true;
        this.button.cursor = 'pointer';
        this.button
            .on('pointerup', () => this.doPointerUp())
            .on('pointerupoutside', () => this.doPointerUpOutside())
            .on('touchstart', () => this.doPointerDown())
            .on('click', (e) => this.leftClick(e))
            .on('pointerover', () => this.doPointerOver())
            .on('pointerout', () => this.doPointerOut());
    }

    // <--
    // METHODS

    leftClick = (e: any) => {
        if (e.data.button === 0) {
            this.doPointerDown()
        }
    }

    doPointerUp() {
        if (this.game.slotMachine?.isFsRunning) return
        if (this.pointerIsOver) {
            const animation = gsap.timeline();
            animation.fromTo(
                this.buttonPlay,
                { alpha: 0 },
                { alpha: 1, repeat: 0, duration: 0 })

        } else {
            const wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.buttonPlayHover,
                { alpha: 1 },
                { alpha: 0, repeat: 0, duration: 0 },
            );
        }
        this.pointerIsDown = false;
    }

    doPointerUpOutside() {
        if (this.game.slotMachine?.isFsRunning) return
        const animation = gsap.timeline();
        animation.fromTo(
            this.buttonPlay,
            { alpha: 0 },
            { alpha: 1, repeat: 0, duration: 0 }
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    doPointerDown() {
        // Sounds.play(SoundNames.BTN_UI);
        if (this.game.slotMachine?.isFsRunning) return
        if (this.game.slotMachine?.isSpinning) {
            getEvent(EventType.ANIMATION_SPEED).send(0.2)
            this.button.interactive = false;
            if (this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) {
                this.game.assetsManager.autoPlayBtn!.setButtonState(false);
                this.game.assetsManager.autoPlayModal.setCounter(0);
            }
            return;
        }
        const animation = gsap.timeline();
        animation.fromTo(
            this.buttonPlayHover,
            { alpha: 0 },
            { alpha: 1, repeat: 0, duration: 0 }
        );
        this.pointerIsDown = true;
        if(this.game.isDebug) {
            const data = localStorage.getItem('debugReels');
            const debugReels = JSON.parse(data!);
            const command = debugReels.command ? debugReels.command : 'debug';
            this.game.slotMachine?.changeCommand(command);
            this.game.slotMachine?.onSpin()
        } else {
            this.game.slotMachine?.onSpin()
        }
    }


    doPointerOver() {
        if (this.game.slotMachine?.isFsRunning) return

        const animation = gsap.timeline();
        animation.fromTo(
            this.buttonBorder,
            { rotation: this.buttonBorder.rotation },
            { rotation: this.buttonBorder.rotation + Math.PI, repeat: 0, duration: 0.3 }
        );

        animation.fromTo(
            this.buttonPlayHover,
            { alpha: 0 },
            { alpha: 1, repeat: 0, duration: 0 }
        );

        animation.fromTo(
            this.buttonStopHover,
            { alpha: 0 },
            { alpha: 1, repeat: 0, duration: 0 }
        );

        this.pointerIsOver = true;
    }

    doPointerOut() {
        if (this.game.slotMachine?.isFsRunning) return
        const wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonBorder,
            { rotation: this.buttonBorder.rotation },
            { rotation: this.buttonBorder.rotation - Math.PI, repeat: 0, duration: 0.3 }
        );

        wiggleTween.fromTo(
            this.buttonPlayHover,
            { alpha: 1 },
            { alpha: 0, repeat: 0, duration: 0 }
        );
        wiggleTween.fromTo(
            this.buttonPlay,
            { alpha: 0 },
            { alpha: 1, repeat: 0, duration: 0 }
        );

        wiggleTween.fromTo(
            this.buttonStopHover,
            { alpha: 1 },
            { alpha: 0, repeat: 0, duration: 0 }
        );
        wiggleTween.fromTo(
            this.buttonStop,
            { alpha: 0 },
            { alpha: 1, repeat: 0, duration: 0 }
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    changeButtonState = (status: boolean) => {
        if (!status) {
            this.button.interactive = false
            this.button.buttonMode = false

        } else {
            this.button.interactive = true
            this.button.buttonMode = true
        }
    }

    changeButtonMode = (mode: 'default' | 'fs' | 'autoPlay' | 'stop') => {
        if (mode === 'default') {
            this.buttonStop.visible = false;
            this.buttonStopHover.visible = false;
            this.buttonPlay.visible = true;
            this.buttonPlayHover.visible = true;
            this.buttonBackground.alpha = 1
            this.buttonBorderFS.alpha = 0
            this.buttonBorder.alpha = 1
            this.buttonPlayHover.alpha = 0
            this.buttonPlay.alpha = 1
            this.buttonLabel.alpha = 0
            this.buttonStopHover.alpha = 0
        } else if (mode === 'fs') {
            this.buttonPlay.visible = true;
            this.buttonPlayHover.visible = true;
            this.buttonStop.visible = false;
            this.buttonStopHover.visible = false;
            this.buttonBackground.alpha = 1
            this.buttonBorderFS.alpha = 1
            this.buttonBorder.alpha = 0
            this.buttonPlayHover.alpha = 0
            this.buttonStopHover.alpha = 0
            this.buttonLabel.alpha = 1
            this.buttonStop.visible = false;
        } else if (mode === 'autoPlay') {
            this.buttonPlay.visible = true;
            this.buttonPlayHover.visible = true;
            this.buttonStop.visible = false;
            this.buttonStopHover.visible = false;
            this.button.interactive = false;
            this.buttonBackground.alpha = 1
            this.buttonBorderFS.alpha = 0
            this.buttonBorder.alpha = 1
            this.buttonPlayHover.alpha = 0
            this.buttonStopHover.alpha = 0
            this.buttonStop.alpha = 1
        } else if (mode === 'stop') {
            if (this.game.slotMachine?.isFsRunning) return;
            this.buttonStop.visible = true;
            this.buttonStopHover.visible = true;
            this.buttonPlay.visible = false;
            this.buttonPlayHover.visible = false;
            this.button.interactive = true
            this.button.alpha = 1
        }
    }

    setSpinCounter = (value: number | string) => {
        if (this.game.slotMachine?.isFsRunning) {
            this.buttonLabel.text = !value ? 0 : value;
            this.buttonLabel.alpha = 1;
            this.buttonPlay.alpha = 0
        } else {
            this.buttonLabel.alpha = 0;
            this.buttonPlay.alpha = 1
        }

    }

    disableOnWin = () => {
        if (this.game.slotMachine!.isFsRunning) return;
        this.changeButtonMode('default');
        this.button.interactive = false;
        this.button.alpha = 0.5;
    }

    resize = () => {

        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.button.scale.set(1.8);
            this.button.y = 2500;
            this.button.x = 960;

        } else {
            if (window.innerWidth <= 1080) {
                if (this.game.leftHandedMode) {
                    this.button.scale.set(1.35);
                    this.button.x = 190;
                    this.button.y = 506;
                } else {
                    this.button.scale.set(1.35);
                    this.button.x = 1740;
                    this.button.y = 506;
                }
            } else {
                this.button.scale.set(1);
                this.button.x = 1779;
                this.button.y = 485;
            }
        }

    }
    // METHODS
    //-->
}


export default PlayBtn;
