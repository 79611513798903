import {Container, Text, TextStyle} from "pixi.js";

class TextStyleTool {
    fontFamily: string;
    size: number;

    constructor() {
        this.fontFamily = "Norse-Bold";
        this.size = 70;
    }

    addText(text: string, fontSize: number) {
        this.size = fontSize;
        const textContainer = new Container();

        const shadow = new Text(text, this.textShadow());
        shadow.x = 2;
        shadow.y = 4;
        shadow.alpha = 0.55;
        textContainer.addChild(shadow);

        const underMain = new Text(text, this.textUnderMainFont());
        underMain.x = 2;
        underMain.y = 4;
        underMain.alpha = 0.8;
        textContainer.addChild(underMain);

        const main = new Text(text, this.textMainFont());
        main.x = 0;
        main.y = 2;
        textContainer.addChild(main);
        return textContainer;
    }

    textUnderMainFont() {
        const color = "#28484f";
        const style = new TextStyle({
            fill: color,
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 20,
            letterSpacing: -2,
            padding: 5,
        });
        return style;
    }

    textMainFont() {
        const gradient = ["#9ca6ab", "#dae1e1", "#e1e8e7", "#e1e8e7", "#e7e8e1", "#c6c5b9", "#50525c", "#6b7579", "#a0a7ab", "#e1e8e7", "#e1e8e7", "#e1e8e7"];
        const style = new TextStyle({
            fill: gradient,
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 20,
            letterSpacing: -2,
            padding: 5,
        });
        return style;
    }

    textShadow() {
        const style = new TextStyle({
            fill: "#000000",
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 50,
            strokeThickness: 5,
            letterSpacing: -2,
            padding: 5,
        });
        return style;
    }
}

export const textStyleTool = new TextStyleTool();
