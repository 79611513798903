import { Container, Sprite } from 'pixi.js';
import { Texture } from 'pixi.js';
import { Game } from '../../Game';
import { BASE_AMOUNT_ASSETS } from '../../assetsLoader/baseAmountLoader';
import { Timer } from '../../../utils/Timer';
import { gsap } from 'gsap';
import { getEvent, EventType } from '../../../GameEventBus';
import { SoundNames } from '../../constants/sounds';
import { Sounds } from '../../classes/SoundController';

class BaseAmount {
	game: Game;
	container: Container;
	overlay?: Sprite;
	win: Container;
	multWin: Container;
	multiplier: Container;
	values: Map<string, Texture>;
	spriteCurrency: Sprite;
	secondSpriteCurrency: Sprite;

	constructor(game: Game) {
		this.game = game;
		this.win = new Container();
		this.multWin = new Container();
		this.multiplier = new Container();
		this.container = new Container();
		this.values = new Map();
		this.spriteCurrency = new Sprite();
		this.secondSpriteCurrency = new Sprite();
	}

	async init() {
		this.setValues();
		this.setupModal();
		this.game.assetsManager.gameField?.container.addChild(this.container);
		getEvent<boolean>(EventType.SLOW_WIN_ANIMATION_OVER).subscribe((value) => {
			this.container.visible = false;
			this.closeModal(1);
		})
		this.container.name = 'BASE_AMOUNT';
	}

	setupModal = async () => {
		this.overlay = new Sprite(Texture.from(BASE_AMOUNT_ASSETS.BACKGROUND));
		this.overlay.anchor.set(0.5);
		this.container.zIndex = 10000;
		this.container.visible = false;
		this.container.addChild(this.overlay);
	};

	setValues = () => {
		this.values.set('0', Texture.from(BASE_AMOUNT_ASSETS.ZERO));
		this.values.set('1', Texture.from(BASE_AMOUNT_ASSETS.ONE));
		this.values.set('2', Texture.from(BASE_AMOUNT_ASSETS.TWO));
		this.values.set('3', Texture.from(BASE_AMOUNT_ASSETS.THREE));
		this.values.set('4', Texture.from(BASE_AMOUNT_ASSETS.FOUR));
		this.values.set('5', Texture.from(BASE_AMOUNT_ASSETS.FIVE));
		this.values.set('6', Texture.from(BASE_AMOUNT_ASSETS.SIX));
		this.values.set('7', Texture.from(BASE_AMOUNT_ASSETS.SEVEN));
		this.values.set('8', Texture.from(BASE_AMOUNT_ASSETS.EIGHT));
		this.values.set('9', Texture.from(BASE_AMOUNT_ASSETS.NINE));
		this.values.set(',', Texture.from(BASE_AMOUNT_ASSETS.COMMA));
		this.values.set('.', Texture.from(BASE_AMOUNT_ASSETS.DOT));
		this.values.set('x', Texture.from(BASE_AMOUNT_ASSETS.X));
	};

	setMultiplier = (multiplierValue: string | null) => {
		if (!multiplierValue) return;
		const letterSpacing = 0;
		let xPos = new Container();
		const ex = new Sprite(this.values.get('x'));
		xPos.x = 0;
		xPos.y = 0;
		xPos.x = ex.width;
		this.multiplier.addChild(ex);
		for (let i = 0; i < multiplierValue.length; i++) {
			const number = new Sprite(
				this.values.get(multiplierValue[i].toLowerCase())
			);
			number.position.copyFrom(xPos);
			number.x += xPos.width + letterSpacing;
			number.y = 0;

			this.multiplier.addChild(number);
			xPos = number;
		}
		xPos = new Container();
		xPos.destroy();
		this.multiplier.pivot.set(
			this.multiplier.width / 2,
			this.multiplier.height / 2
		);
	};

	setWin = (win: string) => {
		this.overlay!.width = this.win.width * 3;
		const letterSpacing = 0;
		this.win.removeChildren();
		let xPos = new Container();
		const cy = this.spriteCurrency;
		xPos.x = 0;
		xPos.y = 0;
		xPos.x = cy.width;
		this.win.addChild(cy);
		for (let i = 0; i < win.length; i++) {
			const number = new Sprite(this.values.get(win[i].toLowerCase()));
			number.position.copyFrom(xPos);
			number.x += xPos.width + letterSpacing;
			number.y = 0;
			this.win.addChild(number);
			xPos = number;
		}
		this.win.pivot.set(this.win.width / 2, this.win.height / 2);
		xPos = new Container();
		xPos.destroy();
	};

	setWinAnimation = async (value: number, duration: number) => {
		let animatedValue = 0;
		const animValue = (start: any, end: number, duration: number) => {
			let startTimestamp: any = null;
			const step = (timestamp: any) => {
				if (!startTimestamp) startTimestamp = timestamp;
				const progress = Math.min((timestamp - startTimestamp) / duration, 1);
				animatedValue = +(progress * (end - start) + start);
				if (progress < 1) {
					window.requestAnimationFrame(step);
				} else {
					animatedValue = +value;
				}
				this.setWin(animatedValue.toFixed(2));
			};
			window.requestAnimationFrame(step);
		};
		animValue(0, +value, duration);
	};

	setSecondWin = (multiplier: string, win: string) => {
		const letterSpacing = 0;
		let xPos = new Container();
		const cy = this.secondSpriteCurrency;
		xPos.x = 0;
		xPos.y = 0;
		xPos.x = cy.width;
		this.multWin.addChild(cy);
		for (let i = 0; i < win.length; i++) {
			const number = new Sprite(this.values.get(win[i].toLowerCase()));
			number.position.copyFrom(xPos);
			number.x += xPos.width + letterSpacing;
			number.y = 0;
			this.multWin.addChild(number);
			this.multWin.position.copyFrom(this.win);
			xPos = number;
		}
		xPos = new Container();
		xPos.destroy();
		this.multWin.alpha = 0;
		this.multWin.pivot.set(this.multWin.width / 2, this.multWin.height / 2);
		this.container.addChild(this.multWin);
		const timer = new Timer(() => this.closeModal(Number(multiplier)), 1000);
		timer.initialize();
	};

	setSecondWinAnimation = async (win: string, multiplier: string) => {
		this.container.addChild(this.multiplier);
		this.multiplier.visible = true;
		this.multiplier.position.copyFrom(this.win);
		this.multiplier.y = -this.win.height + 30;
		this.setMultiplier(multiplier);
		const multipliedWin = Number(win) * Number(multiplier);
		this.setSecondWin(multiplier, String(multipliedWin.toFixed(2)));
		const timer = new Timer(() => {
			this.dropdownAnimation();
		}, 150);
		timer.initialize();
	};

	openSlow = async (currency: string, win: string, multiplier: string, anim: boolean) => {
		this.container.visible = true;
		this.checkSprite(currency);
		await this.setWinAnimation(Number(win), anim ? 800 : 0);
		this.container.addChild(this.win);
		const timer = new Timer(() => {
			if (Number(multiplier) && Number(multiplier) > 1) {
				Sounds.play(SoundNames.MULTIPLIER_SOUND);
				this.setSecondWinAnimation(win, multiplier);
			} else {
				this.closeModal(Number(multiplier));
			}
		}, Number(multiplier) > 1 ? 500 : 1500);
		timer.initialize();
	};

	closeModal = async (multValue: number) => {
		if (multValue > 1) {
			await this.closeMultAnimation();
		} else {
			this.closeDefaultAnimation();
		}
		const timer = new Timer(() => {
			this.game.assetsManager.gameField?.setBlur(false);
			this.win.removeChildren();
			this.multWin.removeChildren();
			this.multiplier.removeChildren();
			this.win.alpha = 1;
		}, 250);
		timer.initialize();
	};

	checkSprite = (currency: string) => {
		this.spriteCurrency = new Sprite(this.values.get(currency.toLowerCase()));
		this.secondSpriteCurrency = new Sprite(
			this.values.get(currency.toLowerCase())
		);
	};

	closeDefaultAnimation = () => {
		const wiggleTween = gsap.timeline();
		const wiggleTween2 = gsap.timeline();
		const wiggleTween3 = gsap.timeline();

		wiggleTween.to(this.win, 0.2, { width: this.win.width * 1.1 });

		wiggleTween2.to(this.win, 0.2, { height: this.win.height * 1.1 });

		wiggleTween3.to(this.win, 0.2, { alpha: 0 });

		wiggleTween.to(this.win, 0.2, { width: this.win.width });

		wiggleTween2.to(this.win, 0.2, { height: this.win.height });
		const timer = new Timer(() => {
			this.container.visible = false;
		}, 200);
		timer.initialize();
	};

	closeMultAnimation = async () => {
		const wiggleTween = gsap.timeline();
		const wiggleTween2 = gsap.timeline();
		const wiggleTween3 = gsap.timeline();

		wiggleTween.to(this.multWin, 0.2, { width: this.multWin.width * 1.1 });
		wiggleTween2.to(this.multWin, 0.2, { height: this.multWin.height * 1.1 });
		wiggleTween3.to(this.multWin, 0.2, { alpha: 0 });
		wiggleTween.to(this.multWin, 0.2, { width: this.multWin.width });
		wiggleTween2.to(this.multWin, 0.2, { height: this.multWin.height });

		const timer = new Timer(() => {
			this.container.visible = false;
		}, 200);
		timer.initialize();
	};

	scaleAnimtation = () => {
		const wiggleTween = gsap.timeline();
		const wiggleTween2 = gsap.timeline();
		wiggleTween.to(this.multWin, 0.1, { width: this.multWin.width * 1.1 });
		wiggleTween2.to(this.multWin, 0.1, { height: this.multWin.height * 1.1 });
		wiggleTween.to(this.multWin, 0.1, { width: this.multWin.width });
		wiggleTween2.to(this.multWin, 0.1, { height: this.multWin.height });
	};

	dropdownAnimation = () => {
		const wiggleTween = gsap.timeline();
		const timer = new Timer(() => {
			this.multiplier.visible = false;
		}, 300);
		timer.initialize();
		wiggleTween.to(this.multiplier, 0.41, { y: this.win.y }).then(() => {
			wiggleTween.fromTo(this.win, 0.05, { alpha: 1 }, { alpha: 0, repeat: 0 });
			this.scaleAnimtation();
			wiggleTween.to(this.multWin, 0.25, { alpha: 1 });
		});
	};
}

export default BaseAmount;
