import packageInfo from "../../../package.json";
import styled from "styled-components";
import {appConfig} from "../../config";
import {EventType, useEventReceiver} from "../../GameEventBus";
import {useState} from "react";

const Version = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #00D700;
  border-top-right-radius: 8px;
  z-index: 9999;
  padding: 2px 8px;
  user-select: none;

`
export default function AppVersion() {
    const [isDebug, setIsDebug] = useState(false)

    useEventReceiver<boolean>(EventType.IS_DEBUG, (value) => {
        setIsDebug(value)
    })

    console.log('version: ', packageInfo.version, `${isDebug ? "-debug" : ""}`)

    if (!appConfig.showVersion) return null

    return null
}

