import { Howl } from 'howler';

export enum SoundNames {
	BIG_WIN = 'BIG_WIN',
	FREESPIN_BG_SOUND = 'FREESPIN_BG_SOUND',
	BG_SOUND = 'BG_SOUND',
	BTN_SPIN = 'BTN_SPIN',
	BTN_UI = 'BTN_UI',
	POPUP_BONUS_START = 'POPUP_BONUS_START',
	POPUP_BONUS_TOTAL_WIN = 'POPUP_BONUS_TOTAL_WIN',
	BIG_WIN_END = 'BIG_WIN_END',
	BONUS_LAND = 'BONUS_LAND',
	BONUS_WIN = 'BONUS_WIN',
	FEATURE_PICKS = 'FEATURE_PICKS',
	FS_TRIGGER = 'FS_TRIGGER',
	MULTIPLIER_SOUND = 'MULTIPLIER_SOUND',
	REEL_SPIN = 'REEL_SPIN',
	REEL_STOP = 'REEL_STOP',
	RESPIN_TRIGGER = 'RESPIN_TRIGGER',
	SPINFICATION = 'SPINFICATION',
	WILD_LOCKED = 'WILD_LOCKED',
	WIN = 'WIN',
	SCATTER_LAND_0 = 'SCATTER_LAND_0',
	SCATTER_LAND_1 = 'SCATTER_LAND_1',
	SCATTER_LAND_2 = 'SCATTER_LAND_2',
	WIN_BOW = 'WIN_BOW',
	WIN_METAL = 'WIN_METAL',
}

export const SoundsMap = {
	[SoundNames.BIG_WIN]: new Howl({
		src: './sounds/bg_bigwin.mp3',
		volume: 1,
		loop: true,
	}),
	[SoundNames.FREESPIN_BG_SOUND]: new Howl({
		src: './sounds/bg_freespin.mp3',
		volume: 1,
		loop: true,
	}),
	[SoundNames.BG_SOUND]: new Howl({
		src: './sounds/bg_main.mp3',
		volume: 1,
		loop: true,
	}),
	[SoundNames.BTN_SPIN]: new Howl({
		src: './sounds/btn_spin.mp3',
		volume: 1,
	}),
	[SoundNames.BTN_UI]: new Howl({
		src: './sounds/btn_ui.mp3',
		volume: 1,
	}),
	[SoundNames.POPUP_BONUS_START]: new Howl({
		src: './sounds/popup_bonus_start.mp3',
		volume: 1,
	}),
	[SoundNames.POPUP_BONUS_TOTAL_WIN]: new Howl({
		src: './sounds/popup_bonus_totalWin.mp3',
		volume: 1,
	}),
	[SoundNames.BIG_WIN_END]: new Howl({
		src: './sounds/sfx_bigwin_end.mp3',
		volume: 1,
		loop: true,
	}),
	[SoundNames.BONUS_LAND]: new Howl({
		src: './sounds/sfx_bonus_land.mp3',
		volume: 1,
	}),
	[SoundNames.BONUS_WIN]: new Howl({
		src: './sounds/sfx_bonus_win.mp3',
		volume: 1,
	}),
	[SoundNames.FEATURE_PICKS]: new Howl({
		src: './sounds/sfx_feature_picks.mp3',
		volume: 1,
	}),
	[SoundNames.FS_TRIGGER]: new Howl({
		src: './sounds/sfx_fs_trigger.mp3',
		volume: 1,
	}),
	[SoundNames.MULTIPLIER_SOUND]: new Howl({
		src: './sounds/sfx_multiplier.mp3',
		volume: 1,
	}),
	[SoundNames.REEL_SPIN]: new Howl({
		src: './sounds/sfx_reel_spin.mp3',
		volume: 1,
	}),
	[SoundNames.REEL_STOP]: new Howl({
		src: './sounds/sfx_reel_stop.mp3',
		volume: 1,
	}),
	[SoundNames.RESPIN_TRIGGER]: new Howl({
		src: './sounds/sfx_respin_trigger.mp3',
		volume: 1,
	}),
	[SoundNames.SPINFICATION]: new Howl({
		src: './sounds/sfx_spinfication.mp3',
		volume: 1,
	}),
	[SoundNames.WILD_LOCKED]: new Howl({
		src: './sounds/sfx_wild_locked.mp3',
		volume: 1,
	}),
	[SoundNames.WIN]: new Howl({
		src: './sounds/win.mp3',
		volume: 1,
	}),
	[SoundNames.SCATTER_LAND_0]: new Howl({
		src: './sounds/sfx_scatter_land_0.mp3',
		volume: 1,
	}),
	[SoundNames.SCATTER_LAND_1]: new Howl({
		src: './sounds/sfx_scatter_land_1.mp3',
		volume: 1,
	}),
	[SoundNames.SCATTER_LAND_2]: new Howl({
		src: './sounds/sfx_scatter_land_2.mp3',
		volume: 1,
	}),
	[SoundNames.WIN_BOW]: new Howl({
		src: './sounds/win_bow.mp3',
		volume: 1,
	}),
	[SoundNames.WIN_METAL]: new Howl({
		src: './sounds/win_metal.mp3',
		volume: 1,
	}),
};
