import { Game } from "../Game";

type CallbackParameters = {
    anyWin: boolean
    freeSpins: boolean
    singleWin: null | number
    lossLimit: null | number
    refreshLoss: boolean
    startSpinCount?: number | string
}

export default function setAutoParams(this: Game, params: CallbackParameters) {

    if (!this.slotMachine) return
    console.log('auto play params', params)
    this.slotMachine.requestParams = params;
}