import * as PIXI from 'pixi.js'
import { Texture } from 'pixi.js';
import { UI_ASSETS } from '../../assetsLoader/UiAssets';
import { Game } from "../../Game";

export default class Logo {
    game: Game;
    logoDesktop: PIXI.Sprite;
    logoPortrait: PIXI.Sprite;

    constructor(game: Game) {
        this.game = game;
        this.logoDesktop = new PIXI.Sprite(Texture.from(UI_ASSETS.LOGO_DESKTOP))
        this.logoPortrait = new PIXI.Sprite(Texture.from(UI_ASSETS.LOGO_PORTRAIT))
    }

    init = () => {
        this.setupLogoNames();
        this.game.resizeCallbacks.push(this.resize)
        this.game.assetsManager.gameContainer.addChild(this.logoDesktop);
        this.game.assetsManager.gameContainer.addChild(this.logoPortrait);
    }

    setupLogoNames = () => {
        this.logoDesktop.name = "LOGO_DESKTOP";
        this.logoPortrait.name = "LOGO_PORTRAIT";
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.logoDesktop.visible = false;
            this.logoPortrait.visible = true;

            this.logoPortrait.scale.set(1.76);
            this.logoPortrait.x = 499.5;
            this.logoPortrait.y = -54;
        } else {
         
            if (window.innerWidth <= 1080) {
                this.logoPortrait.scale.set(1);
                this.logoPortrait.x = 704;
                this.logoPortrait.y = 7;
                this.logoDesktop.visible = false;
                this.logoPortrait.visible = true;
               
            } else {
                this.logoDesktop.visible = true;
                this.logoPortrait.visible = false;
                this.logoDesktop.x = 100;
            }
        }
    }
}