import {LocalStorage} from "../../utils/localStorage";
import {EventType, getEvent} from "../../GameEventBus";

type CallbackParameters = {
    enabled: boolean
}

export default function setTurbo(params: CallbackParameters) {
    const { enabled } = params;

    LocalStorage.setItem('WrapperTurboSettings', enabled)
    getEvent(EventType.ENABLE_TURBO_MODE).send(enabled);
}