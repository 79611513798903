import {Game} from '../../Game';
import {Spine} from 'pixi-spine';
import {Container, Sprite, Text, Texture} from 'pixi.js';
import {PRELOADER_ASSETS} from '../../assetsLoader/preloader';
import {EventType, getEvent} from '../../../GameEventBus';
import {UI} from '../../../ui';
import {textStyleTool} from "../../utils/textStyleTool";
import gsap from "gsap";

export class Preloader {
    game: Game;
    container: Container;
    background: Sprite;
    logo: Spine;
    progress: number;
    barContainer: Container;
    barBackground: Sprite;
    barProgress: Sprite;
    barMask: Sprite;
    barText: Sprite;
    openText: Container;
    popupBackground: Sprite;
    popupTextFirst: Container;
    popupTextSecond: Container;
    isPopupVisible: boolean;

    constructor(game: Game) {
        this.game = game;
        this.container = new Container();
        const spineData =
            this.game.app.loader.resources[PRELOADER_ASSETS.LOGO].spineData!;
        this.logo = new Spine(spineData);
        this.progress = 0;
        this.background = new Sprite(Texture.from(PRELOADER_ASSETS.BACKGROUND));
        this.barContainer = new Container();
        this.barBackground = new Sprite(
            Texture.from(PRELOADER_ASSETS.PROGRESS_BAR_BG)
        );
        this.barProgress = new Sprite(Texture.from(PRELOADER_ASSETS.PROGRESS_BAR));
        this.barText = new Sprite(Texture.from(PRELOADER_ASSETS.LOADING_TEXT));
        this.barMask = new Sprite(Texture.from(PRELOADER_ASSETS.PROGRESS_BAR_MASK));

        this.openText = textStyleTool.addText('Continue', 75)
        this.popupBackground = new Sprite(
            Texture.from(PRELOADER_ASSETS.POPUP)
        );
        this.isPopupVisible = true;
        this.popupTextFirst = textStyleTool.addText('Create powerful boost combos', 85)
        this.popupTextSecond = textStyleTool.addText('In respin and free spins', 85)
    }

    init = async () => {
        this.setupProgressBar();
        this.setupText();
        this.setupPopup();
        this.container.width = 1920;
        this.container.height = 1920;
        this.logo.position.set(window.innerWidth / 2, window.innerHeight / 2);
        this.logo.state.setAnimation(0, '2_wild_in_chest_anim', true);

        this.container.sortableChildren = true;
        this.container.zIndex = 2000;

        this.container
            .on('touchstart', () => this.hide())
            .on('click', () => this.hide());

        this.game.app.stage.addChild(this.container);
        this.container.addChild(this.background, this.barContainer);
        this.barContainer.addChild(this.popupBackground)
        this.game.resizeCallbacks.push(this.resize);

        const showPopup = setInterval(() => {
            const wiggleTween = gsap.timeline();

            if (this.isPopupVisible) {
                this.isPopupVisible = false;
                wiggleTween.fromTo(
                    this.popupBackground,
                    { alpha: 1 },
                    { alpha: 0, repeat: 0, duration: 0.3 }
                );
            } else {
                this.isPopupVisible = true;
                wiggleTween.fromTo(
                    this.popupBackground,
                    { alpha: 0 },
                    { alpha: 1, repeat: 0, duration: 0.3 }
                );
            }
        }, 2000)
    };

    setupProgressBar = () => {
        this.barContainer.sortableChildren = true;
        this.barContainer.zIndex = 1;
        this.barText.zIndex = 2;
        this.barProgress.alpha = 0;
        this.barMask.zIndex = 0;

        this.background.anchor.set(0.5, 0.5);
        this.barBackground.anchor.set(0.5, 0.5);
        this.barProgress.anchor.set(0.5, 0.5);
        this.barMask.anchor.set(0.5, 0.5);
        this.barText.anchor.set(0.5, 0.5);

        this.barProgress.width = this.barBackground.width;
        this.barProgress.mask = this.barMask;

        this.barContainer.addChild(this.barBackground, this.logo);
        this.barBackground.addChild(this.barMask, this.barProgress, this.barText);

        this.background.position.set(window.innerWidth / 2, window.innerHeight / 2);

        this.barContainer.position.set(
            window.innerWidth / 2,
            window.innerHeight / 2
        );

        this.game.resizeCallbacks.push(this.resize);
    };

    hide = async () => {
        getEvent(EventType.SHOW_LOADING_SCREEN).send(false);
        if (this.game.initData && this.game.initData.nextCommands[0] === 'freegame') {
            this.game.slotMachine?.minigamePreparing(this.game.initData)
        }
        UI.display();
        this.container.visible = false;
        this.logo.state.setAnimation(0, '2_wild_in_chest_anim', false);
    };

    watchProgress = (value: number) => {
        this.progress = value;
        this.barProgress.width = this.barBackground.width * (value / 100);
        this.barProgress.x =
            -this.barBackground.width / 2 +
            (this.barBackground.width / 2) * (value / 100);
        this.barProgress.alpha = 1;
        if (value > 99.9) {
            this.game.app.stage.interactiveChildren = true;

            this.container.interactive = true;
            this.container.buttonMode = true;
            this.barBackground.visible = false;
            this.openTextVisible();
        } else {
            this.game.app.stage.interactiveChildren = false;
        }
    };

    setupText = () => {
        this.openText.position.set(-110, 380);
        this.openText.visible = false;
        this.barContainer.addChild(this.openText);
    };

    setupPopup = () => {
        this.popupBackground.zIndex = 200;
        this.popupBackground.scale.set(1)
        this.popupBackground.anchor.set(0.5)
        this.popupBackground.position.set(0, 50)

        const maxScaleFirstText = (this.popupBackground.width * 0.8) / this.popupTextFirst.width;
        const maxScaleSecondText = (this.popupBackground.width * 0.8) / this.popupTextSecond.width;
        const adaptiveScaleMultiplier = Math.min(maxScaleFirstText, maxScaleSecondText);

        this.popupTextFirst.scale.set(1.1 * adaptiveScaleMultiplier, 1.65 * adaptiveScaleMultiplier);
        this.popupTextSecond.scale.set(1.2 * adaptiveScaleMultiplier, 1.55 * adaptiveScaleMultiplier);

        this.popupTextFirst.position.set(-this.popupTextFirst.width / 2, -this.popupTextFirst.height)
        this.popupTextSecond.position.set(-this.popupTextSecond.width / 2, this.popupTextFirst.height / 5)
        this.popupBackground.addChild(this.popupTextFirst, this.popupTextSecond)
        this.popupBackground.visible = true
    }

    openTextVisible = () => {
        this.openText.visible = true;
    };

    resize = () => {
        if (!this.logo) return;

        if (this.progress > 99.9) this.openTextVisible();

        this.container.position.set(0, 0);

        this.barContainer.position.set(
            window.innerWidth / 2,
            window.innerHeight / 2
        );

        this.background.position.set(window.innerWidth / 2, window.innerHeight / 2);
        this.logo.position.set(0, 0 - this.barBackground.height / 2);
        this.barBackground.position.set(0, 405);

        this.adaptive(this.background);
        this.adaptive(this.barContainer);
    };

    adaptive = (name: any) => {
        if (!name) return;

        const baseRatio = 1920 / 1080;
        const currentRatio = window.innerWidth / window.innerHeight;
        let scaleByHeight = ((100 / 1080) * window.innerHeight) / 100;

        if (baseRatio <= currentRatio) {
            name.scale.set(window.innerWidth / 1920, window.innerWidth / 1920);
            if (name === this.barContainer)
                name.scale.set(scaleByHeight, scaleByHeight);
        } else {
            if (window.innerHeight / 1920 <= baseRatio) {
                if (window.innerWidth > window.innerHeight) {
                    name.scale.set(window.innerWidth / 1920, window.innerWidth / 1920);
                } else {
                    name.scale.set(window.innerHeight / 1920, window.innerHeight / 1920);
                }
            } else {
                name.scale.set(window.innerHeight / 1920, window.innerHeight / 1920);
            }
        }
    };
}
