import * as PIXI from "pixi.js"
import { Texture } from "pixi.js";
import { MINIGAME_ASSETS } from "../assetsLoader/miniGameLoader";
import { EventType, getEvent } from "../../GameEventBus";
import MiniGame from "./miniGame";
import { Sounds } from './SoundController';
import { SoundNames } from '../constants/sounds';
import { Timer } from '../../utils/Timer';
import { Spine } from 'pixi-spine';
import { Game } from '../Game';

class Chest {
    container: PIXI.Container;
    chestContainer: PIXI.Sprite;
    featureName: string;
    status: boolean;
    minigame: MiniGame
    game: Game;
    chest: Spine;
    chestIndex: number;

    constructor(minigame: MiniGame, game: Game, chestIndex: number) {
        this.chestIndex = chestIndex;
        this.game = game;
        this.container = new PIXI.Container();
        this.chestContainer = new PIXI.Sprite(Texture.EMPTY);
        this.chest = new Spine(this.game.app.loader.resources[MINIGAME_ASSETS.CHEST_ANIM].spineData!)
        this.featureName = 'Feature name';
        this.minigame = minigame
        this.status = false;
    }

    init = () => {
        this.setupChest();
        this.setupDefaultGraphics();
    }

    setupChest = () => {
        this.container.width = 200;
        this.container.height = 200;
        this.container.interactive = true;
        this.container.name = 'CHEST';
        this.container.cursor = 'pointer';
        this.container.on('pointerdown', this.open);
    }

    setupOpenedChest = (name: any, value: any, index: number) => {
        getEvent(EventType.OPEN_CHEST).send(true);

        this.container.interactive = false;
        this.container.cursor = 'default';
        this.setupOpenGraphics();
        const timer = new Timer(() => {
            this.game.assetsManager.miniGame?.featureAnimContainer.animate(name, value, index, this.chestIndex);
        }, 750);
        timer.initialize();
    }

    setupDefaultGraphics = () => {
        const delay = this.getRandomDelay(5, 9);
        this.container.addChild(this.chestContainer);
        this.chestContainer.addChild(this.chest);
        const timer = new Timer(() => {
            this.chest.state.setAnimation(0, 'win_anim', false);
        }, delay)
        timer.initialize();
    }

    setupOpenGraphics = () => {
        this.chest.state.setAnimation(0, 'opening_anim', false);
    }

    open = () => {
        return new Promise(resolve => {
            if (this.status) return resolve(true);
            this.minigame.baefBtn.setBtnVisible(false);
            this.game.assetsManager.miniGame?.setChestsInteractive(false);
            this.chestContainer.interactive = false;
            Sounds.play(SoundNames.BONUS_WIN);
            getEvent(EventType.CHEST_INTERACTION).send(true);
            if (!this.status) {
                let timer = new Timer(() => {
                    const featuresCount = this.minigame.receivedFeatures.length;
                    const indexOfFeature = Math.floor(Math.random() * featuresCount);
                    const randomFeature = this.minigame.receivedFeatures[indexOfFeature];
                    this.minigame.receivedFeatures.splice(indexOfFeature, 1);
                    this.minigame.features.push(randomFeature);
                    this.status = true;
                    this.setupOpenedChest(randomFeature.name, randomFeature.value, Number(this.game.assetsManager.miniGame?.featureBar.features.length));
                    const featureTimer = new Timer(() => {
                        return resolve(true);
                    }, 1250);
                    featureTimer.initialize();
                }, 500);
                timer.initialize();
            }
        });
    };

    getRandomDelay = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        const result = Math.floor(Math.random() * (max - min + 1)) + min
        return result * 250;
    }
}

export { Chest }
