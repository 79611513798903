export const respinFeatureStartCoords = [
	[
		{ x: 10, y: -40 },
		{ x: 10, y: 190 },
		{ x: 10, y: 420 },
		{ x: 10, y: 640 },
	],
	[
		{ x: 240, y: -40 },
		{ x: 240, y: 190 },
		{ x: 240, y: 420 },
		{ x: 240, y: 640 },
	],
	[
		{ x: 470, y: -40 },
		{ x: 470, y: 190 },
		{ x: 470, y: 420 },
		{ x: 470, y: 640 },
	],
	[
		{ x: 690, y: -40 },
		{ x: 690, y: 190 },
		{ x: 690, y: 420 },
		{ x: 690, y: 640 },
	],
	[
		{ x: 900, y: -40 },
		{ x: 900, y: 190 },
		{ x: 900, y: 420 },
		{ x: 900, y: 640 },
	],
];

export const moveToFeatureBarDesktop = [
	{ x: -295, y: 670 }, // 1
	{ x: -295, y: 540 }, // 2
	{ x: -295, y: 410 }, // 3
	{ x: -295, y: 200 }, // 4
	{ x: -295, y: 80 }, // 5
];

export const moveToFeatureBarLandscape = [
	{ x: -380, y: 400 }, // 1
	{ x: -380, y: 300 }, // 2
	{ x: -380, y: 200 }, // 3
	{ x: -380, y: 100 }, // 4
	{ x: -380, y: 0 }, // 5
];

export const moveToFeatureBarPortrait = [
	{ x: 100, y: 880 }, // 1
	{ x: 290, y: 880 }, // 2
	{ x: 470, y: 880 }, // 3
	{ x: 650, y: 880 }, // 4
	{ x: 830, y: 880 }, // 5
];
