import styled from 'styled-components';
import DecisionButton from './components/DecisionButton';
import CloseWindowButton from './components/CloseWindowButton';
import { EventType, useEventReceiver } from '../../../GameEventBus';
import { useCallback, useState } from 'react';
import { Game } from '../../../game/Game';
import { api } from '../../../App';
import { SoundNames } from '../../../game/constants/sounds';
import { Sounds } from '../../../game/classes/SoundController';
import iconClose from '../../../assets/icons/close-icon.svg';
import iconCloseHover from '../../../assets/icons/close-icon-hover.svg';
import { WhoAmI } from '../../../api/types';

interface IGame {
	game: Game;
	isAssetsLoaded: boolean;
}

const ConfirmationModal = ({ game, isAssetsLoaded }: IGame) => {
	const [isModalActive, setModalActive] = useState(false);
	const [price, setPrice] = useState(0);
	const [currency, setCurrency] = useState("USD");
	const [bet, setCurrentBet] = useState(0);
	const [isLoading, setLoading] = useState(false);

	const changeModalState = (state: boolean) => {
		Sounds.play(SoundNames.BTN_UI);
		setModalActive(state);
	};

	useEventReceiver<any>(EventType.OPEN_BUY_EXTRA_FEATURE_MODAL, value => {
		const { bet, featurePrice } = value;
		const buyPrice = featurePrice ? featurePrice : game.initData.results![0].clientData.buyExtraFeatureCost;
		setPrice(buyPrice);
		setCurrentBet(bet);
		setModalActive(value);
	});

	useEventReceiver<WhoAmI>(EventType.INIT_DATA, value => {
		const currentCurrency = value.account.currency ? value.account.currency : game.initData.account.currency;
		setCurrency(currentCurrency)
	});

	const handlePurchase = useCallback(async () => {
		Sounds.play(SoundNames.BTN_UI);
		setModalActive(false);
		setLoading(true);
		game.assetsManager.miniGame?.baefBtn?.buyExtraFeature();
		game.assetsManager.miniGame?.checkFeatureBar();
		game.slotMachine?.changeBalance(-price);
		const debugRequest = {
			bet: bet,
			command: 'buyextrafeature',
		};
		const data = game.isDebug ? await api.auth.playDebug(debugRequest) : await api.auth.play(bet, 'buyextrafeature');
		if (!data) return;
		game.slotMachine!.freeSpinCount = data.results[0].clientData.freeSpin.added;
		game.assetsManager.miniGame?.setFeaturePrice(data.results[0].clientData.buyExtraFeatureCost);
		game.assetsManager.miniGame?.replaceFeatures(
			data?.results[0].clientData.featuresDetails!
		);
		setModalActive(false);
		setLoading(false);
	}, [setModalActive, game, bet, price]);

	return isAssetsLoaded ? (
		<Wrapper style={{ visibility: isModalActive ? 'visible' : 'hidden' }}>
			<Container>
				<CloseButton onClick={() => changeModalState(false)}>
					<CloseButtonBorder>
						<CloseButtonBody className='nav-btn-hover'>
							<img src={iconClose} alt='Settings' draggable={false} />
							<img src={iconCloseHover} alt='Settings' draggable={false} />
						</CloseButtonBody>
					</CloseButtonBorder>
				</CloseButton>

				<HeaderContainer>
					<Header>Confirmation</Header>

					<Offer>Do you want to buy an extra feature for {price} {currency} ?</Offer>
				</HeaderContainer>

				{!isLoading && (
					<ButtonContainer>
						<ButtonCol onClick={handlePurchase}>
							<DecisionButton>Yes</DecisionButton>
						</ButtonCol>

						<ButtonCol onClick={() => setModalActive(!isModalActive)}>
							<DecisionButton>No</DecisionButton>
						</ButtonCol>
					</ButtonContainer>
				)}
				{isLoading && (
					<OfferContainer style={{ paddingTop: '40px' }}>
						<Offer>Loading...</Offer>
					</OfferContainer>
				)}
			</Container>
		</Wrapper>
	) : null;
};

export default ConfirmationModal;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(21, 21, 21, 0.8);
	left: 0;
	top: 0;
	padding: 50px 20px 15px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
    align-items: center;
	z-index: 300;
	margin: auto;
	position: relative;
	background: #151515;
	border-radius: 10px;
	border: 1px solid #2a2c2b;
	padding: 30px 15px;
	color: #fff;
	max-width: 809px;
	max-height: 439px;
	width: 100%;
	height: 100%;
	min-height: 200px;
`;
const Header = styled.h1`
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	text-align: center;
	color: #e2f4f2;
    padding-bottom: 30px;
`;
const Offer = styled.h3`
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	color: #e2f4f2;
`;

const ButtonContainer = styled.div`
	width: 100%;
    max-width: 380px;
	display: flex;
    justify-content: space-between;
	align-items: center;
	@media (max-width: 360px) {
		width: auto;
        scale: 0.8;
    }  
`;

const ButtonCol = styled.div``;

const HeaderContainer = styled.div`
	text-align: center;
`;

const OfferContainer = styled.div`
	text-align: center;
`;

const CloseButton = styled.div`
	right: -14px;
	top: -14px;
	position: absolute;
	border-radius: 50%;
	height: 60px;
	width: 60px;
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	img {
		height: 21px;
		width: 21px;
		display: inline-block;
	}
	img:last-child {
		display: none;
	}
	&:hover {
		.nav-btn-hover {
			background: #e2f4f2;
			border: 2px solid rgba(21, 21, 21, 0.8);
			img {
				display: none;
			}
			img:last-child {
				transition: all ease 0.5s;
				display: inline-block;
			}
		}
	}
`;

const CloseButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	width: 48px;
	border-radius: 50%;
	background: #151515;
`;

const CloseButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 52px;
	width: 52px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;
