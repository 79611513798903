import { Sprite, Container } from 'pixi.js';
import { Texture } from 'pixi.js';
import { Game } from '../../Game';
import { MODAL_ASSETS } from '../../assetsLoader/modalLoader';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import gsap from 'gsap';
import { respinStartCoords } from './coords';
import { calculateArcPoints } from '../../utils/calculateArcPoints';
import { Timer } from '../../../utils/Timer';

gsap.registerPlugin(MotionPathPlugin);
class RespinAnimation {
	game: Game;
	container: Container;
	feature: Sprite;
	width: number;
	height: number;
	startX!: number;
	startY!: number;

	constructor(game: Game) {
		this.game = game;
		this.container = new Container();
		this.feature = new Sprite(Texture.from(MODAL_ASSETS.RESPIN));
		this.width = 933;
		this.height = 287;

		this.init();
	}

	async init() {
		await this.setupContainer();
		this.container.zIndex = 10000;
		this.feature.zIndex = 10000;
	}

	setupContainer = () => {
		this.feature.name = 'ANIMATED_FEATURE';
		this.container.width = 1152;
		this.container.height = 926;
		this.container.x = 95;
		this.container.y = 320;
		this.container.visible = false;
		this.container.addChild(this.feature);
	};

	showRespin = () => {
		this.container.visible = false;
		this.game.assetsManager.respinModal?.showModal();
	};

	setupPosition = (startX: number, startY: number) => {
		this.startX = startX;
		this.startY = startY;
		this.game.assetsManager.gameField?.respinFeature?.setupPosition(
			startX,
			startY
		);
	};

	animate = async () => {
		return new Promise(resolve => {
			if (!this.startX && !this.startY) return resolve(true);
			this.container.addChild(this.feature);
			this.feature.x = respinStartCoords[this.startX][this.startY].x;
			this.feature.y = respinStartCoords[this.startX][this.startY].y;
			
			const timeline = gsap.timeline();
			const timeScale = gsap.timeline();
			
			let startPoint = respinStartCoords[this.startX][this.startY];
			this.feature.position.set(startPoint.x, startPoint.y);
			let endPoint = { x: 0, y: 0 };
			
			let arcPoints = calculateArcPoints(startPoint, endPoint);
			
			const startTimer = new Timer(() => {
				this.container.visible = true;
				timeline.to(this.feature, 0.5, {
					ease: 'power1.inOut',
					motionPath: {
						path: arcPoints,
					},
					repeat: 0,
				});
	
				timeScale
					.fromTo(
						this.feature,
						0.5,
						{
							width: this.width * 0.1,
							height: this.height * 0.1,
						},
						{
							width: this.width,
							height: this.height,
						}
					)
					.then(() => this.showRespin());
				const timer = new Timer(() => {
					return resolve(true);
				}, 1500);
				timer.initialize();
			}, 200)
			startTimer.initialize();
		});
	};

	resize = () => {};
}

export default RespinAnimation;
