import * as PIXI from "pixi.js";
import { Texture } from "pixi.js";
import { Game } from "../../Game";
import { MODAL_ASSETS } from "../../assetsLoader/modalLoader";
import { Timer } from "../../../utils/Timer";


class RespinModal {
    game: Game;
    container: PIXI.Container;
    modal: PIXI.Container;
    respin: PIXI.Sprite;
    overlay: PIXI.Graphics;

    constructor(game: Game) {
        this.game = game;
        this.container = new PIXI.Container();
        this.modal = new PIXI.Container();
        this.overlay = new PIXI.Graphics();
        this.respin = new PIXI.Sprite(Texture.from(MODAL_ASSETS.RESPIN));
    }

    init = () => {
        this.setupContainer();
        this.setupOverlay();
        this.setupModal();

        this.game.app.stage.addChild(this.overlay);
        this.game.app.stage.addChild(this.container);

        this.game.resizeCallbacks.push(this.resize)
    }

    setupContainer = () => {
        this.container.width = 1920;
        this.container.height = 1080;
        this.container.pivot.set(960, 540);
        this.container.name = "RESPIN"
        this.container.visible = false;
    }

    setupOverlay = () => {
        this.overlay.beginFill(0x151515);
        this.overlay.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.overlay.endFill();
        this.overlay.alpha = 0.5;
        this.overlay.visible = false;
    }

    setupModal = () => {
        this.modal.width = this.respin.width;
        this.modal.height = this.respin.height;
        this.respin.name = "RESPIN_SPITE"
        this.modal.addChild(this.respin);
        this.container.addChild(this.modal);
    }

    showModal = () => {
        return new Promise((resolve) => {
            this.overlay.visible = true;
            this.container.visible = true;

            let timer = new Timer(() => {
                this.overlay.visible = false;
                this.container.visible = false;
                return resolve(true)
            }, 1000);
            timer.initialize();
        })

    }

    resize = () => {
        if (!this.container || !this.game.assetsManager.gameField?.container) return

        this.container.width = window.innerWidth;
        this.container.height = window.innerHeight;
        this.overlay.width = window.innerWidth;
        this.overlay.height = window.innerHeight;
        this.container.scale.x = window.innerWidth / 1920;
        this.container.scale.y = window.innerWidth / 1920;

        this.container.x = window.innerWidth / 2;
        this.container.y = window.innerHeight / 1920;


        if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
            this.container.scale.x = window.innerHeight / 1080 - 0.012;
            this.container.scale.y = window.innerHeight / 1080 - 0.012;
        }

        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.modal.scale.set(1.7595);
            this.modal.x = 129;
            this.modal.y = 1224;

        } else if (window.innerWidth <= 1800) {
            this.modal.scale.set(1.035)
            this.modal.x = 960  - this.respin.width / 2;
            this.modal.y = 903;

        } else {
            this.modal.scale.set(1.005);
            this.modal.x = 960 - this.respin.width / 2;
            this.modal.y = 860;
        }

    }

}

export default RespinModal;
