import { Sprite, Container } from 'pixi.js';
import { Texture } from 'pixi.js';
import { Game } from '../../Game';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import gsap from 'gsap';
import { moveToFeatureBarDesktop, moveToFeatureBarLandscape, moveToFeatureBarPortrait, respinFeatureStartCoords } from './coords';
import { calculateArcPoints } from '../../utils/calculateArcPoints';
import { MINIGAME_ASSETS } from '../../assetsLoader/miniGameLoader';
import { Spine } from 'pixi-spine';
import { Sounds } from '../../classes/SoundController';
import { SoundNames } from '../../constants/sounds';

gsap.registerPlugin(MotionPathPlugin);

class RespinFeatureAnimation {
	game: Game;
	container: Container;
	width: number;
	height: number;
	startX!: number;
	startY!: number;
	index!: number;
	featureContainer: Sprite;
	feature: Spine;
    featureIcons: any;
    featureTextures: Map<string, Texture>;
	currentResolution: "desktop" | "landscape" | "portrait";

	constructor(game: Game) {
		this.game = game;
		this.container = new Container();
		this.featureContainer = new Sprite(Texture.EMPTY);
		this.width = 678;
		this.height = 752;
		const featureData = this.game.app.loader.resources[MINIGAME_ASSETS.SHINE_ANIMATION].spineData!
        this.feature = new Spine(featureData);
        this.featureIcons = this.game.app.loader.resources.SHINE_ICONS.spritesheet?.textures;
        this.featureTextures = new Map();
		this.currentResolution = 'desktop';
		this.init();
		this.game.resizeCallbacks.push(this.resize)
	}

	async init() {
		this.setupContainer();
        this.setupShineFeatures();
		this.container.zIndex = 10000;
	}

	setupContainer = async () => {
		this.container.name = 'ANIMATED_RESPIN_FEATURE';
		this.container.width = 1152;
		this.container.height = 926;
		this.container.x = 95;
		this.container.y = 320;
		this.container.visible = false;
		this.featureContainer.sortableChildren = true;
		this.feature.name = 'SHINE';
		this.feature.zIndex = -1;
		this.feature.state.setAnimation(0, "Shine_idle", true);
		this.container.addChild(this.featureContainer);
		this.featureContainer.addChild(this.feature);
	};

    setupShineFeatures = async () => {
        this.featureTextures.set("ExtraFreespins1", this.featureIcons['extra_fs_s.png']);
        this.featureTextures.set("ExtraFreespins2", this.featureIcons['extra_fs2_s.png']);
        this.featureTextures.set("ExtraFreespins3", this.featureIcons['extra_fs3_s.png']);
        this.featureTextures.set("SymbolWildH1", this.featureIcons['h1_to_wild_s.png']);
        this.featureTextures.set("SymbolWildH2", this.featureIcons['h2_to_wild_s.png']);
        this.featureTextures.set("SymbolWildH3", this.featureIcons['h3_to_wild_s.png']);
        this.featureTextures.set("SymbolWildH4", this.featureIcons['h4_to_wild_s.png']);
        this.featureTextures.set("SymbolWildL1", this.featureIcons['l1_to_wild_s.png']);
        this.featureTextures.set("SymbolWildL2", this.featureIcons['l2_to_wild_s.png']);
        this.featureTextures.set("SymbolWildL3", this.featureIcons['l3_to_wild_s.png']);
        this.featureTextures.set("SymbolWildL4", this.featureIcons['l4_to_wild_s.png']);
        this.featureTextures.set("SymbolRemoveL1", this.featureIcons['l1_removed_s.png']);
        this.featureTextures.set("SymbolRemoveL2", this.featureIcons['l2_removed_s.png']);
        this.featureTextures.set("SymbolRemoveL3", this.featureIcons['l3_removed_s.png']);
        this.featureTextures.set("SymbolRemoveL4", this.featureIcons['l4_removed_s.png']);
        this.featureTextures.set("MultiplierIncrease2", this.featureIcons['mult_x2_s.png']);
        this.featureTextures.set("MultiplierIncrease3", this.featureIcons['mult_x3_s.png']);
        this.featureTextures.set("MultiplierIncrease5", this.featureIcons['mult_x5_s.png']);
        this.featureTextures.set("IncreaseMultipliertrue", this.featureIcons['progressive_mult_s.png']);
        this.featureTextures.set("StickyWildtrue", this.featureIcons['sticky_wild_s.png']);
    }

	moveToFeatureBar = async () => {
		const timeline = gsap.timeline();
		var startPoint = { x: 470, y: 180 };
		var endPoint =
		this.currentResolution === 'desktop'
		? moveToFeatureBarDesktop
		: this.currentResolution === 'landscape'
				? moveToFeatureBarLandscape
				: moveToFeatureBarPortrait;

		await this.setVisible(true);
		var arcPoints = calculateArcPoints(startPoint, endPoint[this.index]);
		
		await timeline.to(this.featureContainer, 0.5, {
			ease: 'power1.inOut',
			motionPath: {
				path: arcPoints,
			},
			repeat: 0,
		})
		this.reset();
	}

	setupPosition = (startX: number, startY: number) => {
		this.startX = startX;
		this.startY = startY;
	};

	animate = async (name: string, value: number | boolean, index: number) => {
		return new Promise(async (resolve) => {
			this.featureContainer.removeChildren();
			await this.setVisible(true);
			if (!this.startX && !this.startY) return;
			const featureName = [name, value, index]
			const feature = new Sprite(this.featureTextures.get(`${featureName[0]}${featureName[1]}`))
			this.featureContainer.addChild(feature);
			this.featureContainer.addChild(this.feature);
			
			this.index = index;

			feature.anchor.set(0.5);
			feature.width = this.width;
			feature.height = this.height;
			
			this.featureContainer.x = respinFeatureStartCoords[this.startX][this.startY].x;
			this.featureContainer.y = respinFeatureStartCoords[this.startX][this.startY].y;

			var startPoint = respinFeatureStartCoords[this.startX][this.startY];
			this.featureContainer.position.set(startPoint.x, startPoint.y);
			feature.position.set(0, -120);
			var endPoint = { x: 470, y: 180 };
			
			var arcPoints = calculateArcPoints(startPoint, endPoint);
			
			Sounds.play(SoundNames.FEATURE_PICKS);
			await this.moveToCenterAnim(arcPoints, feature);
			await this.setVisible(false);
			await this.game.assetsManager.winFeatureModal?.showFeature(name, value, index, 'respin');
			await this.game.assetsManager.gameField?.respinFeature?.moveToFeatureBar();
			return resolve(true);
		})
	};

	moveToCenterAnim = async (arcPoints: any, feature: Sprite) => {
		const timeline = gsap.timeline();
		const timeScale = gsap.timeline();
		const speed = this.index === 0 ? 0.7 : 0.5;

		timeline.to(this.featureContainer, speed, {
			ease: 'power1.inOut',
			motionPath: {
				path: arcPoints,
			},
			repeat: 0,
		});

		await timeScale.fromTo(
			feature,
			speed,
			{
				width: this.width * 0.1,
				height: this.height * 0.1,
			},
			{
				width: this.width,
				height: this.height,
			}
		)
	}

	setVisible = async (state: boolean) => {
		this.container.visible = state;
	}

	reset = () => {
		this.featureContainer.removeChildren();
		this.container.visible = false;
	}

	resize = () => {
		if (window.innerWidth / window.innerHeight <= 0.76) {
            this.currentResolution = 'portrait';
        } else {
            if (window.innerWidth <= 1080) {
                this.currentResolution = 'landscape';
            } else {
				this.currentResolution = 'desktop';
            }
        }
	};
}

export default RespinFeatureAnimation;
