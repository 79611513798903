import { EventType, getEvent } from "../../GameEventBus";
import { Game } from "../Game";
import { Sounds } from "../classes/SoundController";
import { SoundNames } from "../constants/sounds";

type CallbackParameters = {
    steps: number[];
    value: number;
}

export default function changeBet(this: Game, params: CallbackParameters) {
    const { steps, value } = params

    Sounds.play(SoundNames.BTN_UI);

    if (!this.slotMachine) return

    const betIndex = steps.indexOf(value);
    getEvent(EventType.CHOSEN_BET).send(betIndex)

    this.slotMachine.bet = value;
}