import { Container, Graphics, Sprite, Texture } from 'pixi.js';
import { Game } from '../../Game';
import { MODAL_ASSETS } from '../../assetsLoader/modalLoader';
import { BUTTONS_ASSETS } from '../../assetsLoader/ButtonsLoader';
import { SoundNames } from '../../constants/sounds';
import { Sounds } from '../../classes/SoundController';

class MaxWinModal {
	game: Game;
	container: Container;
	overlay: Graphics;
	modalContainer: Container;
	textContainer: Container;
	bg: Sprite;
	btn: Sprite;
	text: Sprite;
	isModalActive: boolean;

	constructor(game: Game) {
		this.game = game;
		this.container = new Container();
		this.overlay = new Graphics();
		this.modalContainer = new Container();
		this.textContainer = new Container();

		this.bg = new Sprite(Texture.from(MODAL_ASSETS.MODAL_BACKGROUND));
		this.btn = new Sprite(Texture.from(BUTTONS_ASSETS.OK_BTN));
		this.text = new Sprite(Texture.from(MODAL_ASSETS.MAX_WIN_CAP_TEXT));
		this.isModalActive = false;
	}

	init = async () => {
		this.setupContainer();
		this.setupOverlay();
		this.setupFSSContainer();
		this.setupBG();
		this.setupText();
		this.setupBtn();

		this.game.app.stage.addChild(this.overlay);
		this.game.app.stage.addChild(this.container);

        this.closeModal();
		this.game.resizeCallbacks.push(this.resize)
	};

	setupContainer = () => {
		this.container.width = 1920;
		this.container.height = 1080;
		this.container.pivot.set(960, 540);
		this.container.name = 'MAX_WIN';
	};

	setupOverlay = () => {
		this.overlay.beginFill(0x151515);
		this.overlay.drawRect(0, 0, window.innerWidth, window.innerHeight);
		this.overlay.endFill();
		this.overlay.alpha = 0.8;
	};

	setupFSSContainer = () => {
		this.modalContainer.width = 970;
		this.modalContainer.height = 674;
		this.modalContainer.pivot.set(485, 337);
		this.modalContainer.x = 960;
		this.modalContainer.y = 540;
		this.modalContainer.zIndex = 1000;
		this.modalContainer.name = 'MAX_WIN_ACHIEVED_MODAL';
		this.container.addChild(this.modalContainer);
	};

	setupBG = () => {
		this.modalContainer.addChild(this.bg);
	};

	setupText = () => {
		this.text.scale.set(0.5);
		this.text.x = 485 - this.text.width / 2;
		this.text.y = 250;
		this.modalContainer.addChild(this.text);
	};

	setupBtn = () => {
		this.btn.x = 485 - this.btn.width / 2 + 5;
		this.btn.y = 525;
		this.btn.interactive = true;
		this.btn.cursor = 'pointer';
		this.btn.on('click', this.doOnClick);
		this.btn.on('touchstart', this.doOnClick);
		this.modalContainer.addChild(this.btn);
	};

	doOnClick = () => {
		if (!this.game.slotMachine) return;
		Sounds.play(SoundNames.BTN_UI);
        this.closeModal();
		this.game.assetsManager.gameContainer.interactiveChildren = true;
		this.game.slotMachine.isFsRunning = false;
        this.game.assetsManager?.fss?.showModal(true, this.game.slotMachine.maxWinCap);
        this.game.slotMachine.stopFs();
	};

	openModal = () => {
		this.overlay.visible = true;
		this.container.visible = true;
		this.game.assetsManager.gameContainer.interactiveChildren = false;
		this.isModalActive = true;
	};

	closeModal = () => {
		this.isModalActive = false;
		this.container.visible = false;
		this.overlay.visible = false;
	};

	resize = () => {
		if (!this.container || !this.game.assetsManager.gameField?.container)
			return;

		this.container.width = window.innerWidth;
		this.container.height = window.innerHeight;
		this.overlay.width = window.innerWidth;
		this.overlay.height = window.innerHeight;
		this.container.scale.x = window.innerWidth / 1920;
		this.container.scale.y = window.innerWidth / 1920;

		this.container.x = window.innerWidth / 2;
		this.container.y = window.innerHeight / 1920;

		if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
			this.container.scale.x = window.innerHeight / 1080 - 0.012;
			this.container.scale.y = window.innerHeight / 1080 - 0.012;
		}

		if (window.innerWidth / window.innerHeight <= 0.76) {
			this.modalContainer.scale.set(1.7595);
			this.modalContainer.y = 1418;
		} else if (window.innerWidth <= 1800) {
			this.modalContainer.scale.set(1.035);
			this.modalContainer.y = 1057;
		} else {
			this.modalContainer.scale.set(1.005);
			this.modalContainer.y = 1040;
		}
	};
}

export default MaxWinModal;
