import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application, Texture } from 'pixi.js';
import { Game } from '../../Game';
import { BUTTONS_ASSETS } from "../../assetsLoader/ButtonsLoader";
import { Sounds } from '../../classes/SoundController';
import { SoundNames } from '../../constants/sounds';

class CloseBtn {

	game: Game;
	button: PIXI.Container;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	buttonDefault: PIXI.Sprite;
	buttonHover: PIXI.Sprite;
	buttonDisabled: PIXI.Sprite;

	constructor(game: Game) {
		this.game = game;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.buttonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CLOSE_BTN_DEFAULT))
		this.buttonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CLOSE_BTN_HOVER))
		this.buttonDisabled = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CLOSE_BTN_DISABLED))
		this.button = new PIXI.Container();
	}

	init = () => {
		this.button.interactive = true;
		this.button.buttonMode = true;

		this.buttonHover.alpha = 0;
		this.buttonDisabled.alpha = 0;

		this.button.addChild(this.buttonDefault!);
		this.button.addChild(this.buttonHover!);
		this.button.addChild(this.buttonDisabled!);

		this.button
			.on('pointerup', () => this.doPointerUp())
			.on('pointerupoutside', () => this.doPointerUpOutside())
			.on('touchstart', () => this.doPointerDown())
			.on('click', (e) => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver())
			.on('pointerout', () => this.doPointerOut());
	};

	leftClick = (e:any) => {
		if(e.data.button === 0){
			this.doPointerDown()
		};
	}

	doPointerUp() {
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonHover,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		}
		this.pointerIsDown = false;
	}

	doPointerUpOutside() {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown() {
		Sounds.play(SoundNames.BTN_UI);
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = true;
	}

	doPointerOver() {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		
		this.pointerIsOver = true;
	}

	doPointerOut() {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0.3,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}
}

export default CloseBtn;