import {Loader} from "pixi.js";
import slide_018 from "../assets/anticipator_sequence/Anticipator_00018.png"
import slide_019 from "../assets/anticipator_sequence/Anticipator_00019.png"
import slide_020 from "../assets/anticipator_sequence/Anticipator_00020.png"
import slide_021 from "../assets/anticipator_sequence/Anticipator_00021.png"
import slide_022 from "../assets/anticipator_sequence/Anticipator_00022.png"
import slide_023 from "../assets/anticipator_sequence/Anticipator_00023.png"
import slide_024 from "../assets/anticipator_sequence/Anticipator_00024.png"
import slide_025 from "../assets/anticipator_sequence/Anticipator_00025.png"
import slide_026 from "../assets/anticipator_sequence/Anticipator_00026.png"
import slide_027 from "../assets/anticipator_sequence/Anticipator_00027.png"
import slide_028 from "../assets/anticipator_sequence/Anticipator_00028.png"
import slide_029 from "../assets/anticipator_sequence/Anticipator_00029.png"
import slide_030 from "../assets/anticipator_sequence/Anticipator_00030.png"
import slide_031 from "../assets/anticipator_sequence/Anticipator_00031.png"
import slide_032 from "../assets/anticipator_sequence/Anticipator_00032.png"
import slide_033 from "../assets/anticipator_sequence/Anticipator_00033.png"
import slide_034 from "../assets/anticipator_sequence/Anticipator_00034.png"
import slide_035 from "../assets/anticipator_sequence/Anticipator_00035.png"
import slide_036 from "../assets/anticipator_sequence/Anticipator_00036.png"
import slide_037 from "../assets/anticipator_sequence/Anticipator_00037.png"
import slide_038 from "../assets/anticipator_sequence/Anticipator_00038.png"
import slide_039 from "../assets/anticipator_sequence/Anticipator_00039.png"
import slide_040 from "../assets/anticipator_sequence/Anticipator_00040.png"
import slide_041 from "../assets/anticipator_sequence/Anticipator_00041.png"
import slide_042 from "../assets/anticipator_sequence/Anticipator_00042.png"
import slide_043 from "../assets/anticipator_sequence/Anticipator_00043.png"
import slide_044 from "../assets/anticipator_sequence/Anticipator_00044.png"
import slide_045 from "../assets/anticipator_sequence/Anticipator_00045.png"
import slide_046 from "../assets/anticipator_sequence/Anticipator_00046.png"

export enum ANTISIPATION_ASSETS {
    ANTISIPATION_SEQUENCE_018 = 'ANTISIPATION_SEQUENCE_018',
    ANTISIPATION_SEQUENCE_019 = 'ANTISIPATION_SEQUENCE_019',
    ANTISIPATION_SEQUENCE_020 = 'ANTISIPATION_SEQUENCE_020',
    ANTISIPATION_SEQUENCE_021 = 'ANTISIPATION_SEQUENCE_021',
    ANTISIPATION_SEQUENCE_022 = 'ANTISIPATION_SEQUENCE_022',
    ANTISIPATION_SEQUENCE_023 = 'ANTISIPATION_SEQUENCE_023',
    ANTISIPATION_SEQUENCE_024 = 'ANTISIPATION_SEQUENCE_024',
    ANTISIPATION_SEQUENCE_025 = 'ANTISIPATION_SEQUENCE_025',
    ANTISIPATION_SEQUENCE_026 = 'ANTISIPATION_SEQUENCE_026',
    ANTISIPATION_SEQUENCE_027 = 'ANTISIPATION_SEQUENCE_027',
    ANTISIPATION_SEQUENCE_028 = 'ANTISIPATION_SEQUENCE_028',
    ANTISIPATION_SEQUENCE_029 = 'ANTISIPATION_SEQUENCE_029',
    ANTISIPATION_SEQUENCE_030 = 'ANTISIPATION_SEQUENCE_030',
    ANTISIPATION_SEQUENCE_031 = 'ANTISIPATION_SEQUENCE_031',
    ANTISIPATION_SEQUENCE_032 = 'ANTISIPATION_SEQUENCE_032',
    ANTISIPATION_SEQUENCE_033 = 'ANTISIPATION_SEQUENCE_033',
    ANTISIPATION_SEQUENCE_034 = 'ANTISIPATION_SEQUENCE_034',
    ANTISIPATION_SEQUENCE_035 = 'ANTISIPATION_SEQUENCE_035',
    ANTISIPATION_SEQUENCE_036 = 'ANTISIPATION_SEQUENCE_036',
    ANTISIPATION_SEQUENCE_037 = 'ANTISIPATION_SEQUENCE_037',
    ANTISIPATION_SEQUENCE_038 = 'ANTISIPATION_SEQUENCE_038',
    ANTISIPATION_SEQUENCE_039 = 'ANTISIPATION_SEQUENCE_039',
    ANTISIPATION_SEQUENCE_040 = 'ANTISIPATION_SEQUENCE_040',
    ANTISIPATION_SEQUENCE_041 = 'ANTISIPATION_SEQUENCE_041',
    ANTISIPATION_SEQUENCE_042 = 'ANTISIPATION_SEQUENCE_042',
    ANTISIPATION_SEQUENCE_043 = 'ANTISIPATION_SEQUENCE_043',
    ANTISIPATION_SEQUENCE_044 = 'ANTISIPATION_SEQUENCE_044',
    ANTISIPATION_SEQUENCE_045 = 'ANTISIPATION_SEQUENCE_045',
    ANTISIPATION_SEQUENCE_046 = 'ANTISIPATION_SEQUENCE_046',
}

export const antisipationLoader = (loader: Loader) => {
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_018, slide_018)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_019, slide_019)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_020, slide_020)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_021, slide_021)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_022, slide_022)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_023, slide_023)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_024, slide_024)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_025, slide_025)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_026, slide_026)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_027, slide_027)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_028, slide_028)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_029, slide_029)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_030, slide_030)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_031, slide_031)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_032, slide_032)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_033, slide_033)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_034, slide_034)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_035, slide_035)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_036, slide_036)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_037, slide_037)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_038, slide_038)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_039, slide_039)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_040, slide_040)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_041, slide_041)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_042, slide_042)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_043, slide_043)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_044, slide_044)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_045, slide_045)
    loader.add(ANTISIPATION_ASSETS.ANTISIPATION_SEQUENCE_046, slide_046)
}
