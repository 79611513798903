import { SymbolItem } from "./SymbolItem";
import { SYMBOL_SIZE } from "../constants/Symbols";
import { Game } from "../Game";
import * as PIXI from 'pixi.js'
import { GameField } from "../components/gameField/GameField";
import { multiplyArray } from "../../utils/multiplyArr";
import {SymbolEvents, SymbolName, SymbolNames} from "../../api/types";
import gsap from 'gsap'
import { slotMachine } from "./slotMachine";
import { EventType, getEvent } from "../../GameEventBus"
import { LocalStorage } from "../../utils/localStorage";
import { Sounds } from './SoundController';
import { SoundNames } from '../constants/sounds';
import { Timer } from "../../utils/Timer";
import Antisipation from "../components/Antisipation/Antisipation";
import {Spine} from "pixi-spine";
import {SYMBOLS_ASSETS} from "../assetsLoader/SymbolsLoader";


export class Reel {
    id: number
    symbols: SymbolItem[]
    game: Game
    sheet?: GameField
    reelsContainer: PIXI.Container
    container: PIXI.Container
    slotMachine: slotMachine
    isAnimate: boolean
    startTimeline: gsap.core.Timeline
    reelSpeed: number

    constructor(game: Game, id: number, parentContainer: PIXI.Container) {
        this.id = id
        this.game = game
        this.slotMachine = game.slotMachine!
        this.symbols = []
        this.reelsContainer = parentContainer
        this.container = new PIXI.Container()
        this.isAnimate = false
        this.reelSpeed = game.slotMachine?.currentSpeed! || 1
        this.startTimeline = gsap.timeline({
            repeat: -1,
            repeatDelay: 0,
            ease: "none",
            delay: 0.1 * (this.id + 1),
        });

    }

    init = () => {
        if (!this.game.assetsManager.gameField) return
        this.sheet = this.game.assetsManager.gameField
        this.reelsContainer.y = 15
        this.reelsContainer.x = -13
        this.container.sortableChildren = true;
        this.container.name = "REEL"
        this.speedListener()

    }

    generateReels = (symbols: string[]) => {
        const multipliedSymbols: string[] = multiplyArray(symbols, 2) as string[]
        return new Promise((resolve, reject) => {
            multipliedSymbols.forEach((name) => {
                const symbol = SymbolItem.getSymbol(name, this.game)
                if (!symbol) return
                this.symbols.push(symbol)

            })
            resolve(true)
        })

    }

    placeSymbols = (reelId: number, isInitial = true) => {
        return new Promise((resolve) => {
            const startPos = 713
            this.symbols.forEach((symbol, index) => {

                symbol.container.y = startPos - (((SYMBOL_SIZE * index + 22) + index * 7) - (isInitial ? 0 : 0))
                this.container.x = (SYMBOL_SIZE * reelId + 23) + reelId * 7
                this.container.addChild(symbol.container)
                this.reelsContainer?.addChild(this.container)
            })

            return resolve(true)
        })
    }

    replaceSymbols = (newSymbols: SymbolName[]) => {
        return new Promise((resolve, reject) => {
            const symbolsLength = this.symbols.length - 1
            for (let i = symbolsLength; i >= 4; i--) {
                const currentSymbol = this.symbols[i]
                const symbolName = newSymbols[symbolsLength - i]
                const symbol = SymbolItem.getSymbol(symbolName, this.game)
                if (!symbol) return
                symbol.container.y = currentSymbol.container.y
                this.symbols[i] = symbol

                const indexToDelete = this.container.children.indexOf(currentSymbol.container)
                this.container.removeChildAt(indexToDelete)

                this.container.addChild(symbol.container)

            }

            return resolve(true)
        })
    }


    replaceSecondSymbols = (newSymbols: SymbolName[]) => {
        return new Promise((resolve, reject) => {
            for (let i = 3; i >= 0; i--) {
                const currentSymbol = this.symbols[i]
                const symbolName = newSymbols[3 - i]
                const symbol = SymbolItem.getSymbol(symbolName, this.game)
                if (!symbol) return

                symbol.container.y = currentSymbol.container.y
                this.symbols[i] = symbol
                const indexToDelete = this.container.children.indexOf(currentSymbol.container)
                this.container.removeChildAt(indexToDelete)
                if(symbol.name === "CHEST") {
                    const soundTimer = new Timer(() => {
                        Sounds.play(SoundNames.BONUS_LAND);
                    }, 750);
                    soundTimer.initialize();
                }
                if (symbol.name === "WILD") symbol.container.zIndex = 100;
                this.container.addChild(symbol.container)


                // this.reelsContainer[]
            }


            return resolve(true)
        })
    }

    onStartAnimate = (isPending = false, isFreeSpin = false, anticipate = false, start = false, isScatter=false) => {
        return new Promise(async (resolve) => {
            if (!isPending && !isFreeSpin) {
                let delay = 2000 * 0.1 * (this.id + 1) * this.reelSpeed
                const timer = new Timer(() => {
                    const repeats = Math.floor(this.startTimeline.totalTime());
                    this.startTimeline.repeat(repeats);
                    this.container.y = 0;
                    this.startTimeline.kill();
                    return resolve(true);
                }, delay)
                timer.initialize()
                timer.pause()
                timer.remaining *= this.slotMachine.currentSpeed
                timer.resume()
            } else {
                if (anticipate) {
                    const antisipation = this.game.assetsManager.antisipation! as Antisipation;
                    const delay = antisipation.antisipationIndex.length;
                    const begin = new Timer(() => {
                        antisipation.animate(this.id);
                        Sounds.play(SoundNames.SPINFICATION);
                    }, start ? delay * 290 : 950 * (delay - 1) * (this.id - 2));
                    begin.initialize();
                    const finish = new Timer(() => {
                        Sounds.stop(SoundNames.SPINFICATION);
                        const repeats = Math.floor(this.startTimeline.totalTime());
                        this.startTimeline.repeat(repeats);
                        this.container.y = 0;
                        this.startTimeline.kill();
                        if (isScatter) Sounds.play(SoundNames.SCATTER_LAND_2);
                        const timer = new Timer(() => {
                            antisipation.stop(this.id);
                        }, 200);
                        timer.initialize();
                        return resolve(true);
                    }, start ? 1855 : 855 * (delay - 1) * (this.id - 1));
                    finish.initialize();
                } else {
                    this.startTimeline = gsap.timeline(
                        {
                            repeat: !isFreeSpin ? -1 : 2,
                            repeatDelay: 0,
                            ease: "none",
                            delay: 0.1 * (this.id + 1),
                        })
                    Sounds.play(SoundNames.REEL_SPIN);

                    this.startTimeline.eventCallback("onRepeat", () => {
                        const symbols = this.shufleSymbols();
                        this.replaceSymbols(symbols);
                    })
                    await this.startTimeline.to(this.container, { y: 892, duration: 0.2, ease: "none" });

                    this.container.y = 0;
                    return resolve(true);
                }
            }
        })
    }

    shufleSymbols = () => {
        const symbols: SymbolNames[] = [];
        const keys: SymbolNames[] = Object.keys(SymbolNames) as SymbolNames[];
        for (let i = 0; i < 3; i++) {
            const symbol = keys[Math.floor(Math.random() * keys.length)];
            symbols.push(symbol);
        }

        return symbols;
    }

    onEndAnimate = async () => {
        Sounds.play(SoundNames.REEL_STOP);
        const timeline = gsap.timeline()
        await timeline
            .fromTo(
                this.container,
                { y: 0 },
                {
                    duration: 0.5 * this.reelSpeed,
                    y: 892,
                    ease: 'back.out(1.2)',
                },
            )
            .then(() => {
            })
        this.container.y -= 892
        this.isAnimate = false
        const isTurboMode = LocalStorage.getItem('WrapperTurboSettings')
        if (isTurboMode && !this.game.slotMachine?.isFsRunning) {
            getEvent(EventType.ANIMATION_SPEED).send(0.5)
        } else {
            getEvent(EventType.ANIMATION_SPEED).send(1)
        }
    }

    speedListener = () => {
        getEvent<number>(EventType.ANIMATION_SPEED).subscribe((value) => {
            this.reelSpeed = value
        })
    }

    symbolToWild = async (newSymbols: SymbolName[], symbolEvents: SymbolEvents[], withShining: boolean) => {
        if (symbolEvents.length === 0) return;

        return new Promise(async (resolve) => {
            await this.processSymbols(newSymbols, symbolEvents, withShining);
            const timer = new Timer(() => {
                resolve(true);
            }, 1000)
            timer.initialize();
        });
    };

    createWildSymbol = () => {
        const symbol = SymbolItem.getSymbol("WILD", this.game);
        if (!symbol) return null;

        return symbol;
    };

    addShiningEffect = (currentSymbol: SymbolItem, withShining: boolean) => {
        const shining = new Spine(this.game.app.loader.resources[SYMBOLS_ASSETS.SWAP_TO_WILD].spineData!);
        shining.zIndex = 10;
        shining.visible = withShining;
        shining.position.set(currentSymbol.spine!.x, currentSymbol.spine!.y);
        currentSymbol.container.addChild(shining);
        shining.state.setAnimation(0, "animation", false);
    };

    replaceSymbol = (currentSymbol: SymbolItem, symbol: SymbolItem) => {
        const timer = new Timer(() => {
        this.container.removeChild(currentSymbol.container);
        this.container.addChild(symbol.container);
        }, 400)
        timer.initialize()
    };

    processSymbols = async (newSymbols: SymbolName[], symbolEvents: SymbolEvents[], withShining: boolean) => {
        for (let i = 0; i < 4; i++) {
            const currentSymbol = this.symbols[i];
            const symbolEventNames = symbolEvents.map(event => event.symbolToWild);
            if (symbolEventNames.includes(currentSymbol.name)) {
                const symbol = this.createWildSymbol();
                if (!symbol) return;

                symbol.container.y = currentSymbol.container.y;
                this.symbols[i] = symbol;

                this.addShiningEffect(currentSymbol, withShining);
                this.replaceSymbol(currentSymbol, symbol);
            }
        }
    };
}
